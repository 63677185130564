import { Dispatch, SetStateAction, useState } from "react"

// https://react.dev/reference/react/useState#updating-objects-and-arrays-in-state
//
// You can put objects and arrays into state. In React, state is considered read-only,
// so you should replace it rather than mutate your existing objects.
//
// This hook wraps the useState hook by passing a Readonly version of the state
// object to it. State objects initialized with this hook may be passed
// as dependency parameters to useEffect hooks because the Readonly nature enforces that
// the state will not change without instantiating a new object.
export function useReadonlyState<S>(initialState: S | (() => S)): [Readonly<S>, Dispatch<SetStateAction<S>>] {
  return useState<Readonly<S>>(initialState)
}
