import { NonProfitOrgInternalConfig, NonProfitOrgInternalConfigSchema } from "@fieldday/fielddayportal-model"
import { Box, Button, FormControl, FormControlLabel, Switch, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import { AlertSeverity, useLoading } from "../../hooks/useLoading"
import { useReadonlyState } from "../../hooks/useReadonlyState"
import { NPOWithActivities } from "../../models/Npo"
import ajv from "../../util/ajvConfig"
import { isoDate } from "../../util/dateUtil"
import { useAPI } from "../../util/useAPI"
import { getErrorMessages } from "../../util/validationErrors"

export default function InternalConfigForm({ setAllowPrompt, npo, afterSubmit }: {
  setAllowPrompt: (arg: boolean) => void,
  npo: NPOWithActivities,
  afterSubmit?: () => void,
}) {
  const FieldDayAPI = useAPI()
  const [errors, setErrors] = useReadonlyState({} as NonProfitOrgInternalConfig)
  const fieldError = errors.disbursementDetailsUpdatedAt
  const [internalConfigDirty, setInternalConfigDirty] = useReadonlyState(false)
  const [internalConfig, setInternalConfig] = useReadonlyState<NonProfitOrgInternalConfig>(npo.internalConfig ?? {})
  const [enabledDisbursement, setEnabledDisbursement] = useReadonlyState(npo.internalConfig?.disbursementDetailsUpdatedAt != null)
  const [savedDisbursementDetailsDate, setSavedDisbursementDetailsDate] = useReadonlyState<string | null>(null)

  const validate = ajv.compile(NonProfitOrgInternalConfigSchema)
  const { loadStart, loadEnd, setAlert } = useLoading()

  const handleDisbursementDetailsDateChange = (newDate: string | null | Dayjs | undefined) => {
    setInternalConfigDirty(true)
    setErrors({})
    const updatedInternalConfig = Object.assign({}, internalConfig, {
      disbursementDetailsUpdatedAt: "",
    })

    if (newDate) {
      updatedInternalConfig.disbursementDetailsUpdatedAt = isoDate(newDate)
    }
    setInternalConfig(updatedInternalConfig)
  }

  const submitForm = async () => {
    if (validate(internalConfig)) {
      setErrors({} as NonProfitOrgInternalConfig)
    } else {
      const errs = getErrorMessages(validate.errors || []) as NonProfitOrgInternalConfig
      setErrors(errs)
      return
    }
    loadStart(true)
    setAllowPrompt(false)

    FieldDayAPI.updateNpoInternalConfig(npo.id, internalConfig).then(response => {
      setAlert(AlertSeverity.SUCCESS, "Configuration updated")
      setInternalConfig(internalConfig ?? response.nonProfitOrg.internalConfig ?? {})
    })
      .catch(err => {
        setAlert(AlertSeverity.ERROR, err.response?.data?.message || `${err}`)
      }).finally(() => {
        loadEnd()
        setAllowPrompt(true)
        afterSubmit && afterSubmit()
      })
  }

  return (<>
    <FormControl component={"fieldset"} fullWidth>
      <FormControlLabel
        value="end"
        control={<Switch
          sx={{ marginTop: "1.5em" }}
          checked={enabledDisbursement}
          onChange={(event) => {
            setEnabledDisbursement(event.target.checked)
            if (event.target.checked) {
              handleDisbursementDetailsDateChange(savedDisbursementDetailsDate ?? "")
              setSavedDisbursementDetailsDate(null)
            } else {
              setErrors({})
              setInternalConfigDirty(true)
              setSavedDisbursementDetailsDate(internalConfig.disbursementDetailsUpdatedAt ?? null)
              const updatedInternalConfig = Object.assign({}, internalConfig, {
                disbursementDetailsUpdatedAt: null,
              })
              setInternalConfig(updatedInternalConfig)
            }
          }}
        />}
        sx={{
          alignItems: "start",
          paddingBottom: enabledDisbursement ? "0em" : "2em",
        }}
        label={enabledDisbursement ?
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Disbursement details date"
              value={internalConfig.disbursementDetailsUpdatedAt}
              onChange={(newDate) => {handleDisbursementDetailsDateChange(newDate)}}
              renderInput={params => <TextField
                {...params}
                fullWidth
                sx={{ width: "17em", mt: "1em" }}
                error={!!fieldError}
                helperText={fieldError
                  ? fieldError
                  : "The date that disbursement details were last received for this nonprofit."}
              />}
            />
          </LocalizationProvider>
          :
          <Typography mt={"1.5em"}>Nonprofit is not ready to receive disbursements</Typography>
        }
        labelPlacement="end"
      />
    </FormControl>
    <FormControl fullWidth sx={{ alignItems: "end" }}>
      <Box mt={2} mb={1}>
        <Button onClick={submitForm} disabled={!internalConfigDirty} variant="contained" color="primary">
          Save changes
        </Button>
      </Box>
    </FormControl>
  </>)
}
