import { useTheme } from "@mui/styles"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useMemo } from "react"
import { useReadonlyState } from "../../hooks/useReadonlyState"
import { NPO } from "../../models/Npo"
import PlatformDonationCreateForm from "./PlatformDonationCreateForm"

interface props {
  orgUnitId: string,
  header: string,
  onClose: (skipConfirm?: boolean, retryAfter?: number) => void,
  reportDirty: (dirty: boolean) => void,
  setSubmitButtonComponent: (buttonComponent: JSX.Element) => void,
  platformDonationOnly?: boolean,
  presetNpo?: NPO,
  presetAmount?: number,
}

export default function StripeProvidedMatchableDonationCreateForm(props: props) {
  // Stripe Elements complains if the stripePromise changes, but we want
  // to be able to only load it when this component is mounted.
  // useMemo memoizes this function so it does not change on every render
  // while still allowing us to only call loadStripe when this component is mounted.
  const stripePromise = useMemo(
    () => loadStripe(import.meta.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
    [import.meta.env.REACT_APP_STRIPE_PUBLISHABLE_KEY],
  )
  const theme = useTheme()

  const [ amount, setAmount ] = useReadonlyState(100)
  const sanitizedAmount = amount && amount >= 100 ? amount : 10000

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        amount: sanitizedAmount,
        currency: "usd",
        paymentMethodCreation: "manual",
        appearance: {
          variables: {
            colorPrimary: theme.palette.primary.main,
            colorDanger: theme.palette.error.main,
            borderRadius: "4px",
            gridRowSpacing: "1.25rem",
            gridColumnSpacing: "1.25rem",
          },
          rules: {
            ".Input": {
              border: `1px solid ${theme.palette.grey[ 400 ]}`,
              boxShadow: "none",
              paddingTop: "1.1rem",
              paddingBottom: "1.1rem",
            },
            ".Input:focus": {
              border: `1px solid ${theme.palette.primary.main}`,
              boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
            },
            ".Input:hover": {
              border: `1px solid ${theme.palette.grey[ 900 ]}`,
            },
            ".Input:hover.Input:focus": {
              border: `1px solid ${theme.palette.primary.main}`,
            },
            ".Input--invalid": {
              border: `1px solid ${theme.palette.error.main}`,
              boxShadow: "none",
            },
            ".Input--invalid:focus": {
              border: `1px solid ${theme.palette.error.main}`,
              boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
            },
            ".Input--invalid:hover": {
              border: `1px solid ${theme.palette.error.main}`,
            },
            ".Input--invalid:hover.Input--invalid:focus": {
              border: `1px solid ${theme.palette.error.main}`,
            },
          }
        },
      }}
    >
      <PlatformDonationCreateForm {...props} reportAmount={setAmount} />
    </Elements>
  )
}
