import { Box, Chip, Divider, Grid, Link, Stack, Typography, useTheme } from "@mui/material"
import { round } from "lodash"
import { Fragment, useState } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { DonationMatchPolicy } from "../../models/Donations"
import { OrgUnit } from "../../models/OrgUnit"
import { default as useStyles } from "../../styles/useStyles"
import { DonationMatchProgressBarGraph } from "../../util/ProgressBarGraph"
import { tsFormatDateRange } from "../../util/dateUtil"
import { matchPolicyLimitAmountPretty } from "../../util/matchableDonationUtils"
import { dollarDollars } from "../../util/stringUtil"
import MatchableDonationPolicyDialog, { DonationPolicyGridItem } from "../Teams/MatchableDonationPolicyDialog"

export default function MatchableDonationPolicySummaryList({ matchableDonationPolicies, orgUnits, variant = "full", adminView, currentOrgUnit }: {
  matchableDonationPolicies: DonationMatchPolicy[],
  orgUnits?: OrgUnit[] | undefined,
  variant?: "full" | "brief",
  adminView?: boolean
  currentOrgUnit?: OrgUnit
}) {
  const styles = useStyles()

  return (
    <Box style={variant === "full" ? { marginTop: '2em', marginBottom: '2em' } : {}}>
      {variant === "full" && <>
        <Divider style={{ marginTop: '3rem', marginBottom: '2rem' }} />
        <Typography component="h2" variant="h5" mb={3}>
          {adminView ? "Donation match policies" : "My matches"}
        </Typography>
        {adminView &&
          <Box className={styles.boxContent}>
            <Typography>
              To manage your Team&apos;s donation match policies, please head to your&nbsp;
            <Link component={RouterLink} to={`/teams/${currentOrgUnit?.handle}/manage/settings/`}>
              Team settings.
            </Link>
            </Typography>
          </Box>
        }
      </>}
      {matchableDonationPolicies.map((mp, i) => {
        const userTeams = !!orgUnits && orgUnits.length > 1
        const filteredTeam = userTeams ? orgUnits?.filter(ou => ou.id === mp.orgUnitId) : []

        return (
          <Fragment key={mp.id}>
            <MatchableDonationPolicySummaryItem
              key={mp.id}
              matchPolicy={mp}
              team={filteredTeam.length !== 0 ? filteredTeam[ 0 ] : undefined}
              variant={variant}
              adminView={adminView}
            />
            {(variant === "full" && i < matchableDonationPolicies.length - 1) &&
              <Divider sx={{ mt: 5, mb: 3.5 }}/>
            }
          </Fragment>
        )
      })}
    </Box>
  )
}

export function MatchableDonationPolicySummaryItem({ matchPolicy, team, variant, adminView }: {
  matchPolicy: DonationMatchPolicy,
  team?: OrgUnit | undefined,
  variant?: "full" | "brief",
  adminView?: boolean
}) {
  const theme = useTheme()
  const styles = useStyles()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const noIndividualLimit = matchPolicy.matchLimitIndividualDollars === 0 || matchPolicy.matchLimitIndividualDollars === null
  const matchedPercentage = (() => {
    if (adminView) {
      return (
        matchPolicy.approvedTotalDollars && matchPolicy.matchLimitTotalDollars ?
            (matchPolicy.approvedTotalDollars / matchPolicy.matchLimitTotalDollars) * 100 : 0
      )
    }
    return (
      matchPolicy.approvedIndividualDollars && matchPolicy.matchLimitIndividualDollars ?
            (matchPolicy.approvedIndividualDollars / matchPolicy.matchLimitIndividualDollars) * 100 : 0
    )
  })()


  const matchingSummary = (<>
    <Typography variant={variant === "full" ? "label2" : "label3"} color={theme.palette.text.secondary}>
      {variant === "brief" && `${matchPolicy.name}: `}{adminView ? "Total approved matches" : "My approved matches"}
    </Typography>
    <DonationMatchProgressBarGraph key={matchPolicy.id}
      matchLimit={adminView ? matchPolicy.matchLimitTotalDollars : matchPolicy.matchLimitIndividualDollars}
      approvedMatchedTotal={adminView ? matchPolicy.approvedTotalDollars : matchPolicy.approvedIndividualDollars}
      totalColor={variant === "brief" ? "#FFF" : undefined}
    />
    <Stack direction="row">
      <Typography variant={variant === "full" ? "body1" : "body2"} fontWeight={500}>
        {dollarDollars(matchPolicy.approvedIndividualDollars)}
      </Typography>
      <Typography variant={variant === "full" ? "body1" : "body2"}color={theme.palette.text.secondary}>
        &nbsp;/ {adminView ? dollarDollars(matchPolicy.matchLimitTotalDollars) : dollarDollars(matchPolicy.matchLimitIndividualDollars)}
        &nbsp;·&nbsp;{round(matchedPercentage, 0)}% of match limit
      </Typography>
    </Stack>
  </>)

  if (variant === "brief") {
    return (
      <Box className={styles.donationMatchingGraphBox}>
        {matchingSummary}
      </Box>
    )
  }

  return (<>
    <Grid container key={matchPolicy.id} mb={2}>
      <Grid item xs={12}>
        <Typography variant="subhead2B" mb={1}>
          {matchPolicy.name}&nbsp;
          {team && <Chip label={team.name} size='small' color="primary" />}
        </Typography>
      </Grid>
      <Grid item container xs={12} columnSpacing={1}>
        <DonationPolicyGridItem subHeader="Policy dates" override={{ xs: 12, lg: 2.5, list: true }}>
          <Typography variant="subhead3B" mt={.5}>
            {(matchPolicy.startISO && matchPolicy.endISO) && tsFormatDateRange(matchPolicy.startISO, matchPolicy.endISO, true, true)}
          </Typography>
        </DonationPolicyGridItem>
        <DonationPolicyGridItem subHeader="Match percent" override={{ xs: 4, lg: 2, list: true }}>
          <Typography variant="subhead3B" mt={.5}>{matchPolicy.matchPercent}%</Typography>
        </DonationPolicyGridItem>
        {adminView &&
          <DonationPolicyGridItem subHeader="Total match limit" override={{ xs: 4, lg: 2, list: true }}>
            <Typography variant="subhead3B" mt={.5}>{matchPolicyLimitAmountPretty(matchPolicy.matchLimitTotalDollars)}</Typography>
          </DonationPolicyGridItem>
        }
        <DonationPolicyGridItem
          subHeader={adminView ? "Individual match limit" : "Match limit"}
          override={{ xs: 4, lg: adminView ? 2.5 : 1.75, list: true }}
        >
          <Typography variant="subhead3B" mt={.5}>{matchPolicyLimitAmountPretty(matchPolicy.matchLimitIndividualDollars)}</Typography>
        </DonationPolicyGridItem>
        <Grid item xs={2}>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            <Link component="button" onClick={() => setDialogIsOpen(true)}>
              View policy description
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {!noIndividualLimit &&
        <Grid item xs={12} lg={9} mt={2}>
          {matchingSummary}
        </Grid>
      }
    </Grid>
    <MatchableDonationPolicyDialog
      matchPolicy={matchPolicy}
      open={dialogIsOpen}
      close={() => setDialogIsOpen(false)}
      adminView={adminView}
    />
  </>)
}
