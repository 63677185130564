import { DonationInvoiceBatchStatus } from "@fieldday/fielddayportal-model"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useLoading } from "../../../hooks/useLoading"
import { NPO } from "../../../models/Npo"
import { OrgUnit } from "../../../models/OrgUnit"
import { dayjsTz, shortDate } from "../../../util/dateUtil"
import { dollarCents } from "../../../util/stringUtil"
import { DonationInvoiceBatchModelResponseWithUpdatedAt, useAPI } from "../../../util/useAPI"
import { DonationsSummary, InvoiceStatusChip } from "./DonationInvoicing"

export default function DonationInvoicesCompleted({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [donationInvoiceBatches, setDonationInvoiceBatches] = useState<DonationInvoiceBatchModelResponseWithUpdatedAt[] | null>(null)
  const [batchOrgUnits, setBatchOrgUnits] = useState<OrgUnit[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useState<NPO[] | null>(null)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.listDonationInvoiceBatches([DonationInvoiceBatchStatus.Paid], abortController).then((res) => {
      setDonationInvoiceBatches(_.orderBy(res.data.donationInvoiceBatches, "updatedAt", "desc"))
      setBatchOrgUnits(res.data.orgUnits)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    }).finally(() => {loadEnd()})
    return () => {
      abortController.abort()
    }
  }, [refreshCounter])

  return (<>
    <Grid container spacing={2} mb={4} pl={2} pr={20}>
      <Grid item xs={2}>
        <Typography fontWeight={"bold"}>Last updated</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography fontWeight={"bold"}>Team name</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight={"bold"}>Match amount</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight={"bold"}>Fees amount</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight={"bold"}>Invoice ID</Typography>
      </Grid>
    </Grid>

    {donationInvoiceBatches?.map(batch => {
      const orgUnit = batchOrgUnits?.find(ou => ou.id === batch.orgUnitId)
      if (!orgUnit) return <></>
      return (
        <DonationInvoiceCompletedSummary
          key={batch.id}
          batch={batch}
          orgUnit={orgUnit}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
    })}
  </>)
}

function DonationInvoiceCompletedSummary({ batch, orgUnit, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationInvoiceBatchModelResponseWithUpdatedAt,
  orgUnit: OrgUnit,
  nonProfitOrgs: NPO[],
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()

  const [expanded, setExpanded] = useState(false)
  const { loadStart } = useLoading()

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography mt={"0.5em"}>
                  {shortDate(dayjs(batch.updatedAt))}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography mt={"0.5em"} display="inline-block">
                  {orgUnit.name}
                </Typography>
                <InvoiceStatusChip sx={{ ml: 1 }} batch={batch} />
              </Grid>
              <Grid item xs={2}>
                <Typography mt={"0.5em"}>
                  {dollarCents(batch.invoiceMatchCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography mt={"0.5em"}>
                  {dollarCents(batch.invoiceFeesCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography mt={"0.5em"} noWrap maxWidth={"100%"} display="inline-block">
                  {batch.invoiceId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {expanded && <Grid item xs={12}>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Created on: </Typography>
                <Typography>{shortDate(dayjsTz(batch.createdAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch ends on:</Typography>
                <Typography>{shortDate(dayjs(batch.endAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Invoiced on:</Typography>
                <Typography>{shortDate(dayjs(batch.invoicedAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Paid on:</Typography>
                <Typography>{shortDate(dayjs(batch.paidAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch id:</Typography>
                <Typography>{batch.batchId}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Invoice id:</Typography>
                <Typography>{batch.invoiceId}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Donations:</Typography>
                <Typography>{batch.matchableDonations?.length}</Typography>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        <Button sx={{ mr: "1em", height: "2.5em" }} onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          loadStart(true)
          FieldDayAPI.updateDonationInvoiceBatch(batch.id, {
            status: DonationInvoiceBatchStatus.Invoiced,
            paidAt: null,
          }).then(() => {
            triggerRefresh()
          })
        }} variant="outlined"
        >
          Unpaid
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <DonationsSummary
          matchableDonations={batch.matchableDonations ?? []}
          nonProfitOrgs={nonProfitOrgs}
        />
      </AccordionDetails>
    </Accordion>
  )
}
