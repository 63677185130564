import { Backdrop, LinearProgress } from '@mui/material';
import { LoadingAlertState } from '../../hooks/useLoading';
import useGlobalStyles from '../../styles/globalStyles';
import { FieldDayFlagSvgMarchingIcon } from '../../util/icons';

export default function LoadingBar({ loadingState }: { loadingState: LoadingAlertState }) {
  const { loading, blocking } = loadingState;
  const globalStyles = useGlobalStyles();

  if (loading && !blocking) {
    return <LinearProgress className={globalStyles.progressBar} />
  }

  if (loading && blocking) {
    return (
      <Backdrop className={globalStyles.backdrop} open={true}>
        <FieldDayFlagSvgMarchingIcon />
      </Backdrop>
    )
  }

  return null
}
