import {
  DonationDisbursementNonProfitOrgBatchResponseModelI,
} from "@fieldday/fielddayportal-model"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import { useEffect } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import { useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import { endOfLastMonth, isoDate } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents } from "../../../util/stringUtil"
import { useAPI } from "../../../util/useAPI"
import NonProfitDisbursementDetails from "./NonProfitDisbursementDetails"

export default function DonationDisbursementPreview({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [
    nonProfitOrgDisbursementBatches, setNonProfitOrgDisbursementBatches
  ] = useReadonlyState<DonationDisbursementNonProfitOrgBatchResponseModelI[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useReadonlyState<NPO[] | null>(null)

  const defaultEndDate = isoDate(endOfLastMonth)
  const [endDate, setEndDate] = useReadonlyState(defaultEndDate)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.previewDonationDisbursementBatch(endDate, abortController).then((res) => {
      setNonProfitOrgDisbursementBatches(res.data.nonProfitOrgDisbursementBatches)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    })
    .catch(() => {
      setNonProfitOrgDisbursementBatches([])
      setBatchNonProfitOrgUnits([])
    })
    .finally(() => {loadEnd()})

    return () => {
      abortController.abort()
    }
  }, [endDate, refreshCounter])

  return (<>
    <FormControl fullWidth sx={{ marginY: 4 }}>
      <Stack direction={"row"} justifyContent="space-between" width={"100%"}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={"Disbursement end date"}
            value={endDate}
            onChange={(newDate: string | null | Dayjs) => {
              if (newDate) {
                const dateISO = isoDate(newDate)
                setEndDate(dateISO)
              } else {
                setEndDate(defaultEndDate)
              }
            }}
            renderInput={params => <TextField {...params} helperText={"Donations and matches after this date will not be included in the batch"} />}
          />
        </LocalizationProvider>
        {(nonProfitOrgDisbursementBatches && nonProfitOrgDisbursementBatches.length > 0) && <Box>
          <Button
            variant="contained"
            onClick={() => {
              loadStart(true)
              FieldDayAPI.createDonationDisbursementBatch(endDate).then(() => {
                triggerRefresh()
              })
            }}
          >
            Start disbursement
          </Button>
        </Box>}
      </Stack>
    </FormControl>
    {(nonProfitOrgDisbursementBatches && nonProfitOrgDisbursementBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No disbursements required"
        description="If you expect to see disbursements here, you may need to adjust the disbursement end date"
        iconSrc={CTAIconSrc.EventsCalendar}
      />
      : <Grid container spacing={2} mb={4} pl={2} pr={5}>
        <Grid item xs={5}>
          <Typography fontWeight={"bold"}>Nonprofit name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Sequence number</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Disbursement amount</Typography>
        </Grid>
      </Grid>
    }

    {nonProfitOrgDisbursementBatches?.map(npoBatch => {
      return (
        <NonProfitDisbursementBatch
          key={npoBatch.nonProfitOrgId}
          batch={npoBatch}
          nonProfitOrg={batchNonProfitOrgUnits?.find(n => n.id === npoBatch.nonProfitOrgId)}
        />
      )
    })}
  </>)
}

function NonProfitDisbursementBatch({ batch, nonProfitOrg }: {
  key: string,
  batch: DonationDisbursementNonProfitOrgBatchResponseModelI,
  nonProfitOrg?: NPO,
}) {
  const [expanded, setExpanded] = useReadonlyState(false)

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography>
              {nonProfitOrg?.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {batch.sequenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {dollarCents(batch.disbursementAmountCents)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <NonProfitDisbursementDetails
          matchableDonations={batch.matchableDonations ?? []}
          platformDonations={batch.platformDonations ?? []}
        />
      </AccordionDetails>
    </Accordion>
  )
}
