import { getMetroRegion, MetroRegion, MetroRegions, MetroRegionsForFilters } from "@fieldday/fielddayportal-model"
import { Autocomplete, FormControl, FormLabel, Link, Popover, Stack, TextField, Typography } from "@mui/material"
import React, { SyntheticEvent, useEffect, useState } from "react"
import { useRegionContext } from "../../hooks/useRegion"
import useStyles from "../../styles/useStyles"
import { regionToString } from "../../util/stringUtil"

export default function RegionSelector({ onChange, value, helpText, error, disabled, className }: {
  onChange: (newValue: MetroRegion | null) => void,
  value: string | undefined,
  error?: boolean,
  helpText?: string,
  disabled?: boolean,
  className?: string,
}) {
  const handleOnChange = (_event: React.SyntheticEvent<Element, Event>, newValue: MetroRegion | null) => {
    onChange(newValue)
  }

  return (
    <Autocomplete
      id='region-selector'
      options={MetroRegions}
      getOptionLabel={option => regionToString(option)}
      className={className}
      onChange={handleOnChange}
      value={typedMetroRegion(value)}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} label="Metro area"
        helperText={helpText && helpText} error={error}
      />}
    />
  )
}

function typedMetroRegion(value?: string | null): MetroRegion | null {
  return getMetroRegion(value) ?? null
}

export function RegionListItem({ region, textColor }: { region: string | undefined, textColor: 'textPrimary' | 'textSecondary' }) {
  return (
    <Typography variant='label2' color={textColor} minWidth='fit-content'>
      {regionToString(region, true)}&nbsp;·&nbsp;
    </Typography>
  )
}

export function regionFilterLabel(filterPageLoc: 'npoList' | 'activityList' | 'eventList' | 'team' | 'policyList',
region: string | undefined | null) {
  const regionStr = region && regionToString(region)
  switch (filterPageLoc) {
    case 'npoList': return regionStr ? `Browsing nonprofits near ${regionStr}.` : 'Browsing nonprofits in all regions.'
    case 'activityList': return regionStr ? `Browsing volunteer activities near ${regionStr}.` : 'Browsing volunteer activities in all regions.'
    case 'eventList': return regionStr ? `Viewing community events near ${regionStr}.` : 'Viewing all community events.'
    case 'team' : return regionStr ? `Viewing Field Trips near ${regionStr}.` : 'Viewing Field Trips in all regions.'
    case 'policyList' : return regionStr ? `Searching nonprofits near ${regionStr}.` : 'Searching nonprofits in all regions.'
  }
}

export function RegionPicker<ParamsType extends { region?: MetroRegion }>({ queryParams, onFilterChange, handleClose }: {
  queryParams: ParamsType,
  onFilterChange: (qp: ParamsType) => void,
  handleClose: () => void
}) {
  const { setRegion } = useRegionContext()
  const styles = useStyles()
  const handleChange = (_event: SyntheticEvent<Element, Event>, value: MetroRegion | null, _reason: string) => {
    setRegion(value)
    onFilterChange({ ...queryParams, region: value })
    handleClose()
  }

  return (
    <FormControl>
      <FormLabel>
        <Typography variant='label3'>
          Region
        </Typography>
      </FormLabel>
      <Autocomplete
        id='region-picker'
        options={MetroRegionsForFilters}
        getOptionLabel={(option: MetroRegion) => regionToString(option)}
        value={queryParams.region ?? null}
        onChange={handleChange}
        className={styles.regionPicker}
        renderInput={(params) => (
          <TextField {...params} variant='standard' placeholder="All regions" />
        )}
      />
    </FormControl>
  )
}

export function RegionFilterPopover<ParamsType extends {
  region?: MetroRegion }>({
    filterPageLoc: filterPageLoc, flexDirection: flexDirection, queryParams, onFilterChange, smallType, setTopMargin, setLeftMargin
  }: {
  filterPageLoc: 'npoList' | 'activityList' | 'eventList' | 'team' | 'policyList',
  flexDirection: 'column' | 'row',
  queryParams: ParamsType
  onFilterChange: (qp: ParamsType) => void,
  smallType?: boolean,
  setTopMargin?: string
  setLeftMargin?: string
}) {
  const styles = useStyles()
  const { region } = useRegionContext()
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if(!queryParams.region) {
      onFilterChange({ ...queryParams, region: region })
    }
    return () => { }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      flexDirection={flexDirection}
      className={styles.regionFilter}
      ml={setLeftMargin ?? ""}
    >
      <Typography variant={smallType ? 'body2' : 'body1'} mt={setTopMargin ?? 'auto'} >
        {regionFilterLabel(filterPageLoc, queryParams.region ? queryParams.region : undefined)}&nbsp;
      </Typography>
      <Link  component='button' variant={smallType ? 'body2' : 'body1'} sx={{ mt: setTopMargin ?? 'auto' }} onClick={handleClick}>
        {queryParams.region ? 'Change region' : 'View by region'}
      </Link>
      <Popover
        id='region-filter-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack style={{ padding: '1em' }}>
          <RegionPicker
            onFilterChange={onFilterChange}
            queryParams={queryParams}
            handleClose={handleClose}
          />
        </Stack>
      </Popover>
    </Stack>
  )
}
