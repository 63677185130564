interface IQueue<T> {
  enqueue(item: T): void;
  dequeue(): T | undefined;
  peek(): T | undefined;
  isEmpty(): boolean;
  size(): number;
}

export class Queue<T> implements IQueue<T> {
  private storage: T[] = [];

  enqueue(item: T): void {
    this.storage.push(item);
  }
  dequeue(): T | undefined {
    return this.storage.shift()
  }
  peek(): T | undefined {
    return !this.isEmpty() ? this.storage[0] : undefined
  }
  isEmpty(): boolean {
    return this.storage.length == 0
  }
  size(): number {
    return this.storage.length
  }
}
