import _ from "lodash"
import { ScheduledActivityForView } from "../models/Activity"
import { OrgUnit } from "../models/OrgUnit"
import { UserInfo } from "../models/UserInfo"

export function filterUsersOrgShareTeams(user: UserInfo, sa: ScheduledActivityForView): OrgUnit[] {
  const userTeams = user.orgUnitMemberships.map(org => org.orgUnit)
  const saOrgShares = sa.orgUnitShares
  if(!userTeams || !saOrgShares) return []

  const usersOrgShareTeamList: OrgUnit[] = []

  userTeams.forEach(team => {
    const test = sa.orgUnitShares
    test?.forEach(orgUnit => {
      if(team.id === orgUnit.orgUnitId) {
        return usersOrgShareTeamList.push(team)
      }
    })
  })
  return usersOrgShareTeamList
}

export const orgKey = "currentOrgId"

export function getCurrentOrg(user: UserInfo | null, path: string) {
  if (user) {
    const getDefaultOrg = () => {
      if (!_.isEmpty(user.orgUnitMemberships)) {
        return user.orgUnitMemberships[0]
      } else if (!_.isEmpty(user.nonprofitMemberships)) {
        return user.nonprofitMemberships[0]
      } else {
        return null
      }
    }

    // find the handle in the path, either /teams/handle/etc or /nonprofits/handle/etc
    const pathmatchgroups = path.match(/\/(?<orgtype>teams|nonprofits)\/(?<handle>[^/]+)\/?/)?.groups
    if (pathmatchgroups?.handle) {
      if (pathmatchgroups.orgtype === "nonprofits") {
        const npoMatch = user.nonprofitMemberships.find(npm => npm.nonProfitOrg.handle === pathmatchgroups.handle)
        if (npoMatch) return npoMatch
      } else if (pathmatchgroups.orgtype === "teams") {
        const ouMatch = user.orgUnitMemberships.find(oum => oum.orgUnit.handle === pathmatchgroups.handle)
        if (ouMatch) return ouMatch
      }
    }

    // otherwise, look in the local storage to see most recent org
    const orgGroupMemberIdFromLocal = localStorage.getItem(orgKey)
    if (orgGroupMemberIdFromLocal) {
      if (orgGroupMemberIdFromLocal.includes("NPO")) {
        const npoMatch = user.nonprofitMemberships.find(npm => npm.id === orgGroupMemberIdFromLocal)
        if (npoMatch) return npoMatch
      } else {
        const ouMatch = user.orgUnitMemberships.find(oum => oum.id === orgGroupMemberIdFromLocal)
        if (ouMatch) return ouMatch
      }
    }
    return getDefaultOrg()
  } else {
    return null
  }
}
