/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Icon, SvgIcon } from "@mui/material";
import { makeStyles } from '@mui/styles';


export function GoogleIcon(props: Record<string, any>) {
  return (
    <SvgIcon {...props}>
      <g xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
        <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
        <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
        <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
      </g>
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    height: '80%',
  },
  iconRoot: {
    textAlign: 'center',
    marginRight: '0.3em',
    marginTop: '-0.4em',
  },
  progressIconRoot: {
    width: "100%",
    height: "100%",
    maxWidth: "60px",
    maxHeight: "60px",
    padding: ".2em",
    textAlign: 'center',
    marginTop: '-0.4em',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: ".3em",
    '&.inactive': {
      border: "none",
      backgroundColor: "#ccc",
    },
  },
  marchingIcon: {
    display: "inline-block",
    animation: `$marchIcon 2s ease-in-out infinite`,
  },
  ctaIcons: {
    height: '3.5em',
    width: '3.5em',
  },
  "@keyframes marchIcon": {
    "0%": {
      transform: "translateX(155px)",
      color: theme.palette.primary.main,
    },
    "30%": {
      transform: "translateX(-140px)",
      color: theme.palette.primary.main,
    },
    "50%": {
      transform: "translateX(-155px) rotateY(180deg)",
      color: theme.palette.neutral.dark,
    },
    "80%": {
      transform: "translateX(140px) rotateY(180deg)",
      color: theme.palette.neutral.dark,
    },
    "100%": {
      transform: "translateX(155px) rotateY(0deg)",
      color: theme.palette.primary.main,
    },
  },
}))

export function FieldDayFlagIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.iconRoot }} {...props}>
      <img className={styles.imageIcon} src="/images/fdflag.svg"/>
    </Icon>
  )
}

export function FieldDayWhiteFlagIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.iconRoot }} {...props}>
      <img className={styles.imageIcon} src="/images/fdflagwhite.svg"/>
    </Icon>
  )
}

export function FieldDayFlagSvgIcon(props: Record<string, any>) {
  return (
    <SvgIcon {...props}>
      <path
        d="M 4.136719 0.546875 C 4.136719 0.546875 13.847656 11.015625 23.996094 4.84375 C 23.996094 4.84375 18.808594 18.480469 2.105469 12.15625 "
      />
      <path
        stroke="rgb(13.72549%,12.156863%,12.54902%)"
        strokeWidth={5}
        d="M 2.509117 120.830641 L 25.694449 2.509359 " transform="matrix(0.172186,0,0,0.194584,0.0132779,0)"/>
    </SvgIcon>
  )
}

export function FieldDayFlagSvgMarchingIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <span className={styles.marchingIcon}>
      <FieldDayFlagSvgIcon sx={{ height: "100px", width: "100px" }} {...props} />
    </span>
  )
}

export function ActiveFlagIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.progressIconRoot }} {...props}>
      <img className={styles.imageIcon} src="/images/fdflag.svg"/>
    </Icon>
  )
}

export function InactiveFlagIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: `${styles.progressIconRoot} inactive` }} {...props}>
      <img className={styles.imageIcon} src="/images/fdflagwhite.svg"/>
    </Icon>
  )
}

export function NonprofitIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.iconRoot }} {...props}>
      <img className={styles.imageIcon} src="/images/hands-holding-heart.svg"/>
    </Icon>
  )
}

export function PaperPlaneSvgIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.ctaIcons }} {...props}>
      <img className={styles.imageIcon} src="/images/activities-paper-plane.svg"/>
    </Icon>
  )
}


export function PlannerSvgIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.ctaIcons }} {...props}>
      <img className={styles.imageIcon} src="/images/field-trip-planner.svg"/>
    </Icon>
  )
}

export function PlannerSmallSvgIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: `${styles.ctaIcons} small` }} {...props}>
      <img className={styles.imageIcon} src="/images/field-trip-planner.svg"/>
    </Icon>
  )
}

export function EventsCalendarSvgIcon(props: Record<string, any>) {
  const styles = useStyles()
  return (
    <Icon classes={{ root: styles.ctaIcons }} {...props}>
      <img className={styles.imageIcon} src="/images/events-calendar-icon.svg"/>
    </Icon>
  )
}

export enum CTAIconSrc {
  ActivitiesPaperPlane  = "/images/activities-paper-plane.svg",
  EditProfilePencil     = "/images/edit-profile-pencil.svg",
  EventsCalendar        = "/images/events-calendar-icon.svg",
  FieldTripPlanner      = "/images/field-trip-planner.svg",
  Locations             = "/images/locations-icon.svg",
  PastEventsCalendar    = "/images/past-events-calendar-icon.svg",
  Surveys               = "/images/surveys-icon.svg",
  Nonprofit             = "/images/nonprofit.svg",
  Organization          = "/images/organization.svg",
  HighFive              = "/images/high-five.svg",
}

interface CTAIconProps extends Record<string, any> {
  src: CTAIconSrc
}

export function CallToActionIcon(props: CTAIconProps) {
  const { src } = props
  const styles = useStyles()
  const className = styles.ctaIcons

  return (
    <Icon classes={{ root: className }} {...props} >
      <img className={styles.imageIcon} src={src} height="67.1875px" width="67.1875px" />
    </Icon>
  )
}

export const pathToLogoPlaceholder = "/images/logos/logo-placeholder.png"
export const pathToBannerPlaceholder = "/images/logos/banner-placeholder.png"
