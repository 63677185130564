import { Box, Skeleton, Typography } from "@mui/material"
import { Widget } from "@typeform/embed-react"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom"
import Header from "../../../components/Global/Header"
import { useAuth } from "../../../hooks/useAuth"
import { Registration } from "../../../models/Registration"
import { useAPI } from "../../../util/useAPI"

// note:
// WeBIP1 9/15/23: https://app.fieldday.com/events/25021ea8-4e51-4123-81f1-01b0eac444ed
// WeBIP2 4/19/24: https://app.fieldday.com/events/e072ba0e-c871-4ce6-bd3c-443c45be31af

// get this from the Typeform embed URL
const FORM_ID = "Y3A1RvXE"
const FIRSTWEBIPID = "25021ea8-4e51-4123-81f1-01b0eac444ed"

export default function IndividualSignUp() {
  const FieldDayAPI = useAPI()
  const { user } = useAuth()
  const [ returningUser, setReturningUser ] = useState<boolean | null>(null)

  useEffect(() => {
    if (!user) return

    // let's see if this user participated in the first one
    FieldDayAPI.listRegistrations(FIRSTWEBIPID, { perPage: 2000 }).then(resp => {
      setReturningUser(resp.registrations.some((reg: Registration) => reg.userId === user.id))
    })
  }, [])

  if (!user) return <Redirect to="/believe-in-portland" />

  if (returningUser === null) return (
    <Skeleton variant="rectangular" animation="wave" height={500} width='100%' />
  )

  return (<>
    <Helmet title="We Believe in Portland: sign-up details"/>
    <Header primaryHeader="We Believe in Portland"
      subheader="Individual Sign-up Details"
    />
    <Box
      style={{ width: '100%', minHeight: 'calc(100vh - 122px - 112px)' }}
    >
      <Typography variant="h4">{returningUser ? "Returning individual" : "New sign-up"}</Typography>

      <Widget id={FORM_ID}
        opacity={100}
        iframeProps={{
          title: 'Let us know where to send supplies',
        }}
        transitiveSearchParams
        medium="snippet"
        autoFocus
        hidden={{
          email: user.email,
          handle: "",
          company_name: "none",
          first_name: user.firstName ?? "unknown",
          last_name: user.lastName ?? "unknown",
          webip_repeat: returningUser.toString(),
          // webip_type = team, individual
          webip_type: "individual",
        }}
        style={{ width: '100%', height: '500px' }}
      />
    </Box>
  </>)
}
