import { List, ListItem } from "@mui/material"
import { Helmet } from "react-helmet"
import Header from "../../components/Global/Header"
import SubheadLink from "../../components/Global/SubheadLink"
import BackToWizardLink from "../../components/Wizard/BackToWizardLink"

export default function DonationDashboard() {
  return (<>
    <Header primaryHeader={"Donation dashboard"} breadCrumbLink={<BackToWizardLink />} />
    <Helmet title="Wizard: Donation dashboard" />
    <List>
      <ListItem><SubheadLink to="/wizard/donation/invoicing">Donation invoicing</SubheadLink></ListItem>
      <ListItem><SubheadLink to="/wizard/donation/disbursement">Donation disbursements</SubheadLink></ListItem>
      <ListItem><SubheadLink to="/wizard/donation/nonprofit">Donation nonprofit config</SubheadLink></ListItem>
    </List>
  </>)
}
