import { MetroRegion, OrgStatus } from "@fieldday/fielddayportal-model"
import {
  Grid, InputAdornment, MenuItem, Select, SelectChangeEvent
} from "@mui/material"
import _ from "lodash"
import { useEffect, useRef } from "react"
import { useAuth } from "../../hooks/useAuth"
import { useDirtyCallback } from "../../hooks/useDirty"
import { useReadonlyState } from "../../hooks/useReadonlyState"
import { NPO, NPOWithId } from "../../models/Npo"
import { UserInfo } from "../../models/UserInfo"
import useFormStyles from "../../styles/formStyles"
import { sortedJsonStringify } from "../../util/objectUtil"
import { matchState } from "../../util/stringUtil"
import { usaStates } from "../../util/usaStates"
import { useAPI } from "../../util/useAPI"
import { AutocompleteForNpoSearch } from "../Global/AutocompleteForNpoSearch"
import RegionSelector from "../Global/RegionSelector"
import NpoQuickDetails from "../NonProfits/NpoQuickDetails"

export default function ManagedFieldTripNpoFields(props: {
  npoForUpdate: NPO,
  setNpoForUpdate: React.Dispatch<React.SetStateAction<NPO>>,
  errors: Record<string, string | null>,
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string | null>>>,
  setDirty: (isDirty: boolean) => void,
  isUpdatedFromInit?: boolean,
  includeRegion?: boolean,
  column?: boolean,
  compact?: boolean,
}) {
  const {
    npoForUpdate, setNpoForUpdate, errors, setErrors, setDirty,
    isUpdatedFromInit = false,
    includeRegion = false,
    column = false,
    compact = false,
  } = props
  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()
  const { user } = useAuth()
  const [npos, setNpos] = useReadonlyState<NPOWithId[]>([])
  const [npoSearch, setNpoSearch] = useReadonlyState<string>("")
  const [npoState, setNpoState] = useReadonlyState<string>(
    matchState(npoForUpdate.primaryRegion as MetroRegion ?? MetroRegion.NotListed)?.code ?? "any")
  const filteredRef = useRef<NPOWithId | null>(null)
  const npoDisabled = !!npoForUpdate.id && npoForUpdate.id !== ""

  useEffect(() => {
    if (npoForUpdate.id) {
      setNpos([npoForUpdate as NPOWithId])
    }
  }, [npoForUpdate.id])

  useDirtyCallback({
    objForUpdate: npoForUpdate,
    forDirtyCompare: forDirtyCompare,
    setDirty: setDirty,
    shouldResetDirty: isUpdatedFromInit,
  })

  const clearNonProfitOrgError = (field: string) => {
    const nestedKey = `/nonProfitOrg/${field}`
    if (errors[nestedKey]) {
      errors[nestedKey] = null
    }

    const baseNestedKey = `/${field}`
    if (errors[baseNestedKey]) {
      errors[baseNestedKey] = null
    }

    const key = `/nonProfitOrg${field[0].toUpperCase() + field.substring(1, field.length)}`
    if (errors[key]) {
      errors[key] = null
    }

    setErrors(errors)
  }

  const clearAllNonProfitOrgErrors = () => {
    for (const err in errors) {
      if (err.includes("nonProfitOrg")) errors[err] = null
    }
    setErrors(errors)
  }

  useEffect(() => {
    const abortController = new AbortController()
    // use a timeout here to so we don't search on every letter
    const timeout = setTimeout(() => {
      if (npoSearch.length > 2) {
        const useState = npoState === 'any' ? undefined : npoState
        FieldDayAPI.searchPubNpos(abortController, npoSearch, useState).then(resp => {
          setNpos(resp.data.nonProfitOrgs as NPOWithId[])
          clearNonProfitOrgError("ein")
        })
      }
    }, 200)
    return () => {
      abortController.abort()
      clearTimeout(timeout)
    }
  }, [npoSearch])

  const handleNpoChange = (_event: React.SyntheticEvent<Element, Event>, newValue: NPOWithId | null, reason: string) => {
    clearAllNonProfitOrgErrors()
    if (reason === "clear") {
      filteredRef.current = null
      const updatedNpo = Object.assign({}, emptyManagedFieldTripNpo(user))
      setNpoForUpdate(updatedNpo)
      setNpos([])
    } else if (newValue) {
      setNpoForUpdate(newValue)
    }
  }

  const npoValue = npoForUpdate.ein ?
    npos.find(npo => npo.ein === npoForUpdate.ein) :
    { name: npoForUpdate.name, ein: npoForUpdate.ein, primaryCause: npoForUpdate.primaryCause } as NPOWithId

  const itemSizing = {
    xs: 12,
    sm: (() => {
      if (column) return 12
      if (includeRegion) return 4
      return 6
    })()
  }

  const npoError = !!errors["/nonProfitOrg/ein"] || !!errors["/nonProfitOrgEin"]
  return (<>
    <Grid item {...itemSizing}>
      <AutocompleteForNpoSearch
        handleNpoChange={handleNpoChange}
        setNpoSearch={setNpoSearch}
        searchedNpos={npos}
        error={!!errors["/nonProfitOrg/ein"] || !!errors["/nonProfitOrgEin"]}
        helperText={npoError ? "Nonprofit required to submit a donation." : "Search for nonprofit by name or employer ID (EIN)"}
        startAdornment={<InputAdornment position="start" sx={{ paddingLeft: "0.5em" }}>
          <Select
            variant="standard"
            disableUnderline={true}
            value={npoState ?? ""}
            onChange={(event: SelectChangeEvent) => { setNpoState(event.target.value) }}
          >
            <MenuItem value="any">Any state</MenuItem>
            {usaStates.map(s => {
              return <MenuItem key={s.code} value={s.code}>{s.code}</MenuItem>
            })}
          </Select>
        </InputAdornment>}
      />
    </Grid>
    <Grid item {...itemSizing} minHeight={compact ? "100px" : "240px"}>
      <NpoQuickDetails npo={npoValue} />
    </Grid>
    {includeRegion &&
    <Grid item xs={12} sm={4}>
      <RegionSelector
        className={formStyles.inlineRegionSelector}
        onChange={newValue => {
          const updatedNpo = Object.assign({}, npoForUpdate)
          updatedNpo.primaryRegion = newValue ?? undefined
          setNpoForUpdate(updatedNpo)
          clearNonProfitOrgError("primaryRegion")
        }}
        value={npoForUpdate.primaryRegion}
        error={!!errors["/nonProfitOrg/primaryRegion"]}
        helpText="Use the best match if possible"
        disabled={npoDisabled}
      />
    </Grid>}

  </>)
}

export function emptyManagedFieldTripNpo(user: UserInfo | null): NPO {
  return {
    id: "",
    name: "",
    primaryCause: "",
    ein: "",
    status: OrgStatus.Placeholder,
    primaryRegion: user?.region,
  }
}

function forDirtyCompare(npo: NPO): string {
  const dirtyFields = _.pick(npo, [
    "id", "name", "ein",
  ])

  return sortedJsonStringify(dirtyFields)
}
