import { Link } from "react-router-dom"

export default function BackToWizardLink() {
  return (
    <Link to="/wizard">
      Wizard
    </Link>
  )
}

export function BackToWizardDonationLink({ key }: { key: string }) {
  return (
    <Link to="/wizard/donation" key={key}>
      Donation
    </Link>
  )
}
