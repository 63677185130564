import {
  MatchableDonationModelResponse,
  MatchableDonationSchema,
  MatchableDonationUpdateModelI,
} from "@fieldday/fielddayportal-model"
import { Box, Button, Container, FormControl, Grid, Link, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDirty } from "../../hooks/useDirty"
import { AlertSeverity, useLoading } from "../../hooks/useLoading"
import { useScrollToError } from "../../hooks/useScrollToError"
import { NPO } from "../../models/Npo"
import useFormStyles from "../../styles/formStyles"
import ajv from "../../util/ajvConfig"
import { isoDate } from "../../util/dateUtil"
import { sortedJsonStringify } from "../../util/objectUtil"
import { useAPI } from "../../util/useAPI"
import { getNestedErrorMessages } from "../../util/validationErrors"
import { CurrencyTextFieldCents } from "../Forms/CurrencyTextField"
import MatchableDonationReceiptUploadForm, { LocalReceiptFile, uploadDonationReceiptFiles } from "./MatchableDonationReceiptUploadForm"

interface props {
  orgUnitId: string,
  header: string,
  onClose: (skipConfirm?: boolean) => void,
  matchableDonation: MatchableDonationModelResponse | null,
  donationNpo: NPO | null,
  reportDirty: (dirty: boolean) => void
}

export default function MatchableDonationUpdateForm(props: props) {
  const { orgUnitId, header, onClose, matchableDonation, donationNpo, reportDirty } = props
  const platformDonations = matchableDonation?.platformDonations
  const isPlatformDonation = platformDonations && platformDonations.length > 0

  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()

  const { setAlert, loadStart, loadEnd } = useLoading()

  const [errors, setErrors] = useState<Record<string, string | null>>({})
  const scrollToError = useScrollToError()

  const [localFilesArray, setLocalFilesArray] = useState<LocalReceiptFile[]>([])

  const clearDonationErrors = (field: string) => {
    const key = `/${field}`
    if (errors[key]) {
      errors[key] = null
      setErrors(errors)
    }
  }

  useEffect(() => {
    const matchableDonationForUpdate = matchableDonationResponseAsUpdate(matchableDonation)
    if (matchableDonationForUpdate) {
      setMatchableDonationForUpdate(matchableDonationForUpdate)
    }
  }, [matchableDonation?.id])

  const [matchableDonationForUpdate, setMatchableDonationForUpdate] = useState<MatchableDonationUpdateModelI>(
    matchableDonationResponseAsUpdate(matchableDonation) ?? emptyMatchableDonation()
  )

  const [matchableDonationDirty] = useDirty({
    objForUpdate: matchableDonationForUpdate,
    forDirtyCompare: (matchableDonationForUpdate) => {return forDirtyCompare(matchableDonationForUpdate, localFilesArray)},
  })
  // This is kind of messy, but the parent component is responsible
  // for modal switching and call to window.confirm, so it needs
  // to know if this component is dirty. So if dirtyFields changes,
  // report it up to the parent via the reportDirty callback.
  useEffect(() => {
    reportDirty(matchableDonationDirty)
  }, [matchableDonationDirty])

  const handleAmountChange = (newVal?: number) => {
    const val = !newVal || isNaN(newVal) ? 0 : newVal
    clearDonationErrors("amount")
    const updatedMatchableDonation = Object.assign({}, matchableDonationForUpdate)
    updateMatchableDonation(updatedMatchableDonation, "amount", val)
    setMatchableDonationForUpdate(updatedMatchableDonation)
  }

  const handleDateChange = (newDate: string | null | Dayjs) => {
    clearDonationErrors("dateISO")
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)

    if (newDate) {
      const dateISO = isoDate(newDate)
      updateMatchableDonation(updatedDonation, "dateISO", dateISO)
    } else {
      updateMatchableDonation(updatedDonation, "dateISO", "")
    }

    setMatchableDonationForUpdate(updatedDonation)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)
    const field = event.target.name as keyof MatchableDonationUpdateModelI
    clearDonationErrors(field)
    updateMatchableDonation(updatedDonation, field, event.target.value)
    setMatchableDonationForUpdate(updatedDonation)
  }

  const validateMatchableDonation = (matchableDonation: MatchableDonationUpdateModelI) => {
    const validateSchema = ajv.compile(MatchableDonationSchema)

    const request = Object.assign({}, matchableDonation, {
      nonProfitOrgId: donationNpo?.id
    })

    validateSchema(request)
    const errors = validateSchema.errors ?? []

    if (errors.length > 0) {
      const errs = getNestedErrorMessages(errors)
      setErrors(errs)
      setAlert(AlertSeverity.ERROR, "invalid donation details")
      scrollToError()
    } else {
      setErrors({})
      return request
    }
  }

  const updateDonation = () => {
    const validatedRequest = validateMatchableDonation(matchableDonationForUpdate)
    if (!validatedRequest || !matchableDonation) return

    const submit = async () => {
      loadStart(true)
      try {
        const updatedDonation = await FieldDayAPI.updateMatchableDonation(orgUnitId, matchableDonation.id, validatedRequest)
        return updatedDonation
      } catch (e) {
        const err = e as any
        setAlert(AlertSeverity.ERROR, err.response?.data?.message || `${err}`)
      } finally {
        loadEnd()
      }
    }

    submit()
    .then((submitResp) => {
      const newMatchableDonation = submitResp?.data.donation
      if (newMatchableDonation?.id && localFilesArray.length > 0) {
        uploadDonationReceiptFiles(newMatchableDonation, localFilesArray, FieldDayAPI)
        .then(() => {
          onClose(true)
        })
        .catch(() => {
          setAlert(AlertSeverity.ERROR, "Error uploading receipt file. Please try again.")
        })
        .finally(() => {
          loadEnd()
        })
      } else {
        loadEnd()
        onClose(true)
      }
    })
    .catch((e) => {
      const err = e as any
      setAlert(AlertSeverity.ERROR, err.response?.data?.message || `${err}`)
    })
  }

  return (
    <>
      <Helmet title={header} />
      <Container maxWidth="md">
        <Box>
          <form className={formStyles.detailsForm}>
            <Grid sx={{ marginTop: "0.5em" }} container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    label="Nonprofit name"
                    value={donationNpo?.name ?? ""}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    label="Nonprofit tax ID (EIN)"
                    value={donationNpo?.ein ?? ""}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={formStyles.textInput}>
                  <CurrencyTextFieldCents
                    id="amount"
                    name="amount"
                    label="Donation amount"
                    value={matchableDonationForUpdate.amount}
                    onChange={handleAmountChange}
                    error={errors["/amount"]}
                    disabled={isPlatformDonation}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={formStyles.textInput}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Donation date"
                      value={matchableDonationForUpdate.dateISO}
                      onChange={handleDateChange}
                      renderInput={params => <TextField {...params} error={!!errors["/dateISO"]} />}
                      disabled={isPlatformDonation}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {!isPlatformDonation && <Grid item xs={12} sm={6}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    id="donationUrl"
                    name="donationUrl"
                    label="Donation website"
                    value={matchableDonationForUpdate.donationUrl}
                    onChange={handleChange}
                    error={!!errors["/donationUrl"]}
                    helperText={errors["/donationUrl"] ?? " "}
                  />
                </FormControl>
              </Grid>}

              <Grid item xs={12}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    id="contextMessage"
                    name="contextMessage"
                    label="Memo"
                    value={matchableDonationForUpdate.contextMessage}
                    onChange={handleChange}
                    multiline
                    minRows={3}
                    maxRows={6}
                    error={!!errors["/contextMessage"]}
                    helperText={errors["/contextMessage"] ?? " "}
                  />
                </FormControl>
              </Grid>

              { (localFilesArray.length === 0 && matchableDonation?.receiptUrl) && <Grid item xs={12}>
                <Link href={`${matchableDonation.receiptUrl}`}>{"Download current receipt"}</Link>
              </Grid>}

              {!isPlatformDonation &&
                <MatchableDonationReceiptUploadForm buttonText="Upload new receipt" setLocalFilesArray={setLocalFilesArray}/>
              }

              <Grid item xs={12}>
                <Box textAlign="end">
                  <Button color="primary" variant="contained" onClick={() => {
                    updateDonation()
                  }}>
                    Update donation
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  )
}


function emptyMatchableDonation(): MatchableDonationUpdateModelI {
  return {
    amount: 0,
    donationUrl: "",
    contextMessage: "",
    dateISO: "",
  }
}

export function updateMatchableDonation<MatchableDonationKey extends keyof MatchableDonationUpdateModelI>(
  matchableDonation: MatchableDonationUpdateModelI,
  property: MatchableDonationKey,
  value: MatchableDonationUpdateModelI[MatchableDonationKey]
): void {
  matchableDonation[property] = value
}

function forDirtyCompare(donation: MatchableDonationUpdateModelI, localFiles: LocalReceiptFile[]): string {
  const dirtyFields = _.pick(donation, [
    "nonProfitOrgId",
    "amount",
    "dateISO",
    "status",
    "receiptUrl",
    "donationUrl",
    "contextMessage",
  ])

  return sortedJsonStringify({
    ...dirtyFields,
    localFiles: localFiles.map(f => f.localUrl)
  })
}

function matchableDonationResponseAsUpdate(matchableDonation: MatchableDonationModelResponse | null): MatchableDonationUpdateModelI | null {
  if (!matchableDonation) return matchableDonation
  return {
    ...matchableDonation,
    approvedDateISO: matchableDonation?.approvedDateISO ?? undefined,
    matchedDateISO: matchableDonation?.matchedDateISO ?? undefined
  }
}
