import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper, SxProps, Typography } from "@mui/material"
import { Theme } from '@mui/material/styles'
import { useRef, useState } from "react"
import useGlobalStyles from '../../styles/globalStyles'


interface Props {
  children: React.ReactNode,
  primaryButton: JSX.Element | null,
  defaultColor: 'warning' | 'primary' | 'inherit' | 'info' | 'error',
  variant?: 'contained' | 'outlined' | 'text',
  setPadding?: string | undefined,
  dashboard?: boolean,
  disabled?: boolean,
  fullWidth?: boolean,
  useText?: string | undefined,
  setTopMargin?: string,
  setWidth?: string,
  expandLabel?: string,
  sx?: SxProps<Theme>,
}

export default function PopperButtonGroup ({
  children, primaryButton, defaultColor, variant = "outlined", setPadding, dashboard,
  disabled = false, fullWidth = false, useText, setTopMargin, setWidth, expandLabel, sx,
}: Props) {
  const globalStyles = useGlobalStyles()
  const anchorEl = useRef(null)
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const variantStyle = dashboard ? variant+"Dash" : variant

  const toggleMenu = () => {
    setIsPopperOpen(!isPopperOpen)
  }

  const handleClose = () => {
    setIsPopperOpen(false)
  }

  // if it's an array of nulls, then just render the primary button alone
  if (!children) return primaryButton
  try {
    const childrenAsArray = children as Array<React.ReactNode>
    if (childrenAsArray.every(c => !c)) return primaryButton
  } catch (error) {
    // pass
  }

  return (
    <div style={{ marginTop: setTopMargin ? setTopMargin : '1em' }}>
      <ButtonGroup
        sx={sx}
        style={{ width: fullWidth ? "100%" : setWidth ? setWidth : "auto" }}
        disabled={disabled}
        ref={anchorEl}
        variant={variant}
        color={defaultColor}
      >
        {primaryButton}
        <Button ref={anchorEl}
          aria-controls={isPopperOpen ? 'menu-list' : undefined}
          aria-expanded={isPopperOpen ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label={expandLabel}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            toggleMenu()
          }}
          variant={variant}
          color={defaultColor}
          className={isPopperOpen ? `${globalStyles.popperExpandActive} ${variantStyle}` : `${globalStyles.popperExpandInactive} ${variantStyle}`}
          style={{ padding:`${setPadding ? setPadding : "0.5em 0.75em"}`, width: useText && setWidth ? "100%" : "auto" }}
          >
            {useText ?
            <Typography fontWeight={600} color={(defaultColor === 'primary' && variant === 'contained') ? '#FFFFFF' : defaultColor}>
              {useText}
            </Typography>
            :
            <ExpandMoreIcon color={disabled ? "disabled" : defaultColor} />
            }
        </Button>
      </ButtonGroup>
      <Popper open={isPopperOpen} anchorEl={anchorEl.current} placement='bottom-end' transition
        style={{ zIndex: 10 }} disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose} >
                <MenuList id='menu-list' style={{ marginBottom: '.3em' }}>
                  <Box
                    // This blocks propagation of keystrokes to the MenuList object to fix an issue
                    // where certain keystrokes in child inputs change focus back to the MenuList.
                    // https://stackoverflow.com/a/58379376
                    // https://github.com/mui/material-ui/blame/v4.5.1/packages/material-ui/src/MenuList/MenuList.js#L134
                    onKeyDown={e => e.stopPropagation()}
                  >
                    { children }
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
