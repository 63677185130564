import ArrowRight from '@mui/icons-material/ArrowRight';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useGlobalStyles from '../../styles/globalStyles';

const dismissKey = "cta-dismiss"
const dismissedValue = "dismissed"

export default function CallToActionBanner() {
  const { user } = useAuth()
  const { pathname } = useLocation()
  const history = useHistory()
  const globalStyles = useGlobalStyles()
  const [dismissed, setDismissed] = useState(sessionStorage.getItem(dismissKey) === dismissedValue)

  const redirect = () => {
    history.push('/profile/edit', { from: history.location })
  }

  const dismiss = () => {
    setDismissed(true)
  }

  useEffect(() => {
    sessionStorage.setItem(dismissKey, dismissed ? dismissedValue : "")
  }, [dismissed])

  if (dismissed) return null

  if (user && !user.isComplete && pathname !== "/profile/edit") {
    return (
      <Box
        className={globalStyles.ctaBanner}
      >
        <button onClick={redirect} className={`${globalStyles.ctaButton} cta`}>
          <ErrorOutline color="error" sx={{ marginRight: "10px" }}/>
          Add details to complete your profile
          <ArrowRight sx={{ color: "rgba(0, 0, 0, 0.75)" }} />
        </button>
        <button className={`${globalStyles.ctaButton} dismiss`} onClick={dismiss}>
          &times;
        </button>
      </Box>
    )
  }

  return null
}
