import { createContext, useContext, useState } from "react"

type ContextProps = {
  children: React.ReactNode
}

type ErrorMessagesForContext = {
  infoMessage?: string
  setInfoMessage: (message?: string) => void
}

const ErrorMessagesContext = createContext<ErrorMessagesForContext>(
  {} as ErrorMessagesForContext
)

export function ErrorMessagesProvider({ children }: ContextProps) {
  const errorMessages = useProvideErrorMessages()

  return (
    <ErrorMessagesContext.Provider value={errorMessages}>
      {children}
    </ErrorMessagesContext.Provider>
  )
}

export const useErrorMessages = () => {
  return useContext(ErrorMessagesContext)
}

export function useProvideErrorMessages(): ErrorMessagesForContext {
  const [infoMessage, setInfoMessage] = useState<string | undefined>()

  return { infoMessage, setInfoMessage }
}
