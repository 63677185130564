import { DonationInvoiceBatchModelResponse, DonationInvoiceBatchStatus, MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import {
  Chip,
  Container,
  Divider,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet"
import Header from "../../../components/Global/Header"
import BackToWizardLink, { BackToWizardDonationLink } from "../../../components/Wizard/BackToWizardLink"
import { NPO } from "../../../models/Npo"
import { shortDate } from "../../../util/dateUtil"
import { dollarCents } from "../../../util/stringUtil"
import DonationInvoicesCompleted from "./DonationInvoicesCompleted"
import DonationInvoicesCreated from "./DonationInvoicesCreated"
import DonationInvoicesInvoiced from "./DonationInvoicesInvoiced"
import DonationInvoicesPreviews from "./DonationInvoicesPreviews"

export default function DonationInvoicing() {
  const [refreshCounter, setRefreshCounter] = useState(0)
  function triggerRefresh() {
    setRefreshCounter(refreshCounter + 1)
  }

  return (<main>
    <Helmet title="Wizard: Donation invoicing" />
    <Header primaryHeader="Invoicing" breadCrumbLink={<BackToWizardLink />} breadCrumbLinks={[<BackToWizardDonationLink key="Donation"/>]} />
    <Container maxWidth="lg" style={{ marginTop: "1em", marginBottom: "2em" }}>
      <Typography variant="h5">Needs batching</Typography>
      <DonationInvoicesPreviews refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />

      <Typography pt={"2em"} variant="h5">In progress batches</Typography>
      <DonationInvoicesCreated refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />
      <Divider sx={{ mb: "2em", visibility: "hidden" }}/>
      <DonationInvoicesInvoiced refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />

      <Typography pt={"2em"} variant="h5">Completed batches</Typography>
      <DonationInvoicesCompleted refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />
    </Container>
  </main>)
}

export function DonationsSummary({ matchableDonations, nonProfitOrgs }: {
  matchableDonations: MatchableDonationModelResponse[],
  nonProfitOrgs: NPO[],
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Approved on</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>NPO name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Approved</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Fees approved</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Donated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(matchableDonations, "approvedDateISO", "asc").map(donation => {
            const npo = nonProfitOrgs.find(npo => npo.id === donation.nonProfitOrgId)
            if (!npo) return <></>
            return (
              <DonationSummary donation={donation} nonProfitOrg={npo} key={donation.id} />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function DonationSummary({ donation, nonProfitOrg }: {
  key: string,
  donation: MatchableDonationModelResponse,
  nonProfitOrg: NPO,
}) {
  return (
    <TableRow key={donation.id}>
      <TableCell>
        <Typography>
          {shortDate(dayjs(donation.approvedDateISO))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {nonProfitOrg.name}
        </Typography>
      </TableCell>
      <TableCell>
        {dollarCents(donation.approvedAmount)}
      </TableCell>
      <TableCell>
        {dollarCents(donation.feesApprovedAmount)}
      </TableCell>
      <TableCell>
        {dollarCents(donation.amount)}
      </TableCell>
    </TableRow>
  )
}

export function InvoiceStatusChip({ batch, sx }: { batch: DonationInvoiceBatchModelResponse, sx?: SxProps }) {
  const chipColor = (() => {
    switch (batch.status) {
      case DonationInvoiceBatchStatus.Created:
        return "secondary"
      case DonationInvoiceBatchStatus.Invoiced:
        return "success"
      case DonationInvoiceBatchStatus.Paid:
        return "primary"
      default:
        return "secondary"
    }
  })()

  return <Chip sx={sx} label={batch.status} size="small" color={chipColor} />
}
