import { DonationMatchPolicyStatus, MatchApprovalStatus, MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
} from "@mui/material"
import dayjs from "dayjs"
import qs from "qs"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import EmptyPageCallToAction from "../../components/Global/EmptyPageCallToAction"
import Header from "../../components/Global/Header"
import MatchableDonationModal, { OpenDonationModal } from "../../components/Teams/MatchableDonationModal"
import MatchableDonationsList from "../../components/Teams/MatchableDonationsList"
import MatchableDonationPolicySummaryList from "../../components/User/MatchableDonationPolicySummaryList"
import { useAuth } from "../../hooks/useAuth"
import { AlertSeverity, useLoading } from "../../hooks/useLoading"
import { usePagination } from "../../hooks/usePagination"
import { DonationMatchPolicy } from "../../models/Donations"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserInfo } from "../../models/UserInfo"
import useOrgStyles from "../../styles/orgStyles"
import { downloadFile } from "../../util/download"
import { CTAIconSrc } from "../../util/icons"
import { setQueryParams } from "../../util/urlManipulation"
import { MatchableDonationFilter, useAPI } from "../../util/useAPI"

export default function MatchableUserDonations() {
  const FieldDayAPI = useAPI()
  const orgStyles = useOrgStyles()
  const { loadStart, loadEnd, setAlert } = useLoading()
  const { user } = useAuth()

  const query = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { page, setPage, maxPage, updateMaxPage } = usePagination()

  const [donationFilters, _setDonationFilters] = useState<MatchableDonationFilter>({
    page: 1,
    perPage: 10,
    ...query,
  })
  const setDonationFilters = (filter: MatchableDonationFilter): void => {
    const newDonationFilters = { ...donationFilters, ...filter }
    setQueryParams(newDonationFilters)
    _setDonationFilters(newDonationFilters)
    setPage(newDonationFilters.page)
  }

  const [matchableDonations, setMatchableDonations] = useState<MatchableDonationModelResponse[] | null>(null)
  const [currentDonation, setCurrentDonation] = useState<MatchableDonationModelResponse | null>(null)
  const [donationNpos, setDonationNpos] = useState<Map<string, NPO>>(new Map([]))
  const [donationOrgs, setDonationOrgs] = useState<Map<string, OrgUnit>>(new Map([]))
  const [matchableDonationPolicies, setMatchableDonationPolicies] = useState<DonationMatchPolicy[] | null>(null)
  const policyListStartDate = dayjs().startOf('year').format('YYYY-MM-DD')
  const policyListEndDate = dayjs().endOf('year').format('YYYY-MM-DD')
  const currentDonationNpo = currentDonation?.nonProfitOrgId ? donationNpos.get(currentDonation.nonProfitOrgId) : undefined
  const currentDonationOrg = currentDonation?.orgUnitId ? donationOrgs.get(currentDonation.orgUnitId) : undefined
  const usersOrgUnits = user?.orgUnitMemberships.map(ou => ou.orgUnit)
  const [openModal, setOpenModal] = useState<OpenDonationModal>(OpenDonationModal.NONE)

  const listDonations = async (
    donationFilters: MatchableDonationFilter,
  ): Promise<{
    donations: MatchableDonationModelResponse[],
    donationNpos: Map<string, NPO>,
    donationOrgs: Map<string, OrgUnit>,
  }> => {
    const resp = await FieldDayAPI.listMyDonations(donationFilters)

    const body = resp.data
    const donations = body.donations
    const nonProfitOrgs = body.nonProfitOrgs
    const orgUnits = body.orgUnits
    updateMaxPage(body.total)
    return {
      donations: donations,
      donationNpos: new Map(nonProfitOrgs.map(npo => [npo.id, npo])),
      donationOrgs: new Map(orgUnits.map(orgUnit => [orgUnit.id, orgUnit]))
    }
  }

  const listUserDonations = async (donationFilters: MatchableDonationFilter) => {
    loadStart(true)
    const { donations, donationNpos, donationOrgs } = await listDonations(donationFilters)
    setMatchableDonations(donations)
    setDonationNpos(donationNpos)
    setDonationOrgs(donationOrgs)
    setCurrentDonation(donations[0])
    setDonationFilters(donationFilters)
    await listDonationMatchPolicies()
    loadEnd()
  }

  const listDonationMatchPolicies = async () => {
    if(!usersOrgUnits || usersOrgUnits.length === 0) return
    const promises = usersOrgUnits.map(ou => {
      return FieldDayAPI.listDonationMatchPolicies(ou.id, policyListStartDate, policyListEndDate, DonationMatchPolicyStatus.Active)
    })
    Promise.all(promises).then(async resp => {
      const policies = resp.map(resp => resp.data.donationMatchPolicies)
      setMatchableDonationPolicies(policies.flat())
    }).catch((err) => {
      setAlert(AlertSeverity.ERROR, `${err.message}`)
    }).finally(() => {
      loadEnd()
    })
  }

  useEffect(() => {
    listUserDonations(donationFilters)
  }, [])

  const fetchPage = (page: number, donationFilters: MatchableDonationFilter) => {
    const newDonationFilters = { ...donationFilters, page: page }
    listUserDonations(newDonationFilters)
  }

  return (<>
    <Helmet title="Your donations" />
    <Box className={orgStyles.horizontalNav}>
      <Container maxWidth="md">
        {(user && matchableDonations && matchableDonations.length > 0) && <>
          <Grid container justifyContent="flex-end">
            <Button
              style={{ marginLeft: "2em" }}
              variant="outlined"
              onClick={() => {
                const filter: MatchableDonationFilter = {
                  startDate: donationFilters.startDate,
                  endDate: donationFilters.endDate,
                  approvalStatus: MatchApprovalStatus.Approved,
                }

                loadStart(true)
                listDonations(filter).then(({ donations, donationNpos }) => {
                  const invoice = generateDonationsCsv(donations, donationNpos, user)
                  loadEnd()
                  downloadFile(invoice, `donations ${donationFilters.startDate} ${donationFilters.endDate}.csv`, "text/csv")
                })
              }}
              aria-label="Download donations as CSV"
            >
              Download donations
            </Button>
          </Grid>
        </>}

        {maxPage > 1 && <Box style={{ display: "flex", justifyContent: "center", marginTop: "1em" }} >
          <Pagination count={maxPage} page={page}
            onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
              fetchPage(value, donationFilters)
            }} />
        </Box>}
      </Container>
    </Box>

    <Container maxWidth="md">
      <Header primaryHeader="Your donations" withDivider />
      <MatchableDonationModal
        canAdmin={false}
        adminView={false}
        openModal={openModal}
        currentDonation={currentDonation}
        donationNpo={currentDonationNpo}
        donationOrgUnit={currentDonationOrg}
        listDonations={() => {
          listUserDonations(donationFilters)
          setCurrentDonation(null)
        }}
        setOpenModal={setOpenModal}
      />

      {user && <MatchableDonationsList
        matchableDonations={matchableDonations}
        donationUsers={new Map([[user.id, user]])}
        donationNpos={donationNpos}
        adminView={false}
        setCurrentDonation={setCurrentDonation}
        setOpenModal={setOpenModal}
      />}

      {(matchableDonations && matchableDonations.length === 0) && <EmptyPageCallToAction
        header="No donations"
        description={<>
          You have not made any matchable donations.
          Go to your team&apos;s donation page to upload a new donation for matching.
        </>}
        iconSrc={CTAIconSrc.Nonprofit}
      />}
      {matchableDonationPolicies && matchableDonationPolicies.length > 0 &&
        <MatchableDonationPolicySummaryList
          matchableDonationPolicies={matchableDonationPolicies}
          orgUnits={usersOrgUnits}
        />
      }
    </Container>
  </>)
}

function generateDonationsCsv(
  matchableDonations: MatchableDonationModelResponse[],
  donationNpos: Map<string, NPO>,
  user: UserInfo,
): string {
  const donationEntries: string[] = []
  for (const donation of matchableDonations) {
    const npo = donationNpos.get(donation.nonProfitOrgId)
    if (npo) donationEntries.push(donationCsvLine(donation, npo, user))
  }

  return [
    donationCsvHeader,
    ...donationEntries,
  ].join("\n")
}

export const donationCsvLine = (donation: MatchableDonationModelResponse, npo: NPO, user: UserInfo): string => {
  const donatedDollars = (donation.amount ?? 0) / 100
  const approvedAmount = donation.approvedAmount ? donation.approvedAmount / 100 : donatedDollars
  const matchedAmount = donation.matchedAmount ? donation.matchedAmount / 100 : undefined

  return [
    donation.createdAt,
    donation.status,
    donation.approvalStatus,
    donation.dateISO,
    donatedDollars,
    approvedAmount,
    matchedAmount,
    npo.name,
    npo.ein,
    user.firstName,
    user.lastName,
    user.email,
    donation.receiptUrl,
    donation.donationUrl,
  ].join(",")
}

export const donationCsvHeader = [
  "Reported date",
  "Status",
  "Approval status",
  "Donation date",
  "Donation amount",
  "Approved amount",
  "Matched amount",
  "Nonprofit name",
  "Nonprofit EIN",
  "First name",
  "Last name",
  "Email",
  "Receipt download link",
  "Nonprofit donation page",
].join(",")
