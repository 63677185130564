import { ParsedQs } from "qs"

export type GenericQueryParams = {
  [key: string]: string | string[] | readonly string[] | number | number[] | null | undefined
}

// set the query params on the current url without reloading or redirecting
// if you want to remove a param, set it to null
export function setQueryParams(params: GenericQueryParams): void {
  const url = new URL(window.location.href)
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      if (Array.isArray(value)) {
        url.searchParams.delete(key) // clear it so we don't append to existing
        value.forEach(v => url.searchParams.append(key, v.toString()))
      } else {
        url.searchParams.set(key, value.toString())
      }
    } else {
      url.searchParams.delete(key)
    }
  }
  window.history.replaceState({}, '', url)
}

export function setHash(hash: string): void {
  const url = new URL(window.location.href)
  url.hash = hash
  window.history.replaceState({}, '', url)
}

type params = undefined | string | qs.ParsedQs | string[] | qs.ParsedQs[]

export function queryParamAsArray(params: params): (string | ParsedQs)[] {
  if (!params) return []
  if (Array.isArray(params)) {
    return params
  } else {
    return [params]
  }
}

export function queryParamStringArray(params: string[] | string): string[] {
  return queryParamAsArray(params) as string[]
}

export function parseQsAsEnums<Enum>(parser: (str: string | null | undefined) => Enum | undefined, params?: params): Enum[] {
  return queryParamAsArray(params)
    .map(param => parser(param as string))
    .filter((param): param is Enum => !!param)
}

export function sanitizeExternalUrl(url?: string): string | undefined {
  if (!url) return
  if (url.startsWith("http://") || url.startsWith("https://")) return url
  return `http://${url}`
}
