import {
  DonationInvoiceBatchStatus,
  MatchableDonationModelResponse,
} from "@fieldday/fielddayportal-model"
import CancelIcon from "@mui/icons-material/Cancel"
import CloseIcon from "@mui/icons-material/Close"
import CopyIcon from "@mui/icons-material/ContentCopy"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import { useEffect } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import { AlertSeverity, useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import { OrgUnit } from "../../../models/OrgUnit"
import useFormStyles from "../../../styles/formStyles"
import { dayjsTz, isoDate, shortDate, today } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents, formattedCents } from "../../../util/stringUtil"
import { DonationInvoiceBatchModelResponseWithUpdatedAt, useAPI } from "../../../util/useAPI"
import { InvoiceStatusChip } from "./DonationInvoicing"

export default function DonationInvoicesCreated({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [donationInvoiceBatches, setDonationInvoiceBatches] = useReadonlyState<DonationInvoiceBatchModelResponseWithUpdatedAt[] | null>(null)
  const [batchOrgUnits, setBatchOrgUnits] = useReadonlyState<OrgUnit[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useReadonlyState<NPO[] | null>(null)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.listDonationInvoiceBatches([DonationInvoiceBatchStatus.Created], abortController).then((res) => {
      setDonationInvoiceBatches(_.orderBy(res.data.donationInvoiceBatches, "updatedAt", "desc"))
      setBatchOrgUnits(res.data.orgUnits)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    }).finally(() => {loadEnd()})

    return () => {
      abortController.abort()
    }
  }, [refreshCounter])

  return (<>
    {(donationInvoiceBatches && donationInvoiceBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No invoices need to be created"
        description=""
        iconSrc={CTAIconSrc.HighFive}
      />
      : <Grid container spacing={2} mb={4} pl={2} pr={19}>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Last updated</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"}>Team name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Match amount</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Fees amount</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Batch id</Typography>
        </Grid>
      </Grid>
    }

    {donationInvoiceBatches?.map(batch => {
      const orgUnit = batchOrgUnits?.find(ou => ou.id === batch.orgUnitId)
      if (!orgUnit) return <></>
      return (
        <DonationInvoiceProgressSummary
          key={batch.id}
          batch={batch}
          orgUnit={orgUnit}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
      })
    }
  </>)
}

function DonationInvoiceProgressSummary({ batch, orgUnit, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationInvoiceBatchModelResponseWithUpdatedAt,
  orgUnit: OrgUnit,
  nonProfitOrgs: Readonly<NPO[]>,
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()

  const { setAlert, loadStart } = useLoading()

  const [expanded, setExpanded] = useReadonlyState(false)

  const [openModal, setOpenModal] = useReadonlyState<"none" | "invoiced" | "paid">("none")
  function closeModal() {
    setOpenModal("none")
  }

  const [invoiceId, setInvoiceId] = useReadonlyState("")
  const defaultInvoiceDate = today()
  const [invoiceDate, setInvoiceDate] = useReadonlyState(defaultInvoiceDate)

  return (<>
    <Dialog open={openModal === "invoiced"} fullWidth maxWidth="xs" onClose={closeModal}>
      {(openModal === "invoiced") && <>
        <DialogTitle>
          Set invoice details
          <IconButton
            aria-label="closeDonationModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal()} }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container>
            <FormControl className={formStyles.textInputFullWidth}>
              <TextField
                label="Invoice id"
                onChange={(event) => {
                  setInvoiceId(event.target.value)
                }}
              />
            </FormControl>
            <FormControl sx={{ marginY: 4 }} className={formStyles.textInputFullWidth}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={"Invoice sent on"}
                  value={invoiceDate}
                  onChange={(newDate: string | null | Dayjs) => {
                    if (newDate) {
                      const dateISO = isoDate(newDate)
                      setInvoiceDate(dateISO)
                    } else {
                      setInvoiceDate(defaultInvoiceDate)
                    }
                  }}
                  renderInput={params => <TextField
                    {...params}
                    helperText={"The date that the invoice was sent"}
                  />}
                />
              </LocalizationProvider>
            </FormControl>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            if (invoiceId === "" || invoiceDate === "") {
              setAlert(AlertSeverity.ERROR, "Invoice id and date are required to mark as invoiced")
            } else {
              loadStart(true)
              FieldDayAPI.updateDonationInvoiceBatch(batch.id, {
                status: DonationInvoiceBatchStatus.Invoiced,
                invoiceId: invoiceId,
                invoicedAt: invoiceDate,
              }).then(() => {
                closeModal()
                triggerRefresh()
              })
            }
          }}>Mark as invoiced</Button>
        </DialogActions>
      </>}
    </Dialog>

    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={2} marginTop={"0.5em"}>
                <Typography>
                  {shortDate(dayjsTz(batch.updatedAt))}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography marginTop={"0.5em"} display="inline-block">
                  {orgUnit.name}
                </Typography>
                <InvoiceStatusChip sx={{ ml: 1 }} batch={batch} />
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(`${formattedCents(batch.invoiceMatchCents)}`)
                      setAlert(AlertSeverity.SUCCESS, `Copied ${formattedCents(batch.invoiceMatchCents)} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {dollarCents(batch.invoiceMatchCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(`${formattedCents(batch.invoiceFeesCents)}`)
                      setAlert(AlertSeverity.SUCCESS, `Copied ${formattedCents(batch.invoiceFeesCents)} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {dollarCents(batch.invoiceFeesCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    sx={{ float: "" }}
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(batch.batchId)
                      setAlert(AlertSeverity.SUCCESS, `Copied ${batch.batchId} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {batch.batchId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {expanded && <Grid item xs={12}>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Created on:</Typography>
                <Typography>{shortDate(dayjsTz(batch.createdAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch ends on:</Typography>
                <Typography>{shortDate(dayjs(batch.endAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch id:</Typography>
                <Typography>{batch.batchId}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Donations:</Typography>
                <Typography>{batch.matchableDonations?.length}</Typography>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        <Button sx={{ mr: "1em", height: "2.5em" }} onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setOpenModal("invoiced")
        }} variant="outlined">
          Invoiced
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <DonationsSummary
          batchId={batch.id}
          matchableDonations={batch.matchableDonations ?? []}
          nonProfitOrgs={nonProfitOrgs}
          triggerRefresh={triggerRefresh}
        />
      </AccordionDetails>
    </Accordion>
  </>)
}

export function DonationsSummary({ batchId, matchableDonations, nonProfitOrgs, triggerRefresh }: {
  batchId: string,
  matchableDonations: MatchableDonationModelResponse[],
  nonProfitOrgs: Readonly<NPO[]>,
  triggerRefresh: () => void,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Approved on</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>NPO name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Approved</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Fees approved</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Donated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(matchableDonations, "approvedDateISO", "asc").map(donation => {
            const npo = nonProfitOrgs.find(npo => npo.id === donation.nonProfitOrgId)
            if (!npo) return <></>
            return (
              <DonationSummary
                batchId={batchId}
                donation={donation}
                nonProfitOrg={npo}
                key={donation.id}
                triggerRefresh={triggerRefresh}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function DonationSummary({ batchId, donation, nonProfitOrg, triggerRefresh }: {
  key: string,
  batchId: string,
  donation: MatchableDonationModelResponse,
  nonProfitOrg: NPO,
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()
  const { loadStart } = useLoading()

  return (
    <TableRow key={donation.id}>
      <TableCell>
        <Typography>
          {shortDate(dayjs(donation.approvedDateISO))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {nonProfitOrg.name}
        </Typography>
      </TableCell>
      <TableCell>
        {dollarCents(donation.approvedAmount)}
      </TableCell>
      <TableCell>
        {dollarCents(donation.feesApprovedAmount)}
      </TableCell>
      <TableCell>
        {dollarCents(donation.amount)}
      </TableCell>
      <TableCell>
        {<IconButton
          onClick={(() => {
            loadStart(true)
            FieldDayAPI.removeDonationFromInvoiceBatch(batchId, donation.id).then(() => {
              triggerRefresh()
            })
          })}
        >
          <CancelIcon />
        </IconButton>}
      </TableCell>
    </TableRow>
  )
}
