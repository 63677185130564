import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert'
import { LoadingAlertState, useLoading } from '../../hooks/useLoading'

export default function AlertBar({ loadingState }: { loadingState: LoadingAlertState }) {
  const { clearAlert } = useLoading();
  const { alertQueue } = loadingState
  const alert = alertQueue.peek()
  const count = alertQueue.size()
  const multiple = count > 1

  return alert ? (
    <Snackbar open={true} autoHideDuration={null} onClose={() => clearAlert()}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <MuiAlert elevation={6} variant="filled" severity={alert.severity}>
        { multiple && `1/${count}` } { alert.message }
      </MuiAlert>
    </Snackbar>
  ) : null
}
