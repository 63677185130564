import type { } from '@mui/lab/themeAugmentation'; // for MuiTimeline
import { red } from '@mui/material/colors';
import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';

// primary palette
const deepForest = '#004D43'
const mountainMeadow = '#009579'
const lichen = '#E4ECDE'

// primary neutrals
const darkGray = '#373A36'
const gray = '#6E7C7C'
const offWhite = '#F4F5F5'

// secondary palette
const secondaryRed = "#E36235"
const secondaryRedLight = "#EDC7A2"
const secondaryRedDark = "#83332E"

const secondaryBlue = "#5B89B4"
const secondaryBlueLight = "#E5EDEB"
const secondaryBlueDark = "#003B71"

const secondaryPink = "#A86D87"
const secondaryPinkLight = "#F4D3D6"
const secondaryPinkDark = "#6D2A5B"

const secondaryOrange = "#EAA55E"
const secondaryOrangeLight = "#F4E3BF"
const secondaryOrangeDark = "#9B5F17"

const secondaryYellow = "#F0DF00"
const secondaryYellowLight = "#F2ECC0"
const secondaryYellowDark = "#A09857"

const secondaryPurple = "#7473C0"
const secondaryPurpleLight = "#DEDFEB"
const secondaryPurpleDark = "#4D4084"

const secondaryGreen = "#B4BD00"
const secondaryGreenLight = "#E7ECC8"
const secondaryGreenDark = "#40683C"

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subhead1A: React.CSSProperties;
    subhead2A: React.CSSProperties;
    subhead3A: React.CSSProperties;
    subhead1B: React.CSSProperties;
    subhead2B: React.CSSProperties;
    subhead3B: React.CSSProperties;
    label1: React.CSSProperties;
    label2: React.CSSProperties;
    label3: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    underlined: React.CSSProperties;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends Partial<TypographyVariants> { }

  interface Theme {
    name: string;
  }

  interface ThemeOptions {
    name?: string;
  }

  interface Palette {
    chartColors: string[];
    neutral: Palette['primary'];
    lightColorsArr: string[];
    darkColorsArr: string[];
  }
  interface PaletteOptions {
    chartColors?: string[];
    neutral?: PaletteOptions['primary'];
    lightColorsArr?: string[];
    darkColorsArr?: string[];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subhead1A: true;
    subhead2A: true;
    subhead3A: true;
    subhead1B: true;
    subhead2B: true;
    subhead3B: true;
    label1: true;
    label2: true;
    label3: true;
    bodyLarge: true;
    underlined: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    blank: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    listItem: true;
  }
}

const standaloneLinkStyles = {
  color: mountainMeadow,
  textDecoration: 'none',
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  '& .MuiSvgIcon-root': {
    fontSize: "1.2em",
  },
}

const headerStyles = {
  headline: {
    fontFamily: 'LoraVariable',
    fontWeight: 600,
    marginBottom: '0.5em',
    marginTop: '0.5em',
  },
  subheadA: {
    fontFamily: 'LoraVariable',
    fontWeight: 400,
    marginBottom: '0.5em',
  },
  subheadB: {
    fontFamily: 'InterVariable',
    fontWeight: 500,
  },
  h5: {
    fontSize: '24px',
    lineHeight: 34 / 24,
    marginBottom: '0.5em',
  },
  h6: {
    fontSize: '20px',
    lineHeight: 26 / 20,
    marginBottom: '0.5em',
  },
}

// base palette for the theme
const paletteOptions = {
  primary: {
    main: mountainMeadow,
    light: lichen,
    dark: deepForest,
  },
  neutral: {
    main: gray,
    dark: darkGray,
    light: offWhite,
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
  },
  info: {
    main: secondaryBlue,
    light: secondaryBlueLight,
    dark: secondaryBlueDark,
  },
  warning: {
    main: secondaryOrange,
    light: secondaryOrangeLight,
    dark: secondaryOrangeDark,
  },
  error: {
    main: secondaryRed,
    light: secondaryRedLight,
    dark: secondaryRedDark,
  },
  success: {
    main: mountainMeadow,
    light: lichen,
    dark: deepForest,
  },
  text: {
    primary: darkGray,
    secondary: gray,
  },
  divider: "#C1C5C8",
  chartColors: [
    mountainMeadow, deepForest,
    secondaryPurple, secondaryPurpleDark,
    secondaryRed, secondaryRedDark,
    secondaryGreen, secondaryGreenDark,
    secondaryBlue, secondaryBlueDark,
    secondaryOrange, secondaryOrangeDark,
    secondaryPink, secondaryPinkDark,
    secondaryYellow, secondaryYellowDark,
  ],
  lightColorsArr: [
    lichen,
    secondaryPurpleLight,
    secondaryRedLight,
    secondaryBlueLight,
    secondaryOrangeLight,
    secondaryPinkLight,
    secondaryYellowLight,
    secondaryGreenLight,
  ],
  darkColorsArr: [
    deepForest,
    secondaryPurpleDark,
    secondaryRedDark,
    secondaryBlueDark,
    secondaryOrangeDark,
    secondaryPinkDark,
    secondaryGreenDark
  ]
}

// primary theme, using the base palette
// font sizes will be converted from px to rem by the responsiveFontSizes util (end of this file)
const primaryThemeBase = createTheme({
  name: 'primary',
  palette: paletteOptions,
  typography: {
    fontFamily: 'InterVariable',
    h1: {
      ...headerStyles.headline,
      fontSize: '48px',
      lineHeight: 54 / 48,
      marginBottom: '0.75em',
    },
    h2: {
      ...headerStyles.headline,
      fontSize: '40px',
      lineHeight: 48 / 40,
      marginBottom: '0.75em',
    },
    h3: {
      ...headerStyles.headline,
      fontSize: '32px',
      lineHeight: 40 / 32,
      marginBottom: '0.75em',
    },
    h4: {
      ...headerStyles.headline,
      fontSize: '28px',
      lineHeight: 36 / 28,
    },
    h5: {
      ...headerStyles.headline,
      ...headerStyles.h5,
    },
    h6: {
      ...headerStyles.headline,
      ...headerStyles.h6,
    },
    subhead1A: {
      ...headerStyles.subheadA,
      ...headerStyles.h5,
    },
    subhead2A: {
      ...headerStyles.subheadA,
      ...headerStyles.h6,
    },
    subhead3A: {
      ...headerStyles.subheadA,
      fontSize: '16px',
      lineHeight: 22 / 16,
    },
    subhead1B: {
      ...headerStyles.subheadB,
      ...headerStyles.h5,
    },
    subhead2B: {
      ...headerStyles.subheadB,
      ...headerStyles.h6,
    },
    subhead3B: {
      ...headerStyles.subheadB,
      fontSize: '16px',
      lineHeight: 22 / 16,
    },
    label1: {
      fontSize: '1.125rem',
      lineHeight: '1.44',
      margin: "0",
      textTransform: "uppercase",
    },
    label2: {
      fontSize: '0.75rem',
      lineHeight: '1.66',
      margin: "0",
      textTransform: "uppercase",
    },
    label3: {
      fontSize: '0.625rem',
      lineHeight: '1.6',
      margin: "0",
      textTransform: "uppercase",
    },
    bodyLarge: {
      fontSize: "1.25rem",
      lineHeight: "1.6",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.57",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "1.5",
    },
    subtitle1: {
      color: paletteOptions.neutral.main,
    },
    subtitle2: {
      color: paletteOptions.neutral.main,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subhead1A: 'h4',
          subhead2A: 'h5',
          subhead3A: 'h6',
          subhead1B: 'h4',
          subhead2B: 'h5',
          subhead3B: 'h6',
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 7px 0px rgb(0 0 0 / 10%)"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
        title: { // kinda matches subhead A3
          fontWeight: 600,
          fontSize: "1em",
          lineHeight: 22 / 16,
          fontFamily: 'LoraVariable',
          marginBottom: "0.25em",
        },
        subheader: {
          textTransform: "uppercase",
          fontSize: "0.65em",
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
          padding: 0,
        },
        input: {
          padding: 0,
        },
        option: {
          padding: 0,
        },
        listbox: {
          "& .MuiAutocomplete-option": {
            padding: "4px 8px",
          },
        },
      }
    },
    MuiAvatar: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: paletteOptions.primary.light,
            color: paletteOptions.primary.dark,
            fontWeight: 400,
            fontSize: "0.875rem",
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: paletteOptions.info.light,
            color: paletteOptions.info.dark,
            fontWeight: 400,
            fontSize: "0.875rem",
          }
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: paletteOptions.warning.light,
            color: paletteOptions.warning.dark,
            fontWeight: 400,
            fontSize: "0.875rem",
          }
        },
        {
          props: { color: 'calendar' },
          style: {
            backgroundColor: paletteOptions.primary.light,
            color: paletteOptions.primary.dark,
            fontSize: ".875rem",
            fontWeight: 500,
            height: 40,
            width: 40,
          }
        }
      ],
      styleOverrides: {
        root: {
          backgroundColor: lichen,
          color: deepForest,
        }
      }

    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            "& .MuiStepIcon-text": {
              fill: "white"
            }
          }
        }
      }
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: "0 16px 0 0",
        },
      },
    },
    MuiTimelineDot: {
      variants: [
        {
          props: { color: "success" },
          style: {
            backgroundColor: deepForest,
          }
        }
      ]
    },
    MuiTabs: {
      styleOverrides: {
        vertical: {
          '& .MuiTab-root.MuiButtonBase-root': {
            marginBottom: "1.5rem !important",
          }
        }
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "listItem" },
          style: {
            display: "flex",
            color: paletteOptions.text.secondary,
            width: "100%",
          }
        },
        {
          props: { variant: "listItem", color: "error" },
          style: {
            display: "flex",
            color: paletteOptions.error.main,
            width: "100%",
          }
        },
      ],

      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1.125rem",
          fontWeight: 600,
          lineHeight: 1.444,
          padding: "0.5em 1.33em",
          whiteSpace: "nowrap",
          minWidth: "auto",
          '& .MuiButton-startIcon': {
            display: "none",
          },
          boxShadow: "none",
        },
        // when you add this, make sure to go through and find all text buttons and only use them in the correct scenarios
        text: {
          color: paletteOptions.text.secondary,
          '&:hover': {
            backgroundColor: paletteOptions.neutral.light,
          },
        },
        sizeMedium: {
          fontSize: "1rem",
        },
        sizeSmall: {
          fontSize: "0.85rem",
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: alpha(paletteOptions.neutral.main, 0.4),
            borderColor: alpha(paletteOptions.neutral.main, 0.4),
            color: paletteOptions.neutral.light,
          },
          ":hover": {
            backgroundColor: alpha(paletteOptions.primary.main, 0.8),
            boxShadow: "none",
          }
        },
        outlined: {
          ":hover": {
            backgroundColor: alpha(paletteOptions.primary.light, .7)
          },
          '& svg': {
            fill: paletteOptions.text.primary,
          },
          '&.Mui-disabled': {
            backgroundColor: "transparent",
            borderColor: alpha(paletteOptions.neutral.main, 0.5),
            color: alpha(paletteOptions.neutral.main, 0.5),
            '& svg': {
              fill: alpha(paletteOptions.neutral.main, 0.5),
            },
          },
          // "Modifying" button
          '&.MuiButton-outlinedWarning': {
            borderColor: paletteOptions.text.secondary,
            color: paletteOptions.text.secondary,
            '&:hover': {
              backgroundColor: paletteOptions.neutral.light,
            },
            '& svg': {
              fill: paletteOptions.text.secondary,
            },
            '&.Mui-disabled': {
              backgroundColor: "transparent",
              borderColor: alpha(paletteOptions.neutral.main, 0.5),
              color: alpha(paletteOptions.neutral.main, 0.5),
              '& svg': {
                fill: alpha(paletteOptions.neutral.main, 0.5),
              },
            },
          },
          '&.MuiButton-outlinedError:hover': {
            backgroundColor: paletteOptions.error.light,
          }
        }
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          '& .MuiButton-contained': {
            '& svg': {
              fill: paletteOptions.neutral.light,
            },
            '&.Mui-disabled svg': {
              fill: alpha(paletteOptions.neutral.dark, 0.3),
            },
          },
          '& .MuiButton-outlined': {
            '& svg': {
              fill: paletteOptions.primary.main,
            },
            '&.Mui-disabled svg': {
              fill: alpha(paletteOptions.neutral.main, 0.5),
            },
          },
          '& .MuiButton-outlinedWarning': {
            '& svg': {
              fill: paletteOptions.text.secondary,
            },
            '&.Mui-disabled svg': {
              fill: alpha(paletteOptions.neutral.main, 0.5),
            },
          },
          '& .MuiButton-outlinedError': {
            '& svg': {
              fill: paletteOptions.error.main,
            },
            '&.Mui-disabled svg': {
              fill: alpha(paletteOptions.neutral.main, 0.5),
            },
            '&.makeStyles-popperExpandActive-50': {
              backgroundColor: alpha(paletteOptions.error.main, 0.2),
            }
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: paletteOptions.neutral.light
          }
        }
      }
    },
    MuiLink: {
      variants: [
        {
          props: { variant: 'subhead1B' },
          style: { ...standaloneLinkStyles }
        },
        {
          props: { variant: 'subhead2B' },
          style: { ...standaloneLinkStyles }
        },
        {
          props: { variant: 'subhead3B' },
          style: { ...standaloneLinkStyles }
        },
        {
          props: { variant: 'underlined' },
          style: {
            textDecoration: "underline",
            color: paletteOptions.neutral.dark
          }
        }
      ],
      styleOverrides: {
        root: {
          color: "inherit",
          textDecorationColor: "inherit",
          cursor: "pointer",
          margin: "0",
          '&:hover': {
            color: paletteOptions.primary.main,
          }
        },
      }
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: paletteOptions.primary.light,
            color: paletteOptions.primary.dark,
            '&:hover': {
              backgroundColor: paletteOptions.primary.light,
            },
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: secondaryPurpleLight,
            color: secondaryPurpleDark,
          }
        },
        {
          props: { color: 'success' }, // use for community event tags
          style: {
            backgroundColor: secondaryBlueLight,
            color: secondaryBlueDark,
          }
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: secondaryOrangeLight,
            color: secondaryOrangeDark,
          }
        },
        {
          props: { variant: 'filled', color: 'warning' },
          style: {
            backgroundColor: paletteOptions.warning.light,
            color: paletteOptions.warning.dark,
            '&:hover': {
              backgroundColor: paletteOptions.warning.light,
            },
          }
        },
        {
          props: { color: "info" }, // use info for filter chips
          style: {
            backgroundColor: paletteOptions.neutral.light,
            color: paletteOptions.neutral.main,
            marginLeft: "1em",
            marginBottom: "0.5em",
            marginTop: "0.5em",
          }
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: "1.125rem",
          }
        }
      ],
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontSize: "0.75rem",
          padding: "0.5rem 0.25rem",
          lineHeight: "1.66",
          fontWeight: 600,
          cursor: "inherit",
        },
        label: {
          whiteSpace: "nowrap",
          padding: "0.25em 0.75em 0.125em",
        },
        sizeSmall: {
          fontSize: "0.675rem",
          borderRadius: "12px",
          fontWeight: "normal",
        },
        deleteIcon: {
          props: { color: "info" }, // use info for filter chips
          backgroundColor: paletteOptions.neutral.light,
          color: paletteOptions.neutral.main,
          fontSize: "0.85rem",
          "&:hover": {
            color: paletteOptions.neutral.dark,
          }
        }
      }
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'success' },
          style: {
            color: paletteOptions.text.primary,
            backgroundColor: paletteOptions.success.light,
            '& svg': {
              fill: paletteOptions.text.primary,
            }
          }
        },
        {
          props: { severity: 'info' },
          style: {
            color: paletteOptions.text.primary,
            backgroundColor: paletteOptions.info.light,
            '& svg': {
              fill: paletteOptions.text.primary,
            }
          }
        },
        {
          props: { severity: 'info', variant: 'outlined' },
          style: {
            borderColor: paletteOptions.info.main,
            backgroundColor: paletteOptions.background.default,
            '& svg': {
              fill: paletteOptions.info.main,
            }
          }
        },
        {
          props: { severity: 'warning' },
          style: {
            color: paletteOptions.text.primary,
            backgroundColor: paletteOptions.warning.light,
            '& svg': {
              fill: paletteOptions.text.primary,
            }
          }
        },
        {
          props: { severity: 'warning', variant: 'outlined' },
          style: {
            borderColor: paletteOptions.warning.main,
            backgroundColor: paletteOptions.background.default,
            '& svg': {
              fill: paletteOptions.warning.main,
            }
          }
        },
        {
          props: { severity: 'error' },
          style: {
            color: paletteOptions.text.primary,
            backgroundColor: paletteOptions.error.light,
            '& svg': {
              fill: paletteOptions.text.primary,
            },
            '&.MuiAlert-filled': {
              // for toast errors, use MUI default where it's nice and annoying and stands out
              backgroundColor: red.A400,
              color: "white",
              '& svg': {
                fill: paletteOptions.error.light,
              },
            },
          }
        },
        {
          props: { variant: 'blank' },
          style: { backgroundColor: paletteOptions.background.default }
        }
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: paletteOptions.primary.dark,
          }
        }
      ]
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiTypography-h6': {
            fontSize: "1.5em",
          }
        }
      }
    }
  }
})

const mobileTheme = createTheme(primaryThemeBase, {
  typography: {
    h4: {
      [primaryThemeBase.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: 34 / 24,
      }
    },
    h6: {
      [primaryThemeBase.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 22 / 16,
      }
    },
    subtitle1: {
      [primaryThemeBase.breakpoints.down('sm')]: {
        fontSize: '.75rem'
      }
    },
    subhead2B: {
      [primaryThemeBase.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: 22 / 16,
      }
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          [primaryThemeBase.breakpoints.down('sm')]: {
            minHeight: "100px",
            padding: 0,
            marginBottom: "1em",
          },
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          [primaryThemeBase.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
          }
        }
      }
    }
  }
})

// the disableAlign option prevents this utility from magically adding 4px to fonts
const primaryTheme = responsiveFontSizes(mobileTheme, { disableAlign: true })

export default primaryTheme
