import { UserRole } from "@fieldday/fielddayportal-model";
import { MembershipWithUser, NonprofitMembership, OrgUnitMembership, UserInfo } from "./UserInfo";

export class UserHelper {
  private ui: UserInfo

  constructor(userInfo: UserInfo) {
    this.ui = userInfo
  }

  displayName(pronouns: boolean = true): string {
    const pronounString = pronouns && !!this.ui.pronouns ? `(${this.ui.pronouns})` : ""
    return `${this.ui.firstName || "--"} ${this.ui.lastName || ""} ${pronounString}`
  }

  abbrev(): string {
    return (
      (this.ui.firstName || "-")[0] +
      (this.ui.lastName || "-")[0]
    )
  }

  emailDomain(): string {
    return this.ui.email.split("@")?.[1]
  }

  isComplete(): boolean {
    return !!this.ui.firstName && !!this.ui.lastName && !!this.ui.region
  }

  coordinatingNonprofitMemberships(): NonprofitMembership[] {
    return this.ui.nonprofitMemberships.filter(npm => isCoordinatingRole(npm.role))
  }

  coordinatingOrgUnitMemberships(): OrgUnitMembership[] {
    return this.ui.orgUnitMemberships.filter(oum => isCoordinatingRole(oum.role))
  }

  ownerNonprofitMemberships(): NonprofitMembership[] {
    return this.ui.nonprofitMemberships.filter(npm => npm.role === UserRole.Owner)
  }

  ownerOrgUnitMemberships(): OrgUnitMembership[] {
    return this.ui.orgUnitMemberships.filter(oum => oum.role === UserRole.Owner)
  }
}

// takes a list of membership objects and translates into full users, filtering out any incomplete users
export function membershipsToCompleteUsers(memberships: MembershipWithUser[]): Required<UserInfo>[] {
  return memberships.map(m => {
    const uh = new UserHelper(m.user)
    m.user.displayName = uh.displayName()
    m.user.isComplete = uh.isComplete()
    return m.user as Required<UserInfo>
  }).filter(u => u.isComplete)
}

function isCoordinatingRole(role: string): boolean {
  return role === UserRole.Owner || role === UserRole.Coordinator
}
