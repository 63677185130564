import { FieldTripPlanState } from "@fieldday/fielddayportal-model"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ListIcon from '@mui/icons-material/List'
import NotesIcon from '@mui/icons-material/Notes'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Chip, CircularProgress, Tab, TabProps, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import React, { JSXElementConstructor, ReactElement, RefAttributes } from "react"
import { LinkProps } from "react-router-dom"


export type EventTypesForTabs =
  "list" | "plans" | "archived" | "calendar" | "upcoming" | "past" | "draft" | "2022" | "2023" | "independent" | "campaigns"
type EventSectionsForTabs = "overview" | "volunteers" | "photos" | "messages" | "otherDates" | "management" | "donate"
export type ConfigTypesForTabs = "profile" | "features" | "notifications" | "membership" | "locations" | "socials" | "donations" | "internalConfig"
export type FormTypesForTabs = "edit" | "preview"
export type PeopleTypesForTabs = "members" | "invitations" | "guests"
export type NpoNavTabs = "dashboard" | "events" | "activities" | "profile" | "reporting" | "memberships" | "settings"
export type OrgUnitNavTabs = "dashboard" | "teamHub" | "fieldTrips" | "reporting" | "memberships" | "settings"
export type DonationTabs = "owner" | "member"
export type CampaignFormTabs = 'volunteering' | 'giving'

export type AllTabs = EventTypesForTabs | ConfigTypesForTabs | FormTypesForTabs | PeopleTypesForTabs |
  DonationTabs | NpoNavTabs | OrgUnitNavTabs | CampaignFormTabs


export type TabContentProps = {
  tabType:  AllTabs | FieldTripPlanState,
  tabValue: AllTabs | FieldTripPlanState,
  children: React.ReactNode,
  loading?: boolean,
  useMounting?: boolean,
}

export const TabContent = ({ tabType, tabValue, children, loading, useMounting }: TabContentProps) => {
    return (
      <>
        {(!useMounting || tabValue === tabType) &&
          <div hidden={tabValue !== tabType} style={{ flexGrow: 1 }}>
            {(loading ?
              <Box margin={4}>
                <Typography fontStyle="italic">Loading ...</Typography>
                <CircularProgress />
              </Box>
              : children)}
          </div>
        }
      </>
    )
}
export const MemoTabContent = React.memo(TabContent)

export const CountChip = ({ countable }: { countable: { length: number } | null }) => {
  if (!countable || countable.length === 0) return null
  return <Chip label={countable.length} color="primary" size="small" style={{ marginLeft: "0.5em" }} />
}

interface StyledTabProps extends TabProps {
  value: AllTabs | EventSectionsForTabs,
  hideIcon?: boolean,
  hideMarginBottom?: boolean,
  setMarginBottom?: string,
  small?: boolean
}

interface StyledSubTabProps extends TabProps {
  value: AllTabs | FieldTripPlanState | boolean | null
  component?: <S = unknown>(props: LinkProps<S> & RefAttributes<HTMLAnchorElement>) => ReactElement<any, string | JSXElementConstructor<any>> | null
  to?: string
}

const convertEventTypeToIcon = (et: AllTabs | EventSectionsForTabs,
  hideIcon?: boolean, small?: boolean) => {
  switch (et) {
    case "list": return hideIcon ? undefined : <ListIcon fontSize={small ? "small" : "medium"}/>
    case "plans": return <NotesIcon />
    // case "archived": return <InventoryIcon />
    case "calendar": return <CalendarMonthIcon fontSize={small ? "small" : "medium"} />
    case "edit" : return <EditOutlinedIcon />
    case "preview" : return <VisibilityOutlinedIcon />

    default: return undefined
  }
}

export const MainTab = styled(({ hideMarginBottom, setMarginBottom, small, ...props }: StyledTabProps) =>
  <Tab
    icon={convertEventTypeToIcon(props.value, props.hideIcon, small)}
    iconPosition="end"
    style={{ marginBottom: hideMarginBottom ? '0em' : setMarginBottom ? setMarginBottom : '.5em' }}
    {...props}
  />)(
    ({ theme }) => ({
      textTransform: "none",
      padding: 0,
      minWidth: 0,
      minHeight: 0,
      fontWeight: 400,
      fontSize: "1rem",
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(4),
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&.Mui-selected': {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        '& svg': {
          color: theme.palette.primary.dark,
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '.875rem',
      }
    }),
  )

export const SubTab = styled((props: StyledSubTabProps) =>
  <Tab {...props} />
)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 400,
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    border: "none",
    alignSelf: "start",
    marginLeft: "0.5rem",
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      '& svg': {
        color: theme.palette.primary.dark,
      },
    },
  }),
)

export const useTabStyles = makeStyles((theme) => ({
  mainHorizontalTabs: {
    marginBottom: "1.5em",
    minHeight: 0,
    overflow: 'visible',
    '& .MuiTabs-scroller': { overflow: 'visible !important' },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    '&.noMargin': {
      marginBottom: '0em',
    },
    '&.topMargin': {
      marginTop: '.25rem',
    },
  },
  eventSectionHorizontalTabs: {
    overflow: 'visible',
    padding: theme.spacing(3.5, 0, 0),
    '& .MuiTabs-scroller': { overflow: 'auto !important' },
    "& .MuiTabs-indicator": {
      height: '3px',
      backgroundColor: theme.palette.primary.dark,
    },
    '&.formStyle': {
      padding: theme.spacing(1, 0, 2, 0)
    },
  },
  subTabs: {
    minHeight: 0,
    "& .MuiTabs-indicator": {
      left: 0,
      width: "3px",
      backgroundColor: theme.palette.primary.dark,
    },
    "& button": {
      textAlign: "start",
      marginLeft: "1rem"
    },
    '& svg': {
      display: "none",
    },
    '& a': {
      marginLeft: "1rem",
    },
    "&.sideNav": {
      marginLeft: "1rem",
      marginTop: '-.4rem',
    },
    "&.orgPicker": {
      "& .MuiTabs-indicator": {
        display: "none",
      },
      padding: "1rem 2.5rem 0rem 0rem",
      '& svg': {
        display: "inline-block",
        marginRight: theme.spacing(1),
      },
    },
  },
  tabContent: {
    flexGrow: 1,
  },
  sideNavTabs: {
    paddingTop: "2px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    '& a': {
      textTransform: "none",
      color: theme.palette.text.secondary,
      alignItems: "flex-start",
      whiteSpace: "nowrap",
      padding: 0,
      minHeight: "20px",
      '&:not(:last-child)': {
        marginBottom: theme.spacing(5),
      },
      '&:hover': {
        color: "inherit",
        '& svg': {
          color: "inherit",
        },
      },
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          color: theme.palette.primary.main,
        },
        '&.orgPickerButton': {
          color: theme.palette.neutral.main
        }
      },
      '& svg': {
        color: theme.palette.neutral.main,
        marginRight: theme.spacing(1.5),
      },
    },
  },
  sideNavOrgTab: { // only needed when the Tab component is a Button
    textTransform: "none",
    padding: "0px",
    minHeight: "auto",
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent"
    },
  },
  orgPickerButton: {
    padding: "0 0 0 8px",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))
