import { bearerTokenKey } from "../hooks/useAuth"

export const ENDPOINT = "fielddayapi"
export const AUTH_ENDPOINT = "fielddayapi-auth"
export const MAYBE_AUTH_ENDPOINT = "fielddayapi-maybe-auth"

// Build the root URL from the origin.
// Note the / at the end to match the cognito callback urls.
export const DETECTED_ROOT_URL = `${window.location.origin}/`

export const AWS_AUTH_CONFIG = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: import.meta.env.REACT_APP_COGNITO_AUTH_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    userPoolId: import.meta.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    /*
     * cookieStorage is useful for cross-domain auth, but we don't need it (for now)
     *
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: import.meta.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "lax", // "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false
    },
    */

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: import.meta.env.REACT_APP_COGNITO_AUTH_ENDPOINT,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: DETECTED_ROOT_URL,
      redirectSignOut: DETECTED_ROOT_URL,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }

  },
  API: {
    endpoints: [
      {
        name: AUTH_ENDPOINT,
        endpoint: import.meta.env.REACT_APP_FIELD_DAY_API_ENDPOINT,
        custom_header: async () => {
          const token = localStorage.getItem(bearerTokenKey)
          return { Authorization: `Bearer ${token}` }
        }
      },
      {
        name: ENDPOINT,
        endpoint: import.meta.env.REACT_APP_FIELD_DAY_API_ENDPOINT,
        custom_header: () => { return { Authorization: "none" } }
      }
    ]
  }
};
