import { Auth } from '@aws-amplify/auth'
import { ClientMetaData } from '@aws-amplify/auth/lib-esm/types'
import { Alert, Box, Button, Card, CardMedia, Container, Grid, Link, Typography } from "@mui/material"
import dayjs from "dayjs"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useErrorMessages } from "../../hooks/errorMessagesContext"
import { useLoading } from "../../hooks/useLoading"
import useFormStyles from "../../styles/formStyles"

export function SSODynatrace({ displayFooter }:{ displayFooter?: boolean }) {
  return (<SSOContainer companyName='Dynatrace' provider="SSO-Dynatrace" displayFooter={displayFooter} />)
}

export function SSODevTest() {
  return (<SSOContainer companyName='Dev Test Company' provider="SSOTestJason" />)
}

export function SSOContainer({ companyName, provider, displayFooter = true }: { companyName: string, provider: string, displayFooter?: boolean }) {
  const { loadStart } = useLoading()
  const { infoMessage } = useErrorMessages()
  const formStyles = useFormStyles()
  const clientMetadata: ClientMetaData = {}

  return (
    <Container maxWidth="xs" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Helmet title="Welcome! Please sign in." />
      <Card sx={{ width: "400px", display: "flex", alignItems: "center", flexDirection: "column", padding: "24px" }}>
        {infoMessage && <Alert sx={{ mb: "2em", alignItems: "center" }} severity="info">{<ReactMarkdown>{infoMessage}</ReactMarkdown>}</Alert>}
        <CardMedia component="img" height="70px" image="/images/FieldDay_BlackLogo.webp" alt="Field Day Logo" title="Field Day"
          style={{ margin: "12px 0 0", width: "auto" }} />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography my={3}>
              Welcome! Use the button below to sign in using your <strong>{companyName}</strong> credentials.
            </Typography>
            <Button
              style={{ marginBottom: "2em" }}
              onClick={() => {
                loadStart(true)
                Auth.federatedSignIn({
                  customProvider: provider,
                  customState: JSON.stringify(clientMetadata)
                })
              }}
              fullWidth
              variant="contained"
              className={formStyles.googleSignInButton}
            >
              Sign in with SSO
            </Button>
          </Grid>
        </Grid>
        <Box mt={1} textAlign='center'>
          <Typography variant='caption' >
            By signing in, you agree to our <Link href='https://www.heyfieldday.com/terms/'>Terms of Service</Link> and&nbsp;
            <Link href='https://www.heyfieldday.com/privacy/'>Privacy Policy</Link>.
          </Typography>
        </Box>
      </Card>
      {displayFooter &&
      <Box textAlign="center" padding="24px">
        <Typography variant="body2">
          <Link href="https://www.heyfieldday.com/companies">Learn about Field Day for companies</Link>
        </Typography>
        <Typography variant="body2">
          &copy; {dayjs().year()} <Link href="https://www.heyfieldday.com/">Field Day</Link>
        </Typography>
      </Box>}
    </Container>
  )
}
