import { MetroRegion, getMetroRegion } from "@fieldday/fielddayportal-model";
import { ReactNode, createContext, useContext, useState } from "react";
import { useAuth } from "./useAuth";

export const regionKey = "metroRegion"

type ContextProps = {
  children: ReactNode
}

interface DataForContext {
  region: MetroRegion | null,
  setRegion: (region: MetroRegion | null) => void,
}

const RegionContext = createContext<DataForContext | undefined>(undefined)

function setRegionInLocalStorage(region: MetroRegion | null) {
  if(region) {
    localStorage.setItem(regionKey, region)
  } else {
    localStorage.removeItem(regionKey)
  }
}

function getLocalRegion() {
  const regionFromLocal = localStorage.getItem(regionKey)
  return getMetroRegion(regionFromLocal)
}

export function RegionProvider({ children }: ContextProps) {
  const { user } = useAuth()
  const userRegion = !user?.region || user?.region === MetroRegion.NotListed ? MetroRegion.Portland : user?.region as MetroRegion
  const [region, setSelectedRegion] = useState<MetroRegion | null>(getLocalRegion() ?? userRegion)

  function setRegion(region: MetroRegion | null) {
    setSelectedRegion(region)
    setRegionInLocalStorage(region)
  }

  const context: DataForContext = {
    region,
    setRegion,
  }

  return (
    <RegionContext.Provider value={context} >
      {children}
    </RegionContext.Provider>
  )
}

export const useRegionContext = () => {
  const context = useContext(RegionContext)
  if(!context) throw new Error("useRegionContext must be used within a RegionProvider")
  return context
}
