import { Chip, SvgIconTypeMap, Theme, Tooltip } from "@mui/material"
import { amber } from "@mui/material/colors"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import WarningIcon from '@mui/icons-material/Warning';

export const tabLabel = (TabIcon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>, labelText: string, notice?: string, tooltip?: string) => {
  return (
    <div style={{
      overflow: 'hidden',
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'start',
    }}>
      <TabIcon style={{ verticalAlign: 'middle', marginRight: "12px" }} fontSize="small" />
      {tooltip ? <Tooltip title={tooltip}><span style={{ verticalAlign: 'middle' }}>{labelText}</span></Tooltip>
        : <span style={{ verticalAlign: 'middle' }}>{labelText}</span>
      }
      { notice &&
        <Tooltip title={notice}>
          <WarningIcon style={{ verticalAlign: 'middle', fill: amber.A400, marginLeft: "8px" }} fontSize="small"/>
        </Tooltip>
      }
    </div>
  )
}


export const chipLabel = (chipCount: number, labelText: string, theme: Theme) => {
  return (
    <div style={{
      overflow: 'hidden',
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'start',
    }}>
      <Chip label={chipCount > 99 ? "99+" : chipCount} size="small"
        style={{
          verticalAlign: 'middle',
          padding: '0em', marginRight: '1em',
          backgroundColor: theme.palette.primary.main,
          color: '#ffffff',
          fontWeight: 700
          }}   />
      <span style={{ verticalAlign: 'middle' }}>{labelText}</span>
    </div>
  )
}
