import {
  DonationDisbursementNonProfitOrgBatchResponseModelI,
  OrgStatus,
} from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import InternalConfigForm from "../../../components/NonProfits/InternalConfigForm"
import { OrgStatusPicker } from "../../../components/Wizard/Pickers"
import { useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import { endOfLastMonth, isoDate } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents } from "../../../util/stringUtil"
import { useAPI } from "../../../util/useAPI"
import NonProfitDisbursementDetails from "./NonProfitDisbursementDetails"

export default function NonProfitDisbursementConfigPreview({ refreshCounter, triggerRefresh }: {
  refreshCounter: number,
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()
  const [
    nonProfitOrgDisbursementBatches, setNonProfitOrgDisbursementBatches
  ] = useReadonlyState<DonationDisbursementNonProfitOrgBatchResponseModelI[] | null>(null)
  const [nonProfitOrgUnits, setNonProfitOrgUnits] = useReadonlyState<NPO[] | null>(null)

  const [hasDisbursementDetails, setHasDisbursementDetails] = useReadonlyState(false)

  const defaultEndDate = isoDate(endOfLastMonth)
  const [endDate, setEndDate] = useReadonlyState(defaultEndDate)
  const [npoStatuses, setNpoStatuses] = useState<OrgStatus[]>([OrgStatus.Active, OrgStatus.Placeholder, OrgStatus.Paused])

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    setNonProfitOrgDisbursementBatches(null)
    setNonProfitOrgUnits(null)
    loadStart(true)
    if (hasDisbursementDetails) {
      FieldDayAPI.listNposForConfig({ status: npoStatuses, withDisbursementDetails: true }, abortController).then((res) => {
        setNonProfitOrgUnits(res.data.nonProfitOrgs)
      })
      .catch(() => {
        setNonProfitOrgDisbursementBatches([])
        setNonProfitOrgUnits([])
      })
      .finally(() => {loadEnd()})
    } else {
      FieldDayAPI.previewDonationDisbursementBatchMissingDetails(endDate, abortController).then((res) => {
        setNonProfitOrgDisbursementBatches(res.data.nonProfitOrgDisbursementBatches)
        setNonProfitOrgUnits(res.data.nonProfitOrgs)
      })
      .catch(() => {
        setNonProfitOrgDisbursementBatches([])
        setNonProfitOrgUnits([])
      })
      .finally(() => {loadEnd()})
    }

    return () => {
      abortController.abort()
    }
  }, [endDate, refreshCounter, hasDisbursementDetails, JSON.stringify(npoStatuses)])

  return (<>
    <FormControl fullWidth sx={{ marginY: 4 }}>
      <Stack direction={"row"} justifyContent="space-between" width={"100%"}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={"End date"}
            value={endDate}
            disabled={hasDisbursementDetails}
            onChange={(newDate: string | null | Dayjs) => {
              if (newDate) {
                const dateISO = isoDate(newDate)
                setEndDate(dateISO)
              } else {
                setEndDate(defaultEndDate)
              }
            }}
            renderInput={params => <TextField {...params} helperText={"Donations and matches after this date will not be included in the batch"} />}
          />
        </LocalizationProvider>
        <Box sx={{ display: "inline-flex", pt: 1 }}>
          <Typography sx={{ pt: 1 }}>Need disbursement details</Typography>
          <Switch
            checked={hasDisbursementDetails}
            onChange={(event) => {
              setHasDisbursementDetails(event.target.checked)
            }}
          />
          <Typography sx={{ pt: 1 }}>Have disbursement details</Typography>
        </Box>
      </Stack>
    </FormControl>

    {hasDisbursementDetails && <>
      <Box sx={{ mb: 5 }}>
        <OrgStatusPicker statuses={npoStatuses} onFilterChange={(statuses) => setNpoStatuses(statuses)} />
      </Box>
      {(nonProfitOrgDisbursementBatches && nonProfitOrgDisbursementBatches.length === 0)
        ? <EmptyPageCallToAction
          header="There are no nonprofits with these statuses that have disbursement details"
          description="If you expect to see nonprofits listed here, you may need to adjust the chosen statuses."
          iconSrc={CTAIconSrc.EventsCalendar}
        />
        : <Grid container spacing={2} mb={4} pl={2} pr={23}>
          <Grid item xs={5}>
            <Typography fontWeight={"bold"}>Nonprofit name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"}>Status</Typography>
          </Grid>
        </Grid>
      }
      {nonProfitOrgUnits?.map(npo => {
        return (
          <NonProfitEntry
            key={npo.id}
            nonProfitOrg={npo}
            triggerRefresh={triggerRefresh}
          />
        )
      })}
    </>}

    {!hasDisbursementDetails && <>
      {(nonProfitOrgDisbursementBatches && nonProfitOrgDisbursementBatches.length === 0)
        ? <EmptyPageCallToAction
          header="No nonprofits missing donation details up to this date"
          description="If you expect to see nonprofits listed here, you may need to adjust the disbursement end date."
          iconSrc={CTAIconSrc.EventsCalendar}
        />
        : <Grid container spacing={2} mb={4} pl={2} pr={26}>
          <Grid item xs={5}>
            <Typography fontWeight={"bold"}>Nonprofit name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"}>Status</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"}>Disbursement amount</Typography>
          </Grid>
        </Grid>
      }

      {nonProfitOrgDisbursementBatches?.map(npoBatch => {
        return (
          <NonProfitDisbursementBatch
            key={npoBatch.nonProfitOrgId}
            batch={npoBatch}
            nonProfitOrg={nonProfitOrgUnits?.find(n => n.id === npoBatch.nonProfitOrgId)}
            triggerRefresh={triggerRefresh}
          />
        )
      })}
    </>}
  </>)
}

function NonProfitDisbursementBatch({ batch, nonProfitOrg, triggerRefresh }: {
  key: string,
  batch: DonationDisbursementNonProfitOrgBatchResponseModelI,
  nonProfitOrg?: NPO,
  triggerRefresh: () => void,
}) {
  const [expanded, setExpanded] = useReadonlyState(false)
  const [dialogOpen, setDialogOpen] = useReadonlyState(false)
  const closeModal = () => {setDialogOpen(false)}

  return (<>
    {nonProfitOrg && <>
      <Dialog open={dialogOpen} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogTitle>
          Set confirmed date
          <IconButton
            aria-label="closeDisbursementDetailsDateModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal()} }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container>
            <InternalConfigForm npo={nonProfitOrg} setAllowPrompt={() => {}} afterSubmit={() => {
              triggerRefresh()
              closeModal()
            }} />
          </Container>
        </DialogContent>
      </Dialog>

      <Accordion expanded={expanded}>
        <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography pt={1}>
                {nonProfitOrg?.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Chip
                sx={{ mt: 1 }}
                label={nonProfitOrg.status}
                color={nonProfitOrg.status === OrgStatus.Active ? "primary" : "warning"}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography pt={1}>
                {dollarCents(batch.disbursementAmountCents)}
              </Typography>
            </Grid>
          </Grid>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setDialogOpen(true)
            }}
          >Update config</Button>
        </AccordionSummary>
        <AccordionDetails>
          <NonProfitDisbursementDetails
            matchableDonations={batch.matchableDonations ?? []}
            platformDonations={batch.platformDonations ?? []}
          />
        </AccordionDetails>
      </Accordion>
    </>}
  </>)
}

function NonProfitEntry({ nonProfitOrg, triggerRefresh }: {
  key: string,
  nonProfitOrg: NPO,
  triggerRefresh: () => void
}) {
  const [dialogOpen, setDialogOpen] = useReadonlyState(false)
  const closeModal = () => {setDialogOpen(false)}

  return (<>
    {nonProfitOrg && <Dialog open={dialogOpen} fullWidth maxWidth="xs" onClose={closeModal}>
      <DialogTitle>
        Set confirmed date
        <IconButton
          aria-label="closeDisbursementDetailsDateModal"
          sx={{ position: 'absolute', right: 16, top: 16 }}
          onClick={() => { closeModal()} }
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container>
          <InternalConfigForm npo={nonProfitOrg} setAllowPrompt={() => {}} afterSubmit={() => {
            triggerRefresh()
            closeModal()
          }} />
        </Container>
      </DialogContent>
    </Dialog>}

    <Accordion expanded={false}>
      <AccordionSummary>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography pt={1}>
              {nonProfitOrg?.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Chip
              sx={{ mt: 1 }}
              label={nonProfitOrg.status}
              color={nonProfitOrg.status === OrgStatus.Active ? "primary" : "warning"}
              size="small"
            />
          </Grid>
        </Grid>
        <Button
          sx={{ mr: 2 }}
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setDialogOpen(true)
          }}
        >Update config</Button>
      </AccordionSummary>
    </Accordion>
  </>)
}
