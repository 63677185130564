import { Typography } from "@mui/material"
import _ from "lodash"
import React, { useEffect } from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { useErrorMessages } from "../../hooks/errorMessagesContext"
import { useAuth } from "../../hooks/useAuth"
import { AlertForQueue } from "../../hooks/useLoading"
import { Queue } from "../../util/queue"
import LoadingBar from "./LoadingBar"

export interface PrivateRouteProps extends RouteProps {
  completeProfileRequired?: boolean
  customNoUserMessage?: string
  wizardRequired?: boolean
  membershipRequired?: boolean // must be a member of a Team or a nonprofit
  children: React.ReactNode
}

// if user is not logged in, redirect to /
// (and remember the previous route so we can redirect)
export default function PrivateRoute({ wizardRequired, membershipRequired, completeProfileRequired, customNoUserMessage, children, ...rest }:
    PrivateRouteProps) {
  const { user, isWizard } = useAuth()
  const { setInfoMessage } = useErrorMessages()

  useEffect(() => {
    if (!user && customNoUserMessage) setInfoMessage(customNoUserMessage)
  }, [])

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }

        if (completeProfileRequired && !user.isComplete) {
          return (<Redirect
            to={{
              pathname: "/profile/edit",
              state: {
                from: location,
                completeProfileRequired: true,
              }
            }}
          />)
        }

        if (membershipRequired && _.isEmpty(user?.orgUnitMemberships) && _.isEmpty(user?.nonprofitMemberships)) {
          return (<Redirect to={{
            pathname: "/teams/create"
          }} />)
        }

        if (wizardRequired && !isWizard()) {
          return (<Redirect to={{ pathname: "/404" }} />)
        }

        return (
          <React.Suspense fallback={<LoadingFallback />}>
            {children}
          </React.Suspense>
        )
      }}
    />
  );
}

function LoadingFallback() {
  return <>
    <LoadingBar loadingState={{ blocking: false, loading: true, alertQueue: new Queue<AlertForQueue> }} />
    <Typography variant="subhead2B" padding={4}><em>Loading ...</em></Typography>
  </>
}
