import { DonationInvoiceBatchModelResponse } from "@fieldday/fielddayportal-model"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import { useLoading } from "../../../hooks/useLoading"
import { NPO } from "../../../models/Npo"
import { OrgUnit } from "../../../models/OrgUnit"
import { endOfLastMonth, isoDate } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents } from "../../../util/stringUtil"
import { useAPI } from "../../../util/useAPI"
import { DonationsSummary } from "./DonationInvoicing"

export default function DonationInvoicesPreviews({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [donationInvoiceBatches, setDonationInvoiceBatches] = useState<DonationInvoiceBatchModelResponse[] | null>(null)
  const [batchOrgUnits, setBatchOrgUnits] = useState<OrgUnit[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useState<NPO[] | null>(null)

  const defaultEndDate = isoDate(endOfLastMonth)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.previewDonationInvoiceBatch(endDate, abortController).then((res) => {
      setDonationInvoiceBatches(res.data.donationInvoiceBatches)
      setBatchOrgUnits(res.data.orgUnits)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    })
    .catch(() => {
      setDonationInvoiceBatches([])
      setBatchOrgUnits([])
      setBatchNonProfitOrgUnits([])
    })
    .finally(() => {loadEnd()})

    return () => {
      abortController.abort()
    }
  }, [endDate, refreshCounter])

  return (<>
    <FormControl sx={{ marginY: 4 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={"Batch end date"}
          value={endDate}
          onChange={(newDate: string | null | Dayjs) => {
            if (newDate) {
              const dateISO = isoDate(newDate)
              setEndDate(dateISO)
            } else {
              setEndDate(defaultEndDate)
            }
          }}
          renderInput={params => <TextField {...params} helperText={"Donations approved after this date will not be included in the batch"} />}
        />
      </LocalizationProvider>
    </FormControl>
    {(donationInvoiceBatches && donationInvoiceBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No donations need batching"
        description="If you expect to see batches here, you may need to adjust the batch end date"
        iconSrc={CTAIconSrc.EventsCalendar}
      />
      : <Grid container spacing={2} mb={4} pl={2} pr={19}>
        <Grid item xs={5}>
          <Typography fontWeight={"bold"}>Team name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Match amount</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Fees amount</Typography>
        </Grid>
      </Grid>
    }

    {donationInvoiceBatches?.map(batch => {
      const orgUnit = batchOrgUnits?.find(ou => ou.id === batch.orgUnitId)
      if (!orgUnit) return <></>
      return (
        <DonationInvoicePreviewSummary
          key={batch.orgUnitId}
          batch={batch}
          orgUnit={orgUnit}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
    })}
  </>)
}

function DonationInvoicePreviewSummary({ batch, orgUnit, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationInvoiceBatchModelResponse,
  orgUnit: OrgUnit,
  nonProfitOrgs: NPO[],
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()

  const [expanded, setExpanded] = useState(false)
  const { loadStart } = useLoading()

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography marginTop={"0.5em"}>
              {orgUnit.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
          <Typography marginTop={"0.5em"}>
              {dollarCents(batch.invoiceMatchCents)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
          <Typography marginTop={"0.5em"}>
              {dollarCents(batch.invoiceFeesCents)}
            </Typography>
          </Grid>
        </Grid>
        <Button sx={{ mr: "1em" }} onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          loadStart(true)
          FieldDayAPI.createDonationInvoiceBatch(orgUnit.id, batch.endAt).then(() => {
            triggerRefresh()
          })
        }} variant="outlined">Create</Button>
      </AccordionSummary>
      <AccordionDetails>
        <DonationsSummary
          matchableDonations={batch.matchableDonations ?? []}
          nonProfitOrgs={nonProfitOrgs}
        />
      </AccordionDetails>
    </Accordion>
  )
}
