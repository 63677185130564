import {
  MatchableDonationModelI,
  MatchableDonationSchema,
} from "@fieldday/fielddayportal-model"
import { Box, Button, Container, FormControl, Grid, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useAuth } from "../../hooks/useAuth"
import { useDirty } from "../../hooks/useDirty"
import { AlertSeverity, useLoading } from "../../hooks/useLoading"
import { useScrollToError } from "../../hooks/useScrollToError"
import { NPO } from "../../models/Npo"
import useFormStyles from "../../styles/formStyles"
import ajv from "../../util/ajvConfig"
import { isoDate } from "../../util/dateUtil"
import { sortedJsonStringify } from "../../util/objectUtil"
import { useAPI } from "../../util/useAPI"
import { getNestedErrorMessages } from "../../util/validationErrors"
import { CurrencyTextFieldCents } from "../Forms/CurrencyTextField"
import ManagedFieldTripNpoFields, { emptyManagedFieldTripNpo } from "./ManagedFieldTripNpoFields"
import MatchableDonationReceiptUploadForm, { LocalReceiptFile, uploadDonationReceiptFiles } from "./MatchableDonationReceiptUploadForm"

interface props {
  orgUnitId: string,
  header: string,
  onClose: (skipConfirm?: boolean) => void,
  reportDirty: (dirty: boolean) => void
}

export default function MatchableDonationCreateForm(props: props) {
  const { orgUnitId, header, onClose, reportDirty } = props
  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()
  const { user } = useAuth()

  const { setAlert, loadStart, loadEnd } = useLoading()

  const [localFilesArray, setLocalFilesArray] = useState<LocalReceiptFile[]>([])

  const [errors, setErrors] = useState<Record<string, string | null>>({})
  const scrollToError = useScrollToError()

  const clearDonationErrors = (field: string) => {
    const key = `/${field}`
    if (errors[key]) {
      errors[key] = null
      setErrors(errors)
    }
  }

  const [npoForUpdate, setNpoForUpdate] = useState<NPO>(
    emptyManagedFieldTripNpo(user)
  )

  const [matchableDonationForUpdate, setMatchableDonationForUpdate] = useState<MatchableDonationModelI>(emptyMatchableDonation())

  const [npoDirty, setNpoDirty] = useState(false)
  const [matchableDonationDirty] = useDirty({
    objForUpdate: matchableDonationForUpdate,
    forDirtyCompare: (matchableDonationForUpdate) => { return forDirtyCompare(matchableDonationForUpdate, localFilesArray) },
  })
  const [allowPropmt, setAllowPrompt] = useState(true)
  const dirtyFields = matchableDonationDirty || npoDirty
  // This is kind of messy, but the parent component is responsible
  // for modal switching and the window.confirm call so it needs
  // to know if this component is dirty. So if dirtyFields changes,
  // report it up to the parent via the reportDirty callback.
  useEffect(() => {
    reportDirty(dirtyFields && allowPropmt)
  }, [allowPropmt, dirtyFields])

  const handleAmountChange = (newVal?: number) => {
    const val = !newVal || isNaN(newVal) ? 0 : newVal
    clearDonationErrors("amount")
    const updatedMatchableDonation = Object.assign({}, matchableDonationForUpdate)
    updateMatchableDonation(updatedMatchableDonation, "amount", val)
    setMatchableDonationForUpdate(updatedMatchableDonation)
  }

  const handleDateChange = (newDate: string | null | Dayjs) => {
    clearDonationErrors("dateISO")
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)

    if (newDate) {
      const dateISO = isoDate(newDate)
      updateMatchableDonation(updatedDonation, "dateISO", dateISO)
    } else {
      updateMatchableDonation(updatedDonation, "dateISO", "")
    }

    setMatchableDonationForUpdate(updatedDonation)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDonation = Object.assign({}, matchableDonationForUpdate)
    const field = event.target.name as keyof MatchableDonationModelI
    clearDonationErrors(field)
    updateMatchableDonation(updatedDonation, field, event.target.value)
    setMatchableDonationForUpdate(updatedDonation)
  }

  const validateMatchableDonation = (matchableDonation: MatchableDonationModelI, npo: NPO) => {
    const validateSchema = ajv.compile(MatchableDonationSchema)
    const request = Object.assign({}, matchableDonation, {
      nonProfitOrgId: npo.id === "" ? undefined : npo.id,
      nonProfitOrgName: npo.id ? undefined : npo.name,
      nonProfitOrgEin: npo.id ? undefined : npo.ein,
      nonProfitOrgCause: npo.id ? undefined : npo.primaryCause
    })
    validateSchema(request)
    const errors = validateSchema.errors ?? []

    if (!npo.ein) {
      errors.push({
        instancePath: "/nonProfitOrg",
        keyword: "required",
        params: { missingProperty: "ein" },
        schemaPath: "nonprofitorg.json/required",
      })
    }

    if ((matchableDonation.amount ?? 0) < 1) {
      errors.push({
        instancePath: "/amount",
        keyword: "required",
        params: { comparison: "<", limit: 1 },
        schemaPath: "#/properties/amount/required",
      })
    }

    if (localFilesArray.length === 0) {
      errors.push({
        instancePath: "",
        keyword: "required",
        params: { missingProperty: "receiptFiles" },
        schemaPath: "#/properties/receiptFiles/required",
      })
    }

    if (dayjs(matchableDonation.dateISO).isAfter(dayjs())) {
      errors.push({
        instancePath: "/dateISO",
        keyword: "maximum",
        params: { comparison: ">", limit: isoDate(dayjs()) },
        schemaPath: "#/properties/dateISO/maximum",
        message: "Must be in past.",
      })
    }

    if (errors.length > 0) {
      const errs = getNestedErrorMessages(errors)
      setErrors(errs)
      setAlert(AlertSeverity.ERROR, "Please check required fields.")
      scrollToError()
    } else {
      setErrors({})
      return request
    }
  }

  const submitDonation = () => {
    const validatedRequest = validateMatchableDonation(matchableDonationForUpdate, npoForUpdate)
    if (!validatedRequest) return

    loadStart(true)
    FieldDayAPI.createMatchableDonation(orgUnitId, validatedRequest)
    .then((submitResp) => {
      setAllowPrompt(false)
      reportDirty(false)
      const newMatchableDonation = submitResp?.data.donation
      if (newMatchableDonation?.id && localFilesArray.length > 0) {
        loadStart(true)
        uploadDonationReceiptFiles(newMatchableDonation, localFilesArray, FieldDayAPI)
          .then(() => {
            setAlert(AlertSeverity.SUCCESS, "Donation report submitted.")
          })
          .catch(() => {
            setAlert(AlertSeverity.ERROR, "Error uploading receipt file. Edit the donation and upload the receipt again.")
          })
          .finally(() => {
            loadEnd()
            onClose(true)
          })
      } else {
        loadEnd()
        onClose(true)
      }
    })
    .catch(err => {
      setAlert(AlertSeverity.ERROR, err.response?.data?.message || `${err}`)
    })
    .finally(loadEnd)
  }

  return (
    <>
      <Helmet title={header} />
      <Container maxWidth="md">
        <Box>
          <form className={formStyles.detailsForm}>
            <Grid sx={{ marginTop: "0.5em" }} container spacing={3}>
              <ManagedFieldTripNpoFields
                npoForUpdate={npoForUpdate}
                setNpoForUpdate={setNpoForUpdate}
                errors={errors}
                setErrors={setErrors}
                setDirty={setNpoDirty}
                isUpdatedFromInit={false}
                includeRegion={false}
              />
            </Grid>
            <Grid sx={{ marginTop: "0.5em" }} container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={formStyles.textInput}>
                  <CurrencyTextFieldCents
                    id="amount"
                    name="amount"
                    label="Donation amount"
                    value={matchableDonationForUpdate.amount}
                    onChange={handleAmountChange}
                    error={errors["/amount"]}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
              <FormControl className={formStyles.textInput}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Donation date"
                    value={matchableDonationForUpdate.dateISO}
                    onChange={handleDateChange}
                    renderInput={params => <TextField {...params} error={!!errors["/dateISO"]}
                      helperText={errors["/dateISO"] ?? " "}
                    />}
                    maxDate={dayjs()} // force it to be in the past
                  />
                </LocalizationProvider>
              </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    id="donationUrl"
                    name="donationUrl"
                    label="Donation website"
                    value={matchableDonationForUpdate.donationUrl}
                    onChange={handleChange}
                    error={!!errors["/donationUrl"]}
                    helperText={errors["/donationUrl"] ?? " "}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={formStyles.textInput}>
                  <TextField
                    id="contextMessage"
                    name="contextMessage"
                    label="Donation description"
                    value={matchableDonationForUpdate.contextMessage}
                    onChange={handleChange}
                    multiline
                    minRows={3}
                    maxRows={6}
                    error={!!errors["/contextMessage"]}
                    helperText={errors["/contextMessage"] ?? " "}
                  />
                </FormControl>
              </Grid>

              <MatchableDonationReceiptUploadForm
                buttonText="Choose receipt"
                setLocalFilesArray={setLocalFilesArray}
                error={!!errors["/receiptFiles"]}
                clearErrors={() => clearDonationErrors("receiptFiles")}
              />

              <Grid item xs={12}>
                <Box textAlign="start" mt={2}>
                  <Button color="primary" variant="contained" onClick={() => {
                    submitDonation()
                  }}>
                    Submit donation
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  )
}


function emptyMatchableDonation(): MatchableDonationModelI {
  return {
    amount: 0,
    donationUrl: "",
    contextMessage: "",
    dateISO: "",
  }
}

export function updateMatchableDonation<MatchableDonationKey extends keyof MatchableDonationModelI>(
  matchableDonation: MatchableDonationModelI,
  property: MatchableDonationKey,
  value: MatchableDonationModelI[MatchableDonationKey]
): void {
  matchableDonation[property] = value
}

function forDirtyCompare(donation: MatchableDonationModelI, localFiles: LocalReceiptFile[]): string {
  const dirtyFields = _.pick(donation, [
    "nonProfitOrgId",
    "amount",
    "dateISO",
    "status",
    "receiptUrl",
    "donationUrl",
    "contextMessage",
  ])

  return sortedJsonStringify({
    ...dirtyFields,
    localFiles: localFiles.map(f => f.localUrl)
  })
}
