import { Box, useMediaQuery } from '@mui/material'
import * as React from 'react'
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import NavColumn from '../components/Global/AppBar/NavColumn'
import PrimaryAppBar from '../components/Global/AppBar/PrimaryAppBar'
import Footer from '../components/Global/Footer'
import theme from '../styles/theme'

type ContextProps = {
  children: React.ReactNode,
  hideMargins?: boolean,
}

type HeaderSetters = {
  setPrimaryHeader: Dispatch<SetStateAction<JSX.Element | string | undefined>>
  setSubheader: Dispatch<SetStateAction<JSX.Element | string | undefined>>
  setWithDivider: Dispatch<SetStateAction<boolean>>
}

export type HeaderDetails = {
  primaryHeader?: JSX.Element | string
  subheader?: JSX.Element | string
  withDivider?: boolean
  breadCrumbLink?: JSX.Element
  breadCrumbLinks?: JSX.Element[]
}

const LayoutContext = createContext<HeaderSetters | undefined>(undefined)

export function LayoutProvider({ children, hideMargins }:ContextProps) {
  const [primaryHeader, setPrimaryHeader] = useState<JSX.Element | string | undefined>(undefined)
  const [subheader, setSubheader] = useState<JSX.Element | string | undefined>(undefined)
  const [withDivider, setWithDivider] = useState<boolean>(false)
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const [headerSetters, _setHeaderSetters] = useState<HeaderSetters>({ setPrimaryHeader, setSubheader, setWithDivider })
  const headerDetails = { primaryHeader, subheader, withDivider }
  const margins = mobileView ? "5%" : "50px"

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <NavColumn />
      <Box sx={{ width: "100%" }}>
        <PrimaryAppBar headerDetails={headerDetails} />
        <LayoutContext.Provider value={headerSetters}>
          <Box component="main" sx={{ flexGrow: 1, mx: hideMargins ? "0px" : margins }}>
            {children}
          </Box>
          <Footer />
        </LayoutContext.Provider>
      </Box>
    </Box>
  )
}

export const useLayout = () => {
  const context = useContext(LayoutContext)
  if (!context) throw new Error('useHeader must be used within a HeaderProvider');
  return context
}
