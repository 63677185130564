import { East, OpenInNew, West } from '@mui/icons-material'
import { Link, useTheme } from '@mui/material'
import { Link as RouterLink } from "react-router-dom"

export default function SubheadLink({
  to, children, size = "large", direction = "none", inline = false, underline = false, external = false, marginTop, marginBottom, customColor }:
  {
    to: string,
    children: React.ReactNode,
    size?: "large" | "medium" | "small",
    direction?: "forward" | "back" | "external" | "none",
    inline?: boolean,
    underline?: boolean,
    external?: boolean,
    marginTop?: string,
    marginBottom?: string,
    customColor?: string
  } ) {
  const variantMap = {
    large: "subhead1B",
    medium: "subhead2B",
    small: "subhead3B",
  }
  const variant = variantMap[size] as "subhead1B" | "subhead2B" | "subhead3B"
  const props = external ? { href: to, target: "_blank", rel: "noreferrer" } : { component: RouterLink, to: to }
  const theme = useTheme()
  return (
    <Link
      {...props}
      variant={variant}
      sx={{
        display: inline ? "inline-flex" : "flex",
        textDecoration: underline ? 'underline' : 'none',
        textUnderlineOffset: '.25rem',
        marginTop: marginTop && marginTop,
        marginBottom: marginBottom && marginBottom,
        color: customColor ? customColor : theme.palette.primary.main
      }}>
      {direction === "back" && <West sx={{ marginRight: "0.2em" }} />}
      {children}
      {direction === "forward" && <East sx={{ marginLeft: "0.2em" }}/>}
      {direction === "external" && <OpenInNew sx={{ marginLeft: "0.3em", mb: -.25 }}/>}
    </Link>
  )
}
