import { FieldTripPlanListResponse, LocationTimeZone } from "@fieldday/fielddayportal-model"
import dayjs, { Dayjs } from "dayjs"
import advanced from "dayjs/plugin/advancedFormat"
import calendar from "dayjs/plugin/calendar"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import localeData from 'dayjs/plugin/localeData'
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from "dayjs/plugin/utc"

import { Location, ScheduledActivity } from "../models/Activity"
import { Campaign } from "../models/Campaign"
import { isEvent } from "./scheduledActivityUtils"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.extend(calendar)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(duration)

export const guessedTimeZone = dayjs.tz.guess()

export const thisYear = dayjs().year()

export const today = () => {
  return isoDate(dayjs.utc().tz(guessedTimeZone))
}

export const twoWeeksAgo = () => {
  return dayjs.utc().tz(guessedTimeZone).subtract(dayjs.duration({ weeks: 2 }))
}

export const toUTC = (timestamp?: string | null, timeZone?: LocationTimeZone): string => {
  const usedTimeZone = timeZone ?? guessedTimeZone
  return dayjs.tz(timestamp, usedTimeZone).toISOString()
}

export const toUTCLocation = (timestamp?: string | null, location?: Location | null): string => {
  const timeZone = locationTimeZone(location)
  return toUTC(timestamp, timeZone)
}

export const fromUTC = (timestamp: string, timeZone?: LocationTimeZone): string => {
  const usedTimeZone = timeZone ?? guessedTimeZone
  return dayjs.utc(timestamp).tz(usedTimeZone).format("YYYY-MM-DDTHH:mm:ss")
}

export const isoDate = (timestamp?: string | null | Dayjs): string => {
  return dayjs.tz(timestamp, guessedTimeZone).format("YYYY-MM-DD")
}

export const localDateTime = (date?: string, time?: string): string => {
  return `${date}T${time}`
}

export const tsFormatPastOnly = (timestamptz: string | Dayjs, showFullDate: boolean = false, abbreviate: boolean = false, ): string => {
  const fullDateFormat = "MM/DD/YYYY [at] h:mm A z"
  const abbreviatedDateFormat = "MM/DD/YY"
  if (showFullDate) {
    return dayjs.utc(timestamptz).tz(guessedTimeZone).calendar(null, {
      sameDay: fullDateFormat,
      lastDay: fullDateFormat,
      lastWeek: fullDateFormat,
      sameElse: fullDateFormat,
    })
  }

  if (abbreviate) {
    return dayjs.utc(timestamptz).tz(guessedTimeZone).calendar(null, {
      sameDay: "[Today]", // The same day ( Today at 2:30 AM )
      lastDay: "[Yesterday]", // The day before ( Yesterday at 2:30 AM )
      lastWeek: "dddd", // Last week ( Wednesday at 2:30 AM )
      sameElse: abbreviatedDateFormat,
    })

  }

  return dayjs.utc(timestamptz).tz(guessedTimeZone).calendar(null, {
    sameDay: "[Today at] h:mm A", // The same day ( Today at 2:30 AM )
    lastDay: "[Yesterday at] h:mm A", // The day before ( Yesterday at 2:30 AM )
    lastWeek: "dddd [at] h:mm A", // Last week ( Wednesday at 2:30 AM )
    sameElse: fullDateFormat,
  })
}

// Choose what time zone to use based on the location and activity type
// or undefined if the time zone should be guessed.
export function locationTimeZone(location?: Location | null): LocationTimeZone | undefined {
  // If there is a location, return its time zone or default to Pacific time
  // if the location does not have a timeZone.
  if (location) return location.timeZone ?? LocationTimeZone.Los_Angeles

  // Otherwise, return undefined, which indicates that there is no associated
  // time zone, so the display should use the browser's local time zone.
  return undefined
}

export function tsFormatLocationTime(location?: Location | null, timestamp?: string | null, durationInMinutes?: number): string {
  const timeZone = locationTimeZone(location)
  return tsFormatTime(timestamp, durationInMinutes, timeZone)
}

export function tsFormatLocationDate(location?: Location | null, timestamp?: string | null, abbrev?: "includeDay" | "dateOnly"): string {
  const timeZone = locationTimeZone(location)
  return tsFormatDate(timestamp, abbrev, timeZone)
}

export function tsFormatLocationDateTime(
  location?: Location | null,
  timestamp?: string | null,
  durationInMinutes?: number,
  abbrev?: "includeDay" | "dateOnly",
): string {
  const timeZone = locationTimeZone(location)
  return tsFormatDateTime(timestamp, durationInMinutes, abbrev, timeZone)
}

export const tsFormatDate = (
  timestamp?: string | null,
  abbrev?: "includeDay" | "dateOnly" | "monthDateYr" | "monthAbbrev",
  timeZone?: LocationTimeZone | null):
  string => {
  const usedTimeZone = timeZone ?? guessedTimeZone
  if (abbrev === 'includeDay') return dayjs.utc(timestamp).tz(usedTimeZone).format('dddd, MMMM D')
  if (abbrev === 'dateOnly') return dayjs.utc(timestamp).tz(usedTimeZone).format('MMMM D')
  if (abbrev === 'monthDateYr') return dayjs.utc(timestamp).tz(usedTimeZone).format('MMMM D, YYYY')
  if (abbrev === 'monthAbbrev') return dayjs.utc(timestamp).tz(usedTimeZone).format('MMM D')
  return dayjs.utc(timestamp).tz(usedTimeZone).format('dddd, MMMM D, YYYY')
}

const tsFormatTime = (timestamp?: string | null, durationInMinutes?: number, timeZone?: LocationTimeZone | null): string => {
  const usedTimeZone = timeZone ?? guessedTimeZone
  const tft = "h:mm A"
  const startAt = dayjs.utc(timestamp).tz(usedTimeZone)
  const endAt   = durationInMinutes ? startAt.add(durationInMinutes, "minutes") : undefined
  return endAt ? `${startAt.format(tft)} to ${endAt.format(`${tft} z`)}` : startAt.format(`${tft} z`)
}

const tsFormatDateTime = (
  timestamp?: string | null,
  durationInMinutes?: number,
  abbrev?: "includeDay" | "dateOnly",
  timeZone?: LocationTimeZone | null,
): string => {
  return `${tsFormatDate(timestamp, abbrev, timeZone)} ${tsFormatTime(timestamp, durationInMinutes, timeZone)}`
}

export const tsFormatDateRange = (startDate: string, endDate: string, includeYear: boolean = false, abbrev?: boolean): string => {
  // Making assumption that nobody will be creating campaigns that span New Year's
  const year = includeYear ? `, ${dayjs(endDate).year()}` : ""
  const startYear = dayjs(startDate).year() !== dayjs(endDate).year() && includeYear ? `, ${dayjs(startDate).year()}` : ""

  if (startDate === endDate) {
    if (abbrev) return dayjs(startDate).format("MMM D")
    if (includeYear) return dayjs(startDate).format("MMMM D, YYYY")
    return dayjs(startDate).format("MMMM D")
  } else if (dayjs(startDate).month() !== dayjs(endDate).month()) {
    if (abbrev && includeYear) return `${dayjs(startDate).format("MMM D")}${startYear}–${dayjs(endDate).format("MMM D")}${year}`
    if (abbrev) return `${dayjs(startDate).format("MMM D")}–${dayjs(endDate).format("MMM D")}`
    return `${dayjs(startDate).format("MMMM D")}${startYear}–${dayjs(endDate).format("MMMM D")}${year}`
  } else {
    if (abbrev && includeYear) return `${dayjs(startDate).format("MMM D")}–${dayjs(endDate).date()}${year}`
    if (abbrev) return `${dayjs(startDate).format("MMM D")}–${dayjs(endDate).date()}`
    return `${dayjs(startDate).format("MMMM D")}${startYear}–${dayjs(endDate).date()}${year}`
  }
}

export const saIsPast = (sa: ScheduledActivity, useEndTime?: boolean): boolean => {
  const startTime = dayjs.utc(sa.timestampUTC).tz(guessedTimeZone)
  const endTime = startTime.add(sa.durationInMinutes ?? 0, "minute")
  const now = dayjs.utc()
  return useEndTime ? endTime.isBefore(now) : startTime.isBefore(now)
}

// can add a buffer if we want a campaign to display for x amount of time after its conclusion
export const campaignIsPast = (campaign: Campaign, buffer: number = 1): boolean => {
  const endDate = dayjs.utc(campaign.endDate).tz(guessedTimeZone)
  const now = dayjs.utc().subtract(buffer, 'day')
  return endDate.isBefore(now)
}

export const sortCampaignByEndDate = (campaign: Campaign[]): Campaign[] => {
  const copiedCamps = campaign.slice()
  return copiedCamps.sort((a, b) => {
    return dayjs(b.endDate).unix() - dayjs(a.endDate).unix()
  })
}

export const sortSasByDate = (saA: ScheduledActivity, saB: ScheduledActivity): number => {
  const saADate = dayjs.utc(saA.timestampUTC)
  const saBDate = dayjs.utc(saB.timestampUTC)
  if (saADate.isBefore(saBDate)) {
    return -1
  } else if (saBDate.isBefore(saADate)) {
    return 1
  } else {
    return 0
  }
}

export const sortCampaignsByDate = (campaignA: Campaign, campaignB: Campaign): number => {
  const campaignADate = dayjs.utc(campaignA.endDate)
  const campaignBDate = dayjs.utc(campaignB.endDate)

  if (campaignADate.isBefore(campaignBDate)) {
    return -1
  } else if (campaignBDate.isBefore(campaignADate)) {
    return 1
  } else {
    return 0
  }
}

export const sortSasAndCampsByDate = (eventA: ScheduledActivity | Campaign, eventB: ScheduledActivity | Campaign): number => {
  let dateA = dayjs()
  let dateB = dayjs()

  if (isEvent(eventA)) {
    dateA = dayjs.utc((eventA as ScheduledActivity).timestampUTC)
  } else {
    dateA = dayjs.utc((eventA as Campaign).endDate)
  }

  if (isEvent(eventB)) {
    dateB = dayjs.utc((eventB as ScheduledActivity).timestampUTC)
  } else {
    dateB = dayjs.utc((eventB as Campaign).endDate)
  }

  if (dateA.isBefore(dateB)) {
    return -1
  } else if (dateB.isBefore(dateA)) {
    return 1
  } else {
    return 0
  }
}

export const tsFormatFtpTimeline = (timestamptz: string | Dayjs, showFullDate: boolean = false): string => {
  if (showFullDate) {
    return tsFormatPastOnly(timestamptz, showFullDate)
  }
  return dayjs(timestamptz).fromNow()
}

export const ftpLastUpdatedAt = (ftp: FieldTripPlanListResponse): string => {
  const updatedAt = ftp.userRefreshedAt ?? ftp.updatedAt
  return dayjs(updatedAt).format('MMM D, YYYY [at] h:mm A')
}

export const ftpReminderUpdatedAt = (ftp: FieldTripPlanListResponse): string => {
  return dayjs(ftp.reminderStateUpdatedAt).format('MMM D, YYYY [at] h:mm A')
}

export const ftpUpdatedAtAbbrv = (updatedAt: string | undefined): string => {
  if(!updatedAt) return ' '
  const isToday = dayjs(updatedAt).format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")
  const displayUpdatedAt = isToday ? dayjs(updatedAt).format("h:mm A") : dayjs(updatedAt).format("MMM DD")
  return displayUpdatedAt
}

export const timeIsValid = (time?: string | null): boolean => {
  return !!time && time !== "" && dayjs(`1970-01-01T${time}`).isValid()
}

export const dateIsValid = (date?: string | null): boolean => {
  return !!date && date !== "" && dayjs(date).isValid()
}

export const dateTimeIsValid = (dateTime?: string | null): boolean => {
  return !!dateTime && dateTime !== "" && dayjs(dateTime).isValid()
}

export const sanitizedDate = (date?: string): string => {
  const sanitized = dateIsValid(date) ? dayjs(date) : dayjs()
  return sanitized.format("YYYY-MM-DD")
}

// If date or time are invalid, or the timestamp cannot be
// converted to a valid timestamp, return an empty string.
// Otherwise, return the provided values as a UTC timestamp.
export const buildTimestampUTC = (dateISO?: string, time?: string, location?: Location | null): string => {
  if (!dateIsValid(dateISO) || !timeIsValid(time)) return ""
  const local = localDateTime(dateISO, time)
  if (!dayjs(local).isValid()) return ""
  const ts = toUTCLocation(local, location)
  return ts
}

export const getStartAndEndToToday = (year?: number): { startDate: string, endDate: string } => {
  const currentYear = dayjs().year()
  const useYear = year ?? currentYear
  // if it's this year, then don't inclulde events scheduled in the future
  // otherwise, go to the end of the year
  const endOn = useYear === currentYear ? dayjs() : dayjs().year(useYear).endOf('year')
  // if year specified, use start of it, otherwise it's "all time" which starts from 2022
  const startOn = year ? dayjs().year(useYear).startOf('year') : dayjs().year(2022).startOf('year')
  return { startDate: startOn.toISOString(), endDate: endOn.toISOString() }
}

export const startOfThisMonth = dayjs().tz(guessedTimeZone).startOf("month").format("YYYY-MM-DD")
export const endOfThisMonth = dayjs().tz(guessedTimeZone).endOf("month").format("YYYY-MM-DD")

export const startOfLastMonth = dayjs().tz(guessedTimeZone).subtract(1, "month").startOf("month").format("YYYY-MM-DD")
export const endOfLastMonth = dayjs().tz(guessedTimeZone).subtract(1, "month").endOf("month").format("YYYY-MM-DD")

export function objFromUtcAsTz(utcTimestamp: string): Dayjs {
  return dayjs.utc(utcTimestamp).tz(guessedTimeZone)
}

export function objFromTzAsUtc(tzTimestamp: string): Dayjs {
  return dayjs.tz(tzTimestamp, guessedTimeZone).utc()
}

export function dayjsTz(timestamp?: string | null, timeZone?: LocationTimeZone): Dayjs {
  const usedTimeZone = timeZone ?? guessedTimeZone
  return dayjs.utc(timestamp).tz(usedTimeZone)
}

export function shortDate(date: Dayjs): string {
  return date.format("MMM D, YYYY")
}
