import _ from "lodash"

// check if keyname exists in object
export function hasKey<O extends Record<string, unknown>>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj
}

// Get a property from an object with a string variable as the property name.
export function getNamedProperty<O>(obj: O, propertyName: keyof O): O[keyof O] {
  return obj[propertyName]
}

// Remove all undefined properties from an object.
// eslint-disable-next-line @typescript-eslint/ban-types
export function removeUndefinedProperties<O extends object>(obj: O): Partial<O> {
  return _.pickBy(obj, p => p !== undefined)
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export function sortedJsonStringify(obj: Record<string, unknown>): string {
  return JSON.stringify(obj, Object.keys(obj).sort())
}

// Takes a list and returns the same elements
// in a 2d array grouped in batches of batchSize.
// The final batch in the 2d array may be smaller than batchSize.
export function batchify<T>(arr: T[], batchSize: number): T[][] {
  if (batchSize === 0 || arr.length === 0) return []

  const batches: T[][] = []

  const fullBatches = Math.floor(arr.length / batchSize)
  const remainingBatch = arr.length % batchSize

  // Add all of the full batches.
  for (let i = 0; i < fullBatches; i++) {
    const start = i * batchSize
    const end = start + batchSize
    const slice = arr.slice(start, end)
    batches.push(slice)
  }

  // For the last batch, return the rest of the elements
  // in the array.
  if (remainingBatch > 0) {
    // end is undefined which means read the rest of the array.
    const slice = arr.slice(fullBatches * batchSize)
    batches.push(slice)
  }

  return batches
}
