import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import useFooterStyles from '../../styles/footerStyles'
import { thisYear } from '../../util/dateUtil'
import { matchPath, useLocation } from 'react-router-dom'

export default function Footer() {
  const footerStyles = useFooterStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const { pathname } = useLocation()
  const pathMatch = (path: string | string[], exact?: boolean): boolean => !!matchPath(pathname, { path: path, exact })
  const loginPage = pathMatch('/', true )

  const learnAboutFieldDay = <a href="https://www.fieldday.com" target="_blank" rel="noreferrer">Learn about Field Day</a>

  const socialIcons = (
    <>
      <a href="https://www.linkedin.com/company/fieldday/" target="_blank" rel="noreferrer">
        <LinkedInIcon htmlColor={theme.palette.neutral.main}
          sx={{ fontSize: mobileView ? "48px" : "1.25rem", verticalAlign: "text-top", mr: mobileView ? "2rem" : "1rem" }}
        />
      </a>
      <a href="https://www.instagram.com/heyfieldday/" target="_blank" rel="noreferrer">
        <InstagramIcon htmlColor={theme.palette.neutral.main} sx={{ fontSize: mobileView ? "48px" : "1.25rem", verticalAlign: "text-top" }}/>
      </a>
    </>
  )

  return loginPage ? null : (
    <footer style={{ marginTop: "3rem" }}>
      <Stack className={footerStyles.footer} direction={mobileView ? "column" : "row"} justifyContent="space-between">
        <Stack direction="row" justifyContent={mobileView ? "center" : "flex-start"}>
          <Typography variant="body2">
            &copy; {thisYear} Field Day
          </Typography>
          <Typography variant="body2" pl={2}>
            <a href="https://heyfieldday.com/tos" target="_blank" rel="noreferrer">Terms</a>
          </Typography>
          <Typography variant="body2" pl={2}>
            <a href="https://heyfieldday.com/privacy" target="_blank" rel="noreferrer">Privacy</a>
          </Typography>
        </Stack>
        {mobileView ?
          <>
            <Typography variant="body2" mt={2} textAlign="center">
              {learnAboutFieldDay}
            </Typography>
            <Stack mt={2} direction="row" justifyContent="center">
              {socialIcons}
            </Stack>
          </>
          :
          <Stack direction="row">
            {socialIcons}
            <Typography variant="body2" pl={2}>
              {learnAboutFieldDay}
            </Typography>
          </Stack>
        }
      </Stack>
    </footer>
  )
}
