import { MatchableDonationModelResponse, PlatformDonationModelResponse } from "@fieldday/fielddayportal-model"
import { Grid, Link, Typography, useTheme } from "@mui/material"
import dayjs from "dayjs"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserHelper } from "../../models/UserHelper"
import { UserInfo } from "../../models/UserInfo"
import { objFromUtcAsTz } from "../../util/dateUtil"
import { DonationUiStatus, getDonationUiStatus } from "../../util/matchableDonationUtils"
import { roundMinutesToHours } from "../../util/mathUtil"
import { dollarCents, pluralizeWithNum } from "../../util/stringUtil"
import { sanitizeExternalUrl } from "../../util/urlManipulation"

interface props {
  matchableDonation?: MatchableDonationModelResponse,
  platformDonation?: PlatformDonationModelResponse,
  donationUser?: UserInfo,
  donationNpo?: NPO,
  donationOrgUnit?: OrgUnit,
  canAdmin: boolean,
  adminView: boolean,
}

export default function MatchableDonationDetails(props: props) {
  const {
    matchableDonation,
    platformDonation,
    donationUser,
    donationNpo,
    donationOrgUnit,
    canAdmin,
    adminView,
  } = props
  const donationUrl = sanitizeExternalUrl(matchableDonation?.donationUrl)
  const status = matchableDonation && getDonationUiStatus(matchableDonation)
  const paymentFailed = status === DonationUiStatus.PaymentFailed
  const paymentPending = status === DonationUiStatus.PaymentPending
  const volunteerTime = matchableDonation?.minutesVolunteered
  const donorUh = donationUser ? new UserHelper(donationUser) : undefined

  return (
    <Grid container spacing={3} mb={2}>
      {(canAdmin && adminView) &&
        <DonationDetailGridItem
          title="Donor"
          content={donorUh?.displayName()}
          secondaryContent={donationUser?.email}
        />
      }
      {volunteerTime ?
        <DonationDetailGridItem
          title="Time volunteered"
          content={`${pluralizeWithNum(roundMinutesToHours(volunteerTime), "hour")}`}
        />
        :
        <DonationDetailGridItem
          title="Donation amount"
          content={dollarCents(paymentFailed || paymentPending ? platformDonation?.intendedAmountCents : matchableDonation?.amount)}
        />
      }
      <DonationDetailGridItem
        title={volunteerTime ? "Volunteer date" : "Donation date"}
        content={matchableDonation && dayjs(matchableDonation?.dateISO).format("MMMM D, YYYY")}
        secondaryContent={(!platformDonation && matchableDonation) ?
          "Reported: " + objFromUtcAsTz(matchableDonation?.createdAt).format("MMMM D, YYYY") : undefined}
      />
      <DonationDetailGridItem
        title="Nonprofit"
        content={donationNpo?.name}
        secondaryContent={!platformDonation || adminView ? "EIN: " + donationNpo?.ein : undefined}
      />
      {donationUrl &&
        <DonationDetailGridItem title="Donation website">
          <Link sx={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mt: .5
          }} target="_blank" rel="noopener" href={donationUrl}>{donationUrl}</Link>
        </DonationDetailGridItem>
      }
      {donationOrgUnit && <DonationDetailGridItem title="Team" content={donationOrgUnit.name}/>}
      { /* XXX: add this to the response model for real; just trying to get this visible for a demo */}
      {matchableDonation && (matchableDonation as unknown as { doubleTheDonationMetadataId: string })?.doubleTheDonationMetadataId &&
        <DonationDetailGridItem title="Reporting source">
          Reported via Double the Donation
        </DonationDetailGridItem>
      }
      {matchableDonation?.receiptUrl &&
        <DonationDetailGridItem title="Donation receipt">
          <Link href={`${matchableDonation.receiptUrl}`} download={
            matchableDonation.receiptFileName ?
              decodeURIComponent(matchableDonation.receiptFileName) :
              undefined
          }>{"Download receipt"}</Link>
        </DonationDetailGridItem>
      }
      {matchableDonation?.contextMessage &&
        <DonationDetailGridItem title="Memo" content={matchableDonation.contextMessage} override={{ xs: 12, sm: 12, md: 8 }}/>
      }
    </Grid>
  )
}

export function DonationDetailGridItem({ children, title, content, secondaryContent, override }: {
  children?: React.ReactNode,
  title: string,
  content?: string,
  secondaryContent?: string,
  override?: { xs?: number, sm?: number, md?: number, secondaryColor?: boolean }
}) {
  const theme = useTheme()

  return (
    <Grid item xs={override?.xs ?? 6} sm={override?.sm ?? 4} md={override?.md}>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        {title}
      </Typography>
      <Typography mt={.5} color={override?.secondaryColor ? theme.palette.text.secondary : "auto"}>
        {children ?? content}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {secondaryContent}
      </Typography>
    </Grid>
  )
}
