import { Causes } from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography, useTheme
} from "@mui/material"
import { DonationMatchPolicy } from "../../models/Donations"
import { NPO } from "../../models/Npo"
import { tsFormatDate, tsFormatDateRange } from "../../util/dateUtil"
import { matchPolicyLimitAmountPretty } from "../../util/matchableDonationUtils"
import { matchedPercentage } from "../../util/mathUtil"
import { dollarCents, dollarDollars, regionToString } from "../../util/stringUtil"
import { PolicyStatusChip } from "./MatchableDonationPolicyListItem"

export default function MatchableDonationPolicyDialog({ matchPolicy, open, close, handleEditClick, adminView, configView, policyNpos }: {
  matchPolicy: DonationMatchPolicy,
  policyNpos?: NPO[],
  open: boolean,
  close: () => void,
  handleEditClick?: () => void,
  adminView?: boolean,
  configView?: boolean
}) {
  const theme = useTheme()
  const matchLimitPercentRemaining = matchPolicy.approvedTotalDollars === 0 ?
    100 : matchedPercentage(matchPolicy.matchLimitTotalDollars, matchPolicy.approvedTotalDollars, true)
  const matchLimitDollarsRemaining = matchPolicy.approvedTotalDollars === 0 ? matchPolicy.matchLimitTotalDollars :
    (matchPolicy.matchLimitTotalDollars && matchPolicy.approvedTotalDollars) && matchPolicy.matchLimitTotalDollars - matchPolicy.approvedTotalDollars

  const approvalThresholdString = (() => {
    if (matchPolicy.approvalThresholdDollars == null) return "Not set: auto-approve all reported donations"
    if (matchPolicy.approvalThresholdDollars === 0) return "Manual approval for all reported donations"
    return `Auto-approve up to $${(matchPolicy.approvalThresholdDollars ?? 0).toLocaleString()}`
  })()

  return (
    <Dialog open={open} onClose={close} maxWidth={adminView ? 'md' : 'sm'} fullWidth>
      <Grid container>
        <Grid item xs={8}>
          <DialogTitle style={{ marginBottom: '0em', paddingBottom: '0em' }}>
            {matchPolicy.name}
            {!configView &&
              <IconButton
                aria-label="closePolicyModal"
                sx={{ position: 'absolute', right: 16, top: 16 }}
                onClick={() => {close()}}
              >
                <CloseIcon />
              </IconButton>
            }
            {adminView && <PolicyStatusChip status={matchPolicy.status} size='small' marginOverride={{ left: '1rem' }} />}
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent sx={{ pt: 0, pb: !configView ? 4 : "auto" }}>
        <Grid container>
          <DonationPolicyGridItem subHeader="Policy dates" override={{ xs: adminView ? 12 : 6 }}>
            <Typography variant="subhead3B" mt={.5}>
              {matchPolicy.startISO && matchPolicy.endISO && tsFormatDateRange(matchPolicy.startISO, matchPolicy.endISO, true)}
            </Typography>
            <Typography variant="caption">
              Report deadline: {tsFormatDate(matchPolicy.acceptEndISO, 'monthDateYr')}
            </Typography>
          </DonationPolicyGridItem>
          <DonationPolicyGridItem subHeader="Match percent" override={{ xs: 3 }} >
            <Typography variant="subhead3B" mt={.5}>
              {matchPolicy.matchPercent}%
            </Typography>
          </DonationPolicyGridItem>
          <DonationPolicyGridItem subHeader="Hourly match" override={{ xs: adminView ? 9 : 3 }}>
            {(matchPolicy.volunteerHourlyMatchCents != null) &&
              <Typography variant="subhead3B" mt={.5}>
                {dollarCents(matchPolicy.volunteerHourlyMatchCents)}
              </Typography>
            }
            {(matchPolicy.volunteerHourlyMatchCents == null) &&
              <Typography variant="subhead3B" mt={.5}>
                No volunteer matching
              </Typography>
            }
          </DonationPolicyGridItem>
          {adminView &&
            <DonationPolicyGridItem subHeader="Total match limit" override={{ xs: 3 }} >
              <Typography variant="subhead3B" mt={.5}>
                {matchPolicyLimitAmountPretty(matchPolicy.matchLimitTotalDollars)}
              </Typography>
            </DonationPolicyGridItem>
          }
          <DonationPolicyGridItem subHeader={adminView ? "Individual match limit" : "Match limit"} override={{ xs: 3 }} >
            <Typography variant="subhead3B" mt={.5}>
              {matchPolicyLimitAmountPretty(matchPolicy.matchLimitIndividualDollars)}
            </Typography>
          </DonationPolicyGridItem>
          {adminView && <>
            <DonationPolicyGridItem subHeader="Approval threshold" override={{ xs: 3 }} >
              <Typography variant="subhead3B" mt={.5}>
                {approvalThresholdString}
              </Typography>
            </DonationPolicyGridItem>
            <DonationPolicyGridItem subHeader="Miniumum donation amount" override={{ xs: 3 }} >
              <Typography variant="subhead3B" mt={.5}>
                {dollarDollars(matchPolicy.minimumDonationAmountDollars)}
              </Typography>
            </DonationPolicyGridItem>
          </>}
          <DonationPolicyGridItem subHeader="Description" override={{ xs: 12, lg: adminView ? 8 : 12 }} >
            <Typography mt={.5}>
              {matchPolicy.description}
            </Typography>
          </DonationPolicyGridItem>
          {adminView &&
            <>
              <Grid item xs={12}>
                <Divider style={{ marginTop: '1.5em', marginBottom: '1em' }} />
              </Grid>
              <DonationPolicyGridItem subHeader="Total approved" override={{ xs: 3 }} >
                <Typography variant="subhead3B" mt={.5}>
                  ${matchPolicy.approvedTotalDollars?.toLocaleString()}
                </Typography>
              </DonationPolicyGridItem>
              {!!matchPolicy.matchLimitTotalDollars && matchPolicy.matchLimitTotalDollars > 0 &&
                <DonationPolicyGridItem subHeader='Match limit remaining' override={{ xs: 3 }} >
                  <Typography variant="subhead3B" mt={.5}>${matchLimitDollarsRemaining?.toLocaleString()} ({matchLimitPercentRemaining}%)</Typography>
                </DonationPolicyGridItem>
              }
              <DonationPolicyGridItem subHeader="Total matched" override={{ xs: 3 }}>
                <Typography variant="subhead3B" mt={.5}>
                  ${matchPolicy.matchedTotalDollars?.toLocaleString()}
                </Typography>
              </DonationPolicyGridItem>
            </>
          }
        </Grid>
        {policyNpos && policyNpos.length > 0 &&
          <DonationPolicyGridItem subHeader="Nonprofits of focus" override={{ xs: 12, }}>
              <List style={{ maxHeight: "400px", overflowY: "scroll", marginLeft: '1rem' }}>
              {policyNpos.map(npo => {
                  const causeChip = (cause: string | undefined) => Causes.map((c, i) => {
                    if (!cause) return null
                    if (cause === c) return (
                      <Chip label={c} size="small" key={i}
                        style={{ color: theme.palette.darkColorsArr[i], backgroundColor: theme.palette.lightColorsArr[i] }}
                      />
                    )
                  })
                return (
                  <ListItem key={npo.id}>
                    <ListItemText
                      primary={
                        <Typography>
                          {npo.name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="label2" color='textSecondary'>
                          {regionToString(npo.primaryRegion)}
                        </Typography>
                      }
                      style={{ margin: '0px', maxWidth: '80%' }}
                    />
                    {causeChip(npo.primaryCause)}
                  </ListItem>
                )
              })}
              </List>
          </DonationPolicyGridItem>
        }
      </DialogContent>
      {(configView && handleEditClick) &&
        <DialogActions style={{ marginTop: '1rem' }}>
          <Button onClick={close}>
            Close
          </Button>
          <Button
            onClick={() => {
              handleEditClick()
              close()
            }}
            variant="contained">
            Edit
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}

export function DonationPolicyGridItem({ children, subHeader, tooltipTitle, override }: {
  children?: React.ReactNode,
  subHeader: string,
  override: { xs: number, lg?: number, list?: boolean }
  tooltipTitle?: string
}) {
  const theme = useTheme()

  return (

    <Grid item xs={override.xs} lg={override.lg} mt={override.list ? "auto" : 3}>
      <Tooltip title={tooltipTitle} placement='right-start' arrow>
        <Typography variant={'label2'} color={theme.palette.text.secondary} width='max-content'>
          {subHeader}
        </Typography>
      </Tooltip>
      {children}
    </Grid>

  )
}
