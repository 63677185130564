import { linearProgressClasses } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/system';

const useGlobalStyles = makeStyles((theme) => ({
  heroContent: {
    paddingTop: theme.spacing(4),
  },
  previewRoot: {
    display: 'flex',
    width: "100%",
    '& $sidebar': {
      maxWidth: "360px",
    },
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  progressBar: {
    width: "100vw",
    position: "fixed",
    zIndex: 2000,
    top: 0,
    height: 7,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.primary.light,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: theme.palette.primary.dark,
    },
    [`& .${linearProgressClasses.bar2Indeterminate}`]: {
      display: "none",
    },
    [`& .${linearProgressClasses.bar1Indeterminate}`]: {
      animationTimingFunction: "ease-in",
    },
  },
  content: {
    position: "relative",
    maxWidth: "100%",
    flexGrow: 1,
  },
  primaryDivider: {
    margin: theme.spacing(2, 0, 4),
    borderColor: theme.palette.divider,
  },
  secondaryDivider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.palette.divider,
  },
  miniDivider: {
    margin: theme.spacing(2, 0),
    borderColor: theme.palette.divider,
  },
  profileContent: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileAvatar: {
    margin: theme.spacing(2, 0),
    width: theme.spacing(28),
    height: theme.spacing(28),
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 400, // needs to be higher than dialogs
    color: theme.palette.primary.main,
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(245, 245, 245, 0.7)",
  },
  breadcrumb: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(0, 0, .5),
  },
  sidebar: {
    padding: theme.spacing(1, 1, 1, 0),
    zIndex: theme.zIndex.appBar - 200,
    width: "100%",
    maxWidth: "300px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%"
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
  },
  sidebarPane: {
    overflowY: "auto",
    overflowX: "hidden",
    flexGrow: 1,
  },
  helperText: {
    fontSize: theme.spacing(1.5),
    color: "#727272",
    '&.warning': {
      color: theme.palette.error.main,
    }
  },
  helperLink: {
    cursor: "pointer",
  },
  buttonHintText: {
    textTransform: "uppercase",
    fontWeight: 700,
  },
  preview: {
    marginLeft: theme.spacing(3),
    minHeight: "fit-content",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  subHeader: {
    fontSize: theme.spacing(2),
  },
  dateAvatar: {
    flexGrow: 0,
    height: "3.5625rem",
    width: "3.5625rem",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    flexDirection: "column",
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: 20 / 16,
    borderRadius: "2px",
    '&.draft': {
      backgroundColor: theme.palette.neutral.light,
      color: theme.palette.neutral.main,
    },
    '&.canceled': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
  },
  ctaBanner: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    minHeight: '64px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.4)',
    backgroundColor: 'white',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ctaButton: {
    position: 'absolute',
    height: '100%',
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    fontSize: '18px',
    color: 'inherit !important',
    padding: '0',
    transition: 'all .1s',
    '&:hover': {
      textShadow: '0 0 .3px grey, 0 0 .3px grey',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    '&.cta': {
      padding: '0 40px 0 10vw',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      clipPath: 'inset(-5px 0px 0px 0px)',
    },
    '&.dismiss': {
      right: 0,
      fontSize: '32px',
      color: 'grey !important',
      width: '64px',
    }
  },
  adminBanner: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    minHeight: '64px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.4)',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  printView: {
    margin: theme.spacing(4),
  },
  notificationBanner: {
    width: "500px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 0.6px 1.4px rgba(0, 0, 0, 0.037)," +
      "0px 1.7px 3.4px rgba(0, 0, 0, 0.053)," +
      "0px 3.3px 6.4px rgba(0, 0, 0, 0.065)," +
      "0px 5.8px 11.4px rgba(0, 0, 0, 0.077)," +
      "0px 9.9px 21.3px rgba(0, 0, 0, 0.093)," +
      "0px 20px 51px rgba(0, 0, 0, 0.13)",
    borderRadius: "15px",
  },
  notificationBannerBody: {
    position: "relative",
    paddingRight: "2em",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.03)",
    }
  },
  notificationDismiss: {
    position: "absolute",
    top: "50%",
    right: 0,
    fontSize: "1.25em",
    transform: "translateY(-50%)",
    cursor: "pointer",
    marginRight: "1em",
    marginTop: "1em",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
      borderRadius: "1rem",
    }
  },
  viewAllNotifications: {
    margin: "0.5em",
  },
  notificationList: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "8px",
  },
  notificationListItem: {
    position: "relative",
    padding: theme.spacing(2, 2, 2, 4),
    margin: "10px 0",
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
    '&.unread': {
      boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
      backgroundColor: "rgba(245, 245, 245, .6)",
    },
  },
  notificationIcon: {
    color: theme.palette.neutral.dark,
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  notificationLink: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  unreadIndicator: {
    position: "absolute",
    fontSize: "12px",
    minWidth: "8px",
    left: "10px",
  },
  clickableText: {
    fontSize: "14px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    '&.disabled': {
      color: theme.palette.action.disabled,
      cursor: "default",
    },
  },
  addToCal: {
    minWidth: "175px",
    '& a': {
      color: "inherit !important"
    }
  },
  loadingText: {
    fontStyle: "italic",
    color: theme.palette.neutral.dark,
    padding: "2em",
  },
  mapPreview: {
    paddingBottom: "60%",
    backgroundColor: theme.palette.neutral.light,
    borderRadius: "8px",
  },
  activityNameList: {
    fontWeight: 600,
    fontSize: "1rem",
    color: theme.palette.primary.dark,
    lineHeight: 1,
    marginBottom: "0.25rem",
    '&.review': {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    },
    '&.notLink': {
      color: theme.palette.text.primary,
    },
  },
  activeIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
    }
  },
  dashStatsBar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: theme.spacing(3, 1.5, 3, 1.5),
    minHeight: '7em'
  },
  dashStatsBarPaper: {
    backgroundColor: '#E4ECDE',
    padding: '0px',
    '&.empty': {
      backgroundColor: '#F4F5F5'
    },
  },
  dashEmptyBox: {
    backgroundColor: '#F4F5F5',
    padding: theme.spacing(3),
    textAlign: 'center',
    color: '#6E7C7C',
    width: '100%'
  },
  listCoords: {
    padding: theme.spacing(0, 0, 0, 1.5),
    width: '100%',
  },
  dashImpactBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    '&.empty': {
      flexDirection: 'column',
    },
  },
  groupButton: {
    lineHeight: "inherit",
  },
  dashboardWidget: {
    width: '100%',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#C1C5C8',
    borderRadius: '4px',
    '&.splitLeft': {
      width: '50%',
      marginRight: '.5em'
    },
    '&.splitRight': {
      width: '50%',
      marginLeft: '.5em'
    },
  },
  widgetCardHeader: {
    padding: theme.spacing(2, 3),
    borderBottomStyle: 'solid',
    borderBottomColor: '#C1C5C8',
    borderBottomWidth: '1px'
  },
  widgetCardBody: {
    padding: theme.spacing(3),
    '&.padding': {
      padding: theme.spacing(3, 0, 3, 0),
    }
  },
  widgetHeader: {
    textAlign: 'center',
    backgroundColor: '#F4F5F5',
    marginBottom: '1em',
    padding: theme.spacing(.5)
  },
  popperExpandActive: { // keep the "hover" effect on when the menu is open
    "&.contained": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8)
    },
    "&.outlined": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2)
    },
    "&.textDash": {
      backgroundColor: "transparent",
      '&:hover': {
        backgroundColor: "transparent",
      },
    },
  },
  popperExpandInactive: {
    "&.textDash": {
      backgroundColor: "transparent",
      '&:hover': {
        backgroundColor: "transparent",
      },
    },
  },
  activityScrollingListItem: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.neutral.light,
    '& $dateAvatar': {
      backgroundColor: '#fff',
      color: theme.palette.text.secondary,
    },
    '&.warn': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  normalParagraph: {
    '& p': {
      margin: "1em 0",
    },
  },
  dashboardChartTabs: {
    paddingBottom: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer'
    },
    '&.selected': {
      width: 'inherit',
      fontWeight: 700,
      color: theme.palette.primary.dark,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.dark,
      borderBottomWidth: '2px',
    },
    '&.selectedCaption': {
      fontWeight: 700,
      color: theme.palette.primary.dark,
      '&:hover': {
        cursor: 'unset'
      },
    },
  },
  markdownBlock: {
    '& a': {
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.dark,
      borderBottomWidth: '1px',
    },
    '& p:first-child': {
      marginTop: 0
    },
    '& p:last-child': {
      marginBottom: 0
    },
  },
  feedPhotoSwipeBtn: {
    '&:hover': {
      backgroundColor: theme.palette.neutral.dark,
      opacity: '.5'
    }
  },
  commentListItem: {
    backgroundColor: theme.palette.neutral.light,
    padding: '8px'
  },
  approvalButton: {
    '&.cancel': {
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
  },
  feedCard: {
    display: 'flex',
    marginTop: '2em',
    marginBottom: '2em',
    maxHeight: 'auto',
    '&.draft': {
      backgroundColor: theme.palette.neutral.light
    },
    '&.campaign': {
      backgroundColor: theme.palette.primary.light
    }
  },
  logInBackgroundBox: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    paddingBottom: '3rem',
    minHeight: "100vh",
    maxHeight: "100%"
  },
  logInEventsList: {
    flexDirection:'row',
    justifyContent: 'center',
    top: '-210px'
  },
  editImageIconBtn: {
    margin: theme.spacing(1, 0, 0, 1),
    position: 'absolute',
    backgroundColor: 'white',
    border: 'solid .5px' + theme.palette.divider,
    borderRadius: '2px',
    '&:hover': {
      opacity: '.75',
      backgroundColor: theme.palette.neutral.light,
    }
  },
  previewActivityHeader: {
    color: theme.palette.grey[500],
    margin: theme.spacing(0, 0, 3, 0),
  },
  activitySubheader: {
    margin: theme.spacing(4, 0, 2, 0),
    '&.bottomNone': {
      margin: theme.spacing(4, 0, 0, 0)
    },
    '&.topNone': {
      margin: theme.spacing(0, 0, 2, 0)
    }
  },
  dashEventListItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3, 0, 3),
    '&.borders': {
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: theme.palette.divider,
    },
  },
  eventListItemLink: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
  eventTypeSummaryBox: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    '&.borderRight': {
      paddingLeft: theme.spacing(0),
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      borderRightColor: theme.palette.divider
    }
  },
  matchPolicyLink: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: "1rem",
    color: theme.palette.primary.dark,
    lineHeight: 1,
    marginBottom: "0.25rem",
    '&.disabled': {
      color: theme.palette.neutral.main,
      '&:hover': {
        cursor: 'unset',
      }
    }
  },
  donationListItem: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
    '&.npo': {
      fontWeight: 600,
      color: theme.palette.primary.dark
    },
    '&.donorInfo': {
      fontSize: ".75rem",
      marginTop: 2,
      color: theme.palette.text.secondary
    }
  }
}));

export default useGlobalStyles
