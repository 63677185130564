import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography, createFilterOptions } from "@mui/material"
import { ReactNode, useRef } from "react"
import { NPOWithId } from "../../models/Npo"
import { autogenHandle, regionOrOtherToString } from "../../util/stringUtil"

export function AutocompleteForNpoSearch({
  handleNpoChange,
  setNpoSearch,
  searchedNpos,
  error,
  helperText,
  startAdornment,
}: {
  handleNpoChange: (_event: React.SyntheticEvent<Element, Event>, newValue: NPOWithId | null, reason: string) => void,
  setNpoSearch: (value: React.SetStateAction<string>) => void,
  searchedNpos: readonly NPOWithId[],
  error?: boolean,
  helperText?: ReactNode,
  startAdornment?: ReactNode,
}) {
  const filteredRef = useRef<NPOWithId | null>(null)
  const filter = createFilterOptions<NPOWithId>()
  const newModel = (newVal: string): NPOWithId => {
    return {
      name: newVal,
      handle: autogenHandle(newVal),
    } as NPOWithId
  }

  const validOpts: Readonly<NPOWithId>[] = searchedNpos.filter(npo => !!npo.ein)

  return (
    <Autocomplete
      onChange={handleNpoChange}
      onInputChange={(_event, value) => setNpoSearch(value) }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params}
          label="Nonprofit name or EIN"
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
          }}
        />
      )}
      options={validOpts}
      getOptionLabel={(option) => {
        if (!option) return `no nonprofit`
        if (typeof option === 'string') return option
        if (typeof option.name === 'string') return option.name
        return ""
      }}
      isOptionEqualToValue={(_option, _value) => true}
      renderOption={(props, option) => {
        return <li key={`${option.ein}-${option.handle ?? option.name}`} {...props}>
          <Typography paddingRight="1em">
            {option.name}
          </Typography>
          <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
            {regionOrOtherToString(option.primaryRegion, option.city, option.state)}
          </Typography>
        </li>
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        if (params.inputValue !== '' && !filtered.some(npo => (
          npo.name?.toLowerCase() === params.inputValue.toLowerCase() ||
          npo.ein.toString().replaceAll("-", "") === params.inputValue.replaceAll("-", "")
        ))) {
          const npoByEin = searchedNpos.find(n => n.ein.toString().replaceAll("-", "") === params.inputValue.replaceAll("-", ""))
          filtered.unshift(npoByEin ?? newModel(params.inputValue))
        }
        // track the filtered option in case someone hits tab
        filteredRef.current = filtered.length > 0 ? filtered[0] : null
        return filtered
      }}
      onKeyDown={(event) => {
        // ignore non-Tab keys
        if (event.key !== 'Tab') return

        // if something got filtered, then use it
        if (filteredRef.current) {
          handleNpoChange(event, filteredRef.current, "selectOption")
        } else {
          // otherwise don't tab away, the field is required
          event.preventDefault()
        }
      }}
    />
  )
}

