import { useEffect } from 'react'
import { HeaderDetails, useLayout } from '../../hooks/useLayout'
export default function Header({ primaryHeader, subheader, withDivider = false, breadCrumbLink, breadCrumbLinks = []}: HeaderDetails) {
  const { setPrimaryHeader, setSubheader, setWithDivider } = useLayout()

  const breadCrumbs = breadCrumbLink ? [breadCrumbLink, ...breadCrumbLinks] : breadCrumbLinks
  const headerWithBreadcrumb = <>{breadCrumbs.map(b => <>{b} &gt; </>)}{primaryHeader}</>


  useEffect(() => {
    setPrimaryHeader(headerWithBreadcrumb)
    setSubheader(subheader)
    setWithDivider(withDivider)

    return () => {
      setPrimaryHeader(undefined)
      setSubheader(undefined)
      setWithDivider(false)
    }
  }, [primaryHeader, subheader, withDivider, breadCrumbLink])

  return null
}
