import {
  DonationDisbursementBatchResponseModelI,
  DonationDisbursementBatchStatus,
} from "@fieldday/fielddayportal-model"
import CopyIcon from "@mui/icons-material/ContentCopy"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { useEffect } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import PopperButtonGroup from "../../../components/Global/PopperButtonGroup"
import { TableRowExpandable } from "../../../components/Global/TableRowExpandable"
import { AlertSeverity, useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import { dayjsTz, shortDate } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents } from "../../../util/stringUtil"
import { useAPI } from "../../../util/useAPI"
import { disbursementBatchId, disbursementTotal, disbursementTotalFormatted } from "./DonationDisbursementUtils"
import GetDisbursementCsvButton from "./GetDisbursementCsvButton"
import NonProfitDisbursementDetails from "./NonProfitDisbursementDetails"

export default function DonationDisbursementsConfirmed({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [disbursementBatches, setDisbursementBatches] = useReadonlyState<DonationDisbursementBatchResponseModelI[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useReadonlyState<NPO[] | null>(null)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.listDonationDisbursementBatches([DonationDisbursementBatchStatus.Confirmed], abortController).then((res) => {
      setDisbursementBatches(res.data.disbursementBatches)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    })
    .finally(loadEnd)

    return () => {
      abortController.abort()
    }
  }, [refreshCounter])

  return (<>
    {(disbursementBatches && disbursementBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No sent disbursements"
        description="No disbursements awaiting confirmation"
        iconSrc={CTAIconSrc.EventsCalendar}
      />
      : <Grid container spacing={2} mb={4} pl={2} pr={29}>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Last updated</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Total</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>ACH transfer id</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Batch id</Typography>
        </Grid>
      </Grid>
    }

    {disbursementBatches?.map(batch => {
      return (
        <DonationDisbursementSummary
          key={batch.id}
          batch={batch}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
    })}
  </>)
}

function DonationDisbursementSummary({ batch, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationDisbursementBatchResponseModelI,
  nonProfitOrgs: Readonly<NPO[]>,
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()

  const { loadStart, setAlert } = useLoading()

  const [expanded, setExpanded] = useReadonlyState(false)

  return (<>
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography sx={{ marginTop: "0.5em" }}>{shortDate(dayjsTz(batch.updatedAt))}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(disbursementTotal(batch))
                  setAlert(AlertSeverity.SUCCESS, `Copied ${disbursementTotal(batch)} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>
              {disbursementTotalFormatted(batch)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              {batch.achTransferId && <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(batch.achTransferId ?? "")
                  setAlert(AlertSeverity.SUCCESS, `Copied ${batch.achTransferId} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>}
              {batch.achTransferId}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(disbursementBatchId(batch))
                  setAlert(AlertSeverity.SUCCESS, `Copied ${disbursementBatchId(batch)} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>
              {disbursementBatchId(batch)}
            </Typography>
          </Grid>
        </Grid>
        <PopperButtonGroup
          sx={{ mr: "1em", height: "2.5em" }}
          defaultColor="primary"
          setTopMargin="0em"
          primaryButton={
            <Button
              variant="outlined"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                loadStart(true)
                FieldDayAPI.updateDonationDisbursementBatch(batch.id, {
                  status: DonationDisbursementBatchStatus.Sent,
                }).then(triggerRefresh)
              }}
            >Unconfirmed</Button>
          }
        >
          <GetDisbursementCsvButton batch={batch} nonProfitOrgs={nonProfitOrgs} />
        </PopperButtonGroup>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell sx={{ fontWeight: "bold" }}>Nonprofit name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Sequence number</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Disbursement amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batch.disbursementNonProfitOrgBatches.map((npoBatch) => {
              const npo = nonProfitOrgs.find(npo => npo.id === npoBatch.nonProfitOrgId)

              return (npo &&
                <TableRowExpandable key={npo.id} expandComponent={
                  <TableCell colSpan={4}>
                    <NonProfitDisbursementDetails
                      matchableDonations={npoBatch.matchableDonations ?? []}
                      platformDonations={npoBatch.platformDonations ?? []}
                    />
                  </TableCell>
                }>
                  <TableCell>{npo?.name}</TableCell>
                  <TableCell>{npoBatch.sequenceNumber}</TableCell>
                  <TableCell>{dollarCents(npoBatch.disbursementAmountCents)}</TableCell>
                </TableRowExpandable>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  </>)
}
