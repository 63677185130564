import { createContext, useContext, useEffect, useState } from "react"
import { useAPI } from "../util/useAPI"
import { AlertSeverity, useLoading } from "./useLoading"
import { urlSafeDecode } from "@aws-amplify/core"
import qs from "qs"
import { guestTokenAttr, teamInviteJoinEventAttr, teamInviteTokenAttr, useAuth } from "./useAuth"
import { useHistory } from "react-router-dom"

interface postAuthQueryState {
  [teamInviteTokenAttr]?: string,
  [teamInviteJoinEventAttr]?: string,
  [guestTokenAttr]?: string,
}

const FederatedStateContext = createContext<FederatedStateForContext | undefined>(undefined)

interface FederatedStateProps {
  children: React.ReactNode
}

interface FederatedStateForContext {
  teamInviteToken?: string
  teamInviteJoinEvent?: boolean
  guestToken?: string
}

export function FederatedSignInStateProvider({ children }: FederatedStateProps) {
  const invite = useProvideFederatedSignInState()

  return (
    <FederatedStateContext.Provider value={invite}>
      {children}
    </FederatedStateContext.Provider>
  )
}

export const useFederatedSignInState = () => {
  const context = useContext(FederatedStateContext)
  if (!context) throw new Error("useFederatedSignInState must be used within a FederatedSignInStateProvider")
  return context
}

function useProvideFederatedSignInState(): FederatedStateForContext {
  const FieldDayAPI = useAPI()
  const { refreshUserProfile } = useAuth()
  const { setAlert } = useLoading()
  const history = useHistory()

  const [teamInviteToken, setTeamInviteToken] = useState<string | undefined>(undefined)
  const [teamInviteJoinEvent, setTeamInviteJoinEvent] = useState<boolean | undefined>(undefined)
  const [guestToken, setGuestToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const queryState = query.state as string | undefined

    // Ignore if there is no state query param.
    if (!queryState) return

    // An example of the state parameter is something like
    // eslint-disable-next-line max-len
    // 2nTuek783kz4uD7XRfVoaDcyJLnPj7lE-7b22637573746f6d3a7465616d496e76697465546f6b656e223a223665767565666e7567353439222c22637573746f6d3a7465616d496e766974654a6f696e4576656e74223a2274727565227d
    // where the part after the [-] is an encoded representation of the custom state.
    // We only care about the custom state here, so split and splice to get that part.
    const queryCustomState = queryState.split('-').splice(1).join('-')

    // Ignore if there is no custom state defined.
    if (!queryCustomState) return

    // Attempt to decode and parse the state.
    const queryCustomStateParsed: postAuthQueryState | undefined = (() => {
      try {
        const queryCustomStateDecoded = urlSafeDecode(queryCustomState)
        return JSON.parse(queryCustomStateDecoded)
      } catch (_) {
        return
      }
    })()

    // Ignore if decoding and parsing failed for some reason.
    if (!queryCustomStateParsed) return

    // Decide if some action needs to be performed depending on the state.
    const teamInviteToken = queryCustomStateParsed[teamInviteTokenAttr]
    const teamInviteJoinEvent = queryCustomStateParsed[teamInviteJoinEventAttr] === "true"
    const guestToken = queryCustomStateParsed[guestTokenAttr]

    if (teamInviteToken || guestToken) {
      // Ensure that the user is logged in and that their profile details are up to date
      // before trying to register them for a team or event.
      refreshUserProfile().then(userInfo => {
        if (teamInviteToken) {
          setTeamInviteToken(teamInviteToken)
          setTeamInviteJoinEvent(teamInviteJoinEvent)
          try {
            FieldDayAPI.createMembershipWithInviteToken(teamInviteToken, teamInviteJoinEvent, `/welcome/${teamInviteToken}`, true).then(result => {
              setAlert(AlertSeverity.SUCCESS, `${result.message}`)
              refreshUserProfile()
            })
          } catch (e) {
            history.push(`/welcome/${teamInviteToken}`)
            setAlert(AlertSeverity.ERROR, "Failed to join the team or event. Please try again.")
          }
        } else if (guestToken) {
          setGuestToken(guestToken)
          try {
            FieldDayAPI.createRegistrationWithGuestToken(guestToken, `/guest/${guestToken}`, true).then(result => {
              setAlert(AlertSeverity.SUCCESS, `${result.message}`)
              refreshUserProfile()
            })
          } catch (e) {
            history.push(`/guest/${guestToken}`)
            setAlert(AlertSeverity.ERROR, "Failed to join the event as a guest. Please try again.")
          }
        }
      })
    }
  }, [])

  return {
    teamInviteToken,
    teamInviteJoinEvent,
    guestToken,
  }
}
