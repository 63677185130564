import { Button, ButtonProps } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

// provides a button that will redirect back to the current route after sign-in
export default function SignInButton({ children, ...rest }: ButtonProps) {
  const locHook = useLocation()
  const buttonProps = Object.assign(rest, {
    variant: "contained",
    component: Link,
    to: { pathname: "/", state: { from: locHook }}
  })

  return (
    <Button {...buttonProps}>
      { children }
    </Button>
  )
}
