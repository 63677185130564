import _, { round } from "lodash"

// change minutes to hours with 2 decimal places
export function roundMinutesToHours(mins?: number): number {
  if (!mins) return 0
  return Math.round((mins / 6) * 10) / 100
}

export function truncateDecimal(decimal: number, places: number): number {
  return _.round(decimal, places)
}

export function matchedPercentage(limit?: number | null, count?: number, inverse?: boolean): number {
  if (!limit || !count) return 0
  const percent = (count / limit) * 100
  if (inverse) return (round((100 - percent), 0))
  return round(percent, 0)
}
