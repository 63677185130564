import { useTheme } from "@mui/material"
import { round } from "lodash"

export default function ProgressBarGraph({ total, count }: {
  total: number, count: number, currency?: boolean }) {
  const percentage = (count / total) * 100;
  const theme = useTheme()
  const fillColor = (() => {
    if (percentage > 50) {
      return theme.palette.primary.main
    } else if (percentage > 25) {
      return theme.palette.warning.main
    }
    return theme.palette.error.main
  })()

  return (
    <svg width="100%" height="40" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="95%" height="30" fill={theme.palette.neutral.light} rx="15" ry="15"
        stroke={fillColor}
        strokeWidth="1"/>
      <rect x="5" y="5" width={`${percentage}%`} height="30" fill={fillColor} rx="15" ry="15"/>

      <text x="15" y="21" fontFamily="Arial" fontSize="14" fill="#DDD" alignmentBaseline="middle" textAnchor="start">
        {count.toLocaleString("en-US")} of {total.toLocaleString("en-US")}: {round(percentage, 0)}%
      </text>
    </svg>
  )
}

export function DonationMatchProgressBarGraph({ matchLimit, approvedMatchedTotal, totalColor }: {
  matchLimit?: number | null, approvedMatchedTotal?: number, matchedTotal?: number, totalColor?: string }) {
    const theme = useTheme()
  if(!matchLimit) return null
  const approvedMatchedPercentage = Math.min(100, ((approvedMatchedTotal ?? 0) / matchLimit) * 100)

  return (
    <svg width="100%" height="36" xmlns="http://www.w3.org/2000/svg">
      <rect y="8" width="99%" height="24" fill={totalColor ?? theme.palette.neutral.light} rx="4" ry="4"
        stroke={theme.palette.divider}
        strokeWidth="1"/>
      <rect y="8" width={`${approvedMatchedPercentage}%`} height="24"
        fill={theme.palette.primary.dark} rx="4" ry="4"/>
    </svg>
  )
}
