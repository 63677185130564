import {
  DonationDisbursementBatchResponseModelI,
  DonationDisbursementBatchStatus,
} from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import CopyIcon from "@mui/icons-material/ContentCopy"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import _ from "lodash"
import { useEffect } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import PopperButtonGroup from "../../../components/Global/PopperButtonGroup"
import { TableRowExpandable } from "../../../components/Global/TableRowExpandable"
import { AlertSeverity, useLoading } from "../../../hooks/useLoading"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import { NPO } from "../../../models/Npo"
import useFormStyles from "../../../styles/formStyles"
import { dayjsTz, isoDate, shortDate, today } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents } from "../../../util/stringUtil"
import { useAPI } from "../../../util/useAPI"
import { disbursementBatchId, disbursementTotal, disbursementTotalFormatted } from "./DonationDisbursementUtils"
import GetDisbursementCsvButton from "./GetDisbursementCsvButton"
import NonProfitDisbursementDetails from "./NonProfitDisbursementDetails"

export default function DonationDisbursementsSent({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [disbursementBatches, setDisbursementBatches] = useReadonlyState<DonationDisbursementBatchResponseModelI[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useReadonlyState<NPO[] | null>(null)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.listDonationDisbursementBatches([DonationDisbursementBatchStatus.Sent], abortController).then((res) => {
      setDisbursementBatches(res.data.disbursementBatches)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    })
    .finally(loadEnd)

    return () => {
      abortController.abort()
    }
  }, [refreshCounter])

  return (<>
    {(disbursementBatches && disbursementBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No sent disbursements"
        description="No disbursements awaiting confirmation"
        iconSrc={CTAIconSrc.EventsCalendar}
      />
      : <Grid container spacing={2} mb={4} pl={2} pr={27}>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Last updated</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Total</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>ACH transfer id</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={"bold"}>Batch id</Typography>
        </Grid>
      </Grid>
    }

    {disbursementBatches?.map(batch => {
      return (
        <DonationDisbursementSummary
          key={batch.id}
          batch={batch}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
    })}
  </>)
}

function DonationDisbursementSummary({ batch, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationDisbursementBatchResponseModelI,
  nonProfitOrgs: Readonly<NPO[]>,
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()
  const formStyles = useFormStyles()

  const { setAlert, loadStart } = useLoading()

  const [expanded, setExpanded] = useReadonlyState(false)

  const [openModal, setOpenModal] = useReadonlyState<"none" | "confirmed">("none")
  function closeModal() {
    setOpenModal("none")
  }

  const defaultDisbursementConfirmedDate = today()
  const [disbursementConfirmedDate, setDisbursementConfirmedDate] = useReadonlyState(defaultDisbursementConfirmedDate)

  return (<>
    <Dialog open={openModal === "confirmed"} fullWidth maxWidth="xs" onClose={closeModal}>
      {(openModal === "confirmed") && <>
        <DialogTitle>
          Set confirmed date
          <IconButton
            aria-label="closeDonationModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal()} }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container>
            <FormControl sx={{ marginY: 4 }} className={formStyles.textInputFullWidth}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={"Disbursement confirmed on"}
                  value={disbursementConfirmedDate}
                  onChange={(newDate: string | null | Dayjs) => {
                    if (newDate) {
                      const dateISO = isoDate(newDate)
                      setDisbursementConfirmedDate(dateISO)
                    } else {
                      setDisbursementConfirmedDate(disbursementConfirmedDate)
                    }
                  }}
                  renderInput={params => <TextField
                    {...params}
                    helperText={"The date that the disbursement was sent"}
                  />}
                />
              </LocalizationProvider>
            </FormControl>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            if (disbursementConfirmedDate === "") {
              setAlert(AlertSeverity.ERROR, "ACH transfer id and date are required to mark as sent")
            } else {
              loadStart(true)
              FieldDayAPI.updateDonationDisbursementBatch(batch.id, {
                status: DonationDisbursementBatchStatus.Confirmed,
                confirmedAt: disbursementConfirmedDate,
              }).then(() => {
                closeModal()
                triggerRefresh()
              })
            }
          }}>Mark as confirmed</Button>
        </DialogActions>
      </>}
    </Dialog>

    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography sx={{ marginTop: "0.5em" }}>{shortDate(dayjsTz(batch.updatedAt))}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(disbursementTotal(batch))
                  setAlert(AlertSeverity.SUCCESS, `Copied ${disbursementTotal(batch)} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>
              {disbursementTotalFormatted(batch)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              {batch.achTransferId && <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(batch.achTransferId ?? "")
                  setAlert(AlertSeverity.SUCCESS, `Copied ${batch.achTransferId} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>}
              {batch.achTransferId}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography noWrap maxWidth={"90%"} display="inline-block">
              <IconButton
                sx={{ float: "" }}
                onClick={(event) => {
                  event?.stopPropagation()
                  event?.preventDefault()
                  navigator.clipboard.writeText(disbursementBatchId(batch))
                  setAlert(AlertSeverity.SUCCESS, `Copied ${disbursementBatchId(batch)} to clipboard`, 2000)
                }}
              >
                <CopyIcon />
              </IconButton>
              {disbursementBatchId(batch)}
            </Typography>
          </Grid>
        </Grid>
        <PopperButtonGroup
          sx={{ mr: "1em", height: "2.5em" }}
          defaultColor="primary"
          setTopMargin="0em"
          primaryButton={
            <Button
              fullWidth
              variant="outlined"
              onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setOpenModal("confirmed")
            }}>Confirmed</Button>
          }
        >
          <Button
            variant="listItem"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              loadStart(true)
              FieldDayAPI.updateDonationDisbursementBatch(batch.id, {
                status: DonationDisbursementBatchStatus.Created
              }).then(triggerRefresh)
            }}
          >Not sent</Button>
          <GetDisbursementCsvButton batch={batch} nonProfitOrgs={nonProfitOrgs} />
        </PopperButtonGroup>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell sx={{ fontWeight: "bold" }}>Nonprofit name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Sequence number</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Disbursement amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.orderBy(batch.disbursementNonProfitOrgBatches, "sequenceNumber").map((npoBatch) => {
              const npo = nonProfitOrgs.find(npo => npo.id === npoBatch.nonProfitOrgId)

              return (npo &&
                <TableRowExpandable key={npo.id} expandComponent={
                  <TableCell colSpan={4}>
                    <NonProfitDisbursementDetails
                      matchableDonations={npoBatch.matchableDonations ?? []}
                      platformDonations={npoBatch.platformDonations ?? []}
                    />
                  </TableCell>
                }>
                  <TableCell>{npo.name}</TableCell>
                  <TableCell>{npoBatch.sequenceNumber}</TableCell>
                  <TableCell>{dollarCents(npoBatch.disbursementAmountCents)}</TableCell>
                </TableRowExpandable>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  </>)
}
