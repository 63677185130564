import * as React from 'react'
import { createContext, useContext, useReducer, useState } from 'react'
import AlertBar from '../components/Global/AlertBar'
import LoadingBar from '../components/Global/LoadingBar'
import { Queue } from '../util/queue'
import { sleep } from '../util/sleep'

enum ActionType {
  LOAD_START    = "LOAD_START",
  LOAD_END      = "LOAD_END",
  SET_ALERT     = "SET_ALERT",
  CLEAR_ALERT   = "CLEAR_ALERT",
}

export enum AlertSeverity {
  SUCCESS = "success",
  ERROR   = "error",
  INFO    = "info",
  WARNING = "warning",
}

type ContextProps = {
  children: React.ReactNode
}

type LoadingSetters = {
  loadStart:    (blocking?: boolean)                                          => void
  loadEnd:      ()                                                            => void
  setAlert:     (severity: AlertSeverity, message: string, duration?: number) => void
  clearAlert:   ()                                                            => void
}

const LoadingContext = createContext<LoadingSetters | undefined>(undefined)

export function LoadingProvider({ children }:ContextProps) {
  const [loadingState, dispatch] = useReducer(reducer, { loading: false, alertQueue: new Queue<AlertForQueue>() })

  const loadStart  = (blocking: boolean = false) => dispatch({ type: ActionType.LOAD_START, blocking                    })
  const loadEnd    = ()                          => dispatch({ type: ActionType.LOAD_END                                })
  const clearAlert = ()                          => dispatch({ type: ActionType.CLEAR_ALERT                             })
  const setAlert   = (severity: AlertSeverity, message: string, duration?: number) => {
    dispatch({ type: ActionType.SET_ALERT,  alert: { severity, message }})
    if (severity === AlertSeverity.INFO) duration = duration ?? 3000
    if (duration) sleep(duration).then(clearAlert)
  }

  const [loadingSetters, _setLoadingSetters] = useState<LoadingSetters>({ loadStart, loadEnd, setAlert, clearAlert })

  return (
    <LoadingContext.Provider value={loadingSetters}>
      <LoadingBar loadingState={loadingState} />
      <AlertBar loadingState={loadingState} />
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) throw new Error('useLoading must be used within a LoadingProvider');
  return context
}

export type LoadingAlertState = {
  loading: boolean;
  alertQueue: Queue<AlertForQueue>
  blocking?: boolean;
}

export type AlertForQueue = {
  severity: AlertSeverity
  message: string
}

type Action =
  | { type: ActionType.LOAD_START,  blocking: boolean }
  | { type: ActionType.LOAD_END,                      }
  | { type: ActionType.SET_ALERT,   alert: AlertForQueue      }
  | { type: ActionType.CLEAR_ALERT,                   }

const reducer = (state: LoadingAlertState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_START:
      return { ...state, loading: true, blocking: action.blocking };
    case ActionType.LOAD_END:
      return { ...state, loading: false };
    case ActionType.SET_ALERT:
      // enqueue unless the exact message is at the top of the queue already
      if (action.alert.message !== state.alertQueue.peek()?.message) state.alertQueue.enqueue(action.alert)
      return { ...state }
    case ActionType.CLEAR_ALERT:
      state.alertQueue.dequeue()
      return { ...state }
  }
}
