import { MatchableDonationModelResponse, PlatformDonationModelResponse } from "@fieldday/fielddayportal-model"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import dayjs from "dayjs"
import _ from "lodash"
import { dayjsTz, shortDate } from "../../../util/dateUtil"
import { dollarCents } from "../../../util/stringUtil"

export default function NonProfitDisbursementDetails({ matchableDonations, platformDonations }: {
  matchableDonations: MatchableDonationModelResponse[],
  platformDonations: PlatformDonationModelResponse[],
}) {
  return (<TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Fees</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {platformDonations.length > 0 && <TableRow><Typography variant="caption">Donations</Typography></TableRow>}
        {_.orderBy(platformDonations, "createdAt").map(donation => {
          return (<TableRow key={`pd-${donation.nonProfitOrgId}`}>
            <TableCell>{shortDate(dayjsTz(donation.createdAt))}</TableCell>
            <TableCell>{dollarCents(donation.receivedAmountCents)}</TableCell>
            <TableCell>({dollarCents(donation.feeAmountCents)})</TableCell>
          </TableRow>)
        })}
        {matchableDonations.length > 0 && <TableRow><Typography variant="caption">Matches</Typography></TableRow>}
        {_.orderBy(matchableDonations, "matchedDateISO").map(donation => {
          return (<TableRow key={`md-${donation.nonProfitOrgId}`}>
            <TableCell>{shortDate(dayjs(donation.matchedDateISO))}</TableCell>
            <TableCell>{dollarCents(donation.matchedAmount)}</TableCell>
            <TableCell>{dollarCents(donation.feesMatchedAmount)}</TableCell>
          </TableRow>)
        })}
      </TableBody>
    </Table>
  </TableContainer>)
}
