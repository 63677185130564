import * as Sentry from "@sentry/react"
import { createRoot } from 'react-dom/client'

import "@fontsource/inter/variable-full.css"
import "@fontsource/lora/variable-italic.css"
import "@fontsource/lora/variable.css"
import '@fontsource/roboto'
import "@fontsource/roboto-condensed"
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import './index.css'
import theme from './styles/theme'

import { BrowserRouter as Router } from "react-router-dom"
import App from './App'
import ScrollToTop from './components/Global/ScrollToTop'

import { AuthProvider } from './hooks/useAuth'
import { LoadingProvider } from './hooks/useLoading'

import { Auth } from "@aws-amplify/auth"
import { AWS_AUTH_CONFIG } from './config/aws-auth'
import { ErrorMessagesProvider } from './hooks/errorMessagesContext'
import { FederatedSignInStateProvider } from './hooks/useFederatedSignInState'
import { RegionProvider } from './hooks/useRegion'

if (import.meta.env.REACT_APP_SENTRY_DSN !== "disabled") {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    // setting this > 0 will sample for performance
    tracesSampleRate: parseFloat(import.meta.env.REACT_APP_SENTRY_SR || "0.1"),
    ignoreErrors: [
      // Requests are canceled during the course of normal application operation.
      'Request canceled with message "canceled"',
      // Reported as benign by one of the specification authors
      // https://stackoverflow.com/a/50387233
      'ResizeObserver loop limit exceeded',
      // Firefox version of the same error above (we have not yet seen this error)
      'ResizeObserver loop completed with undelivered notifications.',
      // Error from amplify axios client when XHR response is 0 (network interruption)
      'timeout of 0ms exceeded',
      // XHR response is 0 (network interruption)
      'Network Error',
      // Errors from chrome extensions are not useful.
      'chrome-extension',
    ]
  })
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

Auth.configure(AWS_AUTH_CONFIG)

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Router>
        <LoadingProvider>
          <AuthProvider>
            <FederatedSignInStateProvider>
              <ErrorMessagesProvider>
                <RegionProvider>
                  <ScrollToTop />
                  <App />
                </RegionProvider>
              </ErrorMessagesProvider>
            </FederatedSignInStateProvider>
          </AuthProvider>
        </LoadingProvider>
      </Router>
    </ThemeProvider>
  </StyledEngineProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
