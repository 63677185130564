import { useEffect, useLayoutEffect, useRef } from 'react'

export type callbackFunc = () => void
export type intervalDelay = number | null

function useInterval(callback: callbackFunc, delayInSeconds: intervalDelay) {
  const savedCallback = useRef(callback)

  // After every render, save the latest callback into the ref.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if delay is specifically set to null (0 is a valid delay)
    if (delayInSeconds === null) {
      console.log("No delay specified so pausing interval")
      return
    }

    const delayMs = delayInSeconds * 1000
    const id = setInterval(() => savedCallback.current(), delayMs)
    console.log(`Set interval with ID ${id} to ${delayMs}`)

    return () => {
      console.log(`Clearing interval with ID ${id}`)
      clearInterval(id)
    }
  }, [delayInSeconds])
}

export default useInterval

// Explanation of the challenges of using setInterval in React components from Dan Abramov (Redux creator)
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
