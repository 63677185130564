import { Container, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import useStyles from "../../styles/useStyles";

export default function Unauthorized({ npoId }: { npoId?: string }) {
  const styles = useStyles()
  const browseActivitiesUrl = npoId ? `/activities?npoId=${npoId}` : '/activities'
  return (
    <main>
    <Container maxWidth="sm" className={styles.heroContent}>
    <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom mt={4}>
      Oops, looks like this activity is no longer available.
    </Typography>
    <Typography variant="h5" align="center" color="textSecondary" style={ { margin: "50px" } }>
       <Link to={browseActivitiesUrl}>Browse more activities</Link>
    </Typography>
  </Container>
</main>
  )
}
