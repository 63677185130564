import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { IconButton, TableCell, TableRow } from "@mui/material"
import { ReactNode } from "react"
import { useReadonlyState } from "../../hooks/useReadonlyState"

export function TableRowExpandable({ children, expandComponent }: { children: ReactNode, expandComponent: ReactNode }) {
  const [expanded, setExpanded] = useReadonlyState(false)

  return (
    <>
      <TableRow
        onClick={() => { setExpanded(!expanded) }}
        sx={{ input: { cursor: "pointer" }}}
      >
        <TableCell padding="checkbox">
          <IconButton>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  )
}
