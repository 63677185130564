import { Container, Typography } from "@mui/material"
import { Helmet } from "react-helmet"
import Header from "../../../components/Global/Header"
import BackToWizardLink, { BackToWizardDonationLink } from "../../../components/Wizard/BackToWizardLink"
import { useReadonlyState } from "../../../hooks/useReadonlyState"
import DonationDisbursementPreview from "./DonationDisbursementPreview"
import DonationDisbursementsConfirmed from "./DonationDisbursementsConfirmed"
import DonationDisbursementsCreated from "./DonationDisbursementsCreated"
import DonationDisbursementsSent from "./DonationDisbursementsSent"

export default function DonationDisbursement() {
  const [refreshCounter, setRefreshCounter] = useReadonlyState(0)
  function triggerRefresh() {
    setRefreshCounter(refreshCounter + 1)
  }

  return (<main>
    <Helmet title="Wizard: Donation dibursement" />
    <Header primaryHeader="Disbursement" breadCrumbLink={<BackToWizardLink />} breadCrumbLinks={[<BackToWizardDonationLink key="Donation"/>]} />
    <Container maxWidth="lg" style={{ marginTop: "1em", marginBottom: "2em" }}>
      <DonationDisbursementPreview refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />

      <Typography pt={"2em"} variant="h5">Awaiting send</Typography>
      <DonationDisbursementsCreated refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />

      <Typography pt={"2em"} variant="h5">Awaiting confirmation</Typography>
      <DonationDisbursementsSent refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />

      <Typography pt={"2em"} variant="h5">Completed</Typography>
      <DonationDisbursementsConfirmed refreshCounter={refreshCounter} triggerRefresh={triggerRefresh} />
    </Container>
  </main>)
}
