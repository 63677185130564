import { Typography } from "@mui/material"
import React from "react"
import { Route, RouteProps } from "react-router-dom"
import { AlertForQueue } from "../../hooks/useLoading"
import { Queue } from "../../util/queue"
import LoadingBar from "./LoadingBar"

// a version of Route that uses React Suspense to lazy load the component inside; shows a fallback while loading
// note: you can import this as Route to replace existing routes
export default function SuspenseRoute(props: RouteSuspenseProps) {
  const { children, ...rest } = props
  return (
    <Route {...rest}>
      <React.Suspense fallback={ <LoadingFallback /> }>
        {children}
      </React.Suspense>
    </Route>
  )
}

interface RouteSuspenseProps extends RouteProps {
  children: React.ReactNode // force to be present
}

function LoadingFallback() {
  return <>
    <LoadingBar loadingState={{ blocking: false, loading: true, alertQueue: new Queue<AlertForQueue> }} />
    <Typography variant="subhead2B" padding={4}><em>Loading ...</em></Typography>
  </>
}
