import { DonationStatus, MatchApprovalStatus, MatchableDonationModelResponse, PlatformDonationStatus } from "@fieldday/fielddayportal-model"

export enum DonationUiStatus {
  PaymentPending = "Payment pending",
  PaymentFailed = "Payment failed",
  Made = "Donation made",
  Reported = "Donation reported",
  Approved = "Match approved",
  Declined = "Match declined",
  Matched = "Matched",
  Completed = "Completed",
  Batched = "Invoicing",
  HoursCompleted = "Hours completed"
}

export function getDonationUiStatus(donation: MatchableDonationModelResponse, adminView?: boolean) {
  const platformDonation = donation.platformDonations && donation.platformDonations[0]

  if (platformDonation && platformDonation.status === PlatformDonationStatus.Created) {
    return DonationUiStatus.PaymentPending
  }
  if (platformDonation && platformDonation.status === PlatformDonationStatus.Failed) {
    return DonationUiStatus.PaymentFailed
  }
  if ((platformDonation && platformDonation.status === PlatformDonationStatus.Paid) && donation.approvalStatus === MatchApprovalStatus.Pending) {
    return DonationUiStatus.Made
  }
  if (donation.minutesVolunteered && donation.approvalStatus === MatchApprovalStatus.Pending) {
    return DonationUiStatus.HoursCompleted
  }
  if (!platformDonation && donation.approvalStatus === MatchApprovalStatus.Pending) {
    return DonationUiStatus.Reported
  }
  if (adminView) {
    if (donation.approvalStatus === MatchApprovalStatus.Approved) {
      if (donation.status === DonationStatus.Batched) {
        return DonationUiStatus.Batched
      }
      if (donation.status === DonationStatus.Reported) {
        return DonationUiStatus.Approved
      }
    }
  } else {
    if (donation.approvalStatus === MatchApprovalStatus.Approved && [DonationStatus.Reported, DonationStatus.Batched].includes(donation.status)) {
      return DonationUiStatus.Approved
    }
  }
  if (donation.approvalStatus && [MatchApprovalStatus.Rejected, MatchApprovalStatus.Canceled].includes(donation.approvalStatus)) {
    if (platformDonation && platformDonation.status === PlatformDonationStatus.Disbursed) {
      // For the purposes of UI statuses, direct donations that are rejected for matching and are disbursed are considered "Completed"
      return DonationUiStatus.Completed
    }
    return DonationUiStatus.Declined
  }
  if (donation.approvalStatus === MatchApprovalStatus.Approved && donation.status === DonationStatus.Matched) {
    return DonationUiStatus.Matched
  }

  if (donation.approvalStatus === MatchApprovalStatus.Approved && donation.status === DonationStatus.Completed) {
    return DonationUiStatus.Completed
  }
}

export function matchPolicyLimitAmountPretty(amount?: number | null): string {
  if (amount == null) return "No limit"
  return `$${amount.toLocaleString()}`
}
