import makeStyles from '@mui/styles/makeStyles';

const useFooterStyles = makeStyles((theme) => ({
  footer: {
    width: "90%",
    margin: "auto",
    paddingTop: "2em",
    paddingBottom: "2em",
    marginBottom: "2.5rem",
    color: theme.palette.neutral.main,
    alignContent: "center",
    '& a:any-link': {
      color: theme.palette.neutral.main,
      fontWeight: "normal",
    },
    borderTop: `1px solid ${theme.palette.divider}`
  },
}))

export default useFooterStyles
