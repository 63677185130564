import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

const useFormStyles = makeStyles((theme) => ({
  disabled: {},
  button: {
    margin: theme.spacing(2, 1, 2, 0)
  },
  hiddenTextInput: {
    display: "none",
  },
  profTextInput: {
    margin: 0,
    width: '49%',
  },
  stateTexfield: {
    margin: 0,
  },
  stateAutocomplete: {
    width: '100%'
  },
  regionSelector: {
    width: '100%',
    margin: theme.spacing(2, 0, 2, 0),
  },
  inlineRegionSelector: {
    width: '100%',
  },
  textInput: {
    margin: theme.spacing(4, 2, 0, 0),
    width: "45%",
    maxWidth: theme.spacing(32),
  },
  textInputWide: {
    margin: theme.spacing(2, 0, 0, 0),
    width: "100%",
    // maxWidth: theme.spacing(32),
  },
  textInputFullWidth: {
    margin: theme.spacing(2, 0, 0, 0),
    width: "100%",
    '&.collapsed': {
      margin: theme.spacing(0),
    },
  },
  textInputInDialog: {
    margin: theme.spacing(0, 0, 0, 0),
    width: "100%",
  },
  orgTextInput: {
    margin: theme.spacing(2, 2, 2, 0),
    width: "100%",
  },
  selectMenu: {
    maxHeight: "200px",
    marginTop: "5px",
  },
  selectMenuDisabled: {
    color: theme.palette.text.disabled,
  },
  selectIcon: {
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  locationForm: {
    '& $textInput': {
      width: "100%",
      maxWidth: "100%",
      margin: theme.spacing(1, 0, 0),
    },
  },
  detailsForm: {
    '& $textInput': {
      width: "100%",
      maxWidth: "100%",
      margin: theme.spacing(1, 0, 2)
    },
  },
  textArea: {
    width: theme.spacing(70),
    maxWidth: "100%",
    margin: theme.spacing(2, 0, 0),
    resize: "none",
    '&.autoWidth': {
      width: 'auto'
    },
  },
  textField: {
    width: '100%',
    margin: theme.spacing(2, 0, 0),
    resize: "none",
    '&.collapsed': {
      margin: theme.spacing(0),
    },
  },
  select: {
    width: '100%',
    margin: theme.spacing(2, 0, 0),
    '&.inAlert': {
      margin: theme.spacing(1, 0, 0),
    },
  },
  popoverSelect: {
    width: '100%',
  },
  formLabel: {
    fontSize: "0.675em",
    textTransform: "uppercase"
  },
  imageInput: {
    width: "100%",
    height: theme.spacing(13),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    overflow: "hidden",
    color: `rgba(0, 0, 0, 0.23)`,
    '& $imageIconButton': {
      color: `rgba(0, 0, 0, 0.23)`,
    },
    '&:hover': {
      borderColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary,
      '& $imageIconButton': {
        color: theme.palette.text.secondary,
      },
    },
    '&.dragover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& $imageIconButton': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main,
      },
    },
    '&$disabled': {
      borderColor: theme.palette.action.disabled,
      color: theme.palette.action.disabled,
      cursor: "default",
      '& $imageIconButton': {
        backgroundColor: "transparent",
        color: theme.palette.action.disabled,
        cursor: "default"
      },
      '&:hover $imageIconButton': {
        color: theme.palette.action.disabled,
      },
    },
  },
  imageInputLoading: {
    width: "100%",
    height: theme.spacing(13),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: "5px",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  imagePreview: {
    maxWidth: '300px',
    maxHeight: '300px',
    overflow: "hidden",
  },
  dropZone: {
    position: "relative",
    '&.dragover': {
    },
    '&.dragover::after, &.dragover-disabled::after': {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.neutral.dark,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(256, 256, 256, 0.85)",
    },
    '&.dragover::after': {
      content: "'Drop image here to upload'",
    },
    '&.dragover-disabled::after': {
      content: "'You can add images to complete your profile after you create your page'",
    },
  },
  imageIconButton: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  googleSignInButton: {
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: "roboto",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    '& .MuiButton-startIcon': {
      display: "flex",
    },
    marginTop: theme.spacing(2),
  },
  commentTextField: {
    backgroundColor: theme.palette.neutral.light,
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px'
    },
  },
  borderlessOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px',
    },
    '&.Mui-focused': {
      borderWidth: '0px',
      outline: "none",
    },
    '&:focus-visible': {
      outline: "none",
    }
  },
  checkboxArea: {
    width: '100%',
  },
  checkboxDescripBox: {
    borderColor: theme.palette.text.secondary,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '&.checked': {
      borderColor: theme.palette.primary.main,
    },
  },
  alertBox: {
    width: '100%',
    margin: theme.spacing(2, 0, 0, 0),
    '& .MuiAlert-message': {
      width: '100%'
    }
  },
}));

export default useFormStyles
