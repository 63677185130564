import { useEffect, useState } from "react"

export function useScrollToError() {
  const [scrollToError, setScrollToError] = useState(false)
  useEffect(() => {
    if (scrollToError) {
      const section = document.querySelector( ".Mui-error" )
      section?.scrollIntoView( { behavior: "smooth", block: "center" } )
      setScrollToError(false)
    }
  }, [scrollToError])

  // Return a control to enable error scrolling one time
  // (e.g. after a form submit).
  return () => { setScrollToError(true) }
}
