import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import * as React from "react";
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { FieldDayFlagSvgIcon } from '../../../util/icons';
import { tabLabel } from '../../OrgCommon/DashboardTabLabel';
import { useTabStyles } from '../../OrgCommon/TabHelpers';

const NavTabsForUser = React.lazy(() => import('./NavTabsForUser'))
const NavLinksForUser = React.lazy(() => import('./NavLinksForUser'))

export default function NavColumnContent() {
  const tabStyles = useTabStyles()
  const { user } = useAuth()
  const { pathname } = useLocation()
  const pathMatch = (path: string | string[], exact?: boolean): boolean => !!matchPath(pathname, { path: path, exact });
  const pathMatchesUserNonprofit = user?.nonprofitMemberships.some(npm => pathMatch(`/nonprofits/${npm.nonProfitOrg.handle}`))
  const noMemberships = _.isEmpty(user?.orgUnitMemberships) && _.isEmpty(user?.nonprofitMemberships)

  const tabsValue = (() => {
    if (pathMatch(["/activities", "/nonprofits/:handle/activities"])) return "activities"
    if (pathMatch("/events")) return "events"
    if (pathMatch("/nonprofits") && !pathMatch("/nonprofits/:handle/activities") && !pathMatchesUserNonprofit) return "nonprofits"
    if (pathMatch(["/teams/create"])) return "createTeam"
    return false
  })()

  const tabsProps = {
    className: tabStyles.sideNavTabs,
    orientation: "vertical" as const,
    sx: { flexShrink: 0 },
  }

  return <>
    <Link to="/" style={{ marginBottom: "4.25rem" }}>
      <img height="50px" width="92px" src="/images/FieldDay_BlackLogo.webp" alt="Field Day Logo" title="Field Day" />
    </Link>
    <Stack direction="column" justifyContent="space-between" height="100%">
      <Stack>
        {(user && !noMemberships) &&
          <>
            <React.Suspense>
              <NavTabsForUser tabsProps={tabsProps} />
            </React.Suspense>
            <Divider sx={{ mt: 2, mb: 4.5 }} />
          </>
        }
        <Tabs {...tabsProps} value={tabsValue}>
          {!noMemberships &&
            <Tab
              label={tabLabel(FieldDayFlagSvgIcon, "Plan a Field Trip")}
              component={Link}
              to="/activities"
              value='activities'
            />
          }
          <Tab
            label={tabLabel(EventOutlinedIcon, "Volunteer")}
            component={Link}
            to="/events"
            value='events'
          />
          <Tab
            label={tabLabel(VolunteerActivismOutlinedIcon, "Explore nonprofits")}
            component={Link}
            to="/nonprofits"
            value='nonprofits'
          />
          {noMemberships &&
            <Tab
              label={tabLabel(FieldDayFlagSvgIcon, "Create a Team")}
              component={Link}
              to="/teams/create"
              value='createATeam'
            />
          }
        </Tabs>
      </Stack>
      {user &&
        <React.Suspense>
          <NavLinksForUser />
        </React.Suspense>
      }
    </Stack>
  </>
}
