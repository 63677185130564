import { DonationDisbursementBatchResponseModelI } from "@fieldday/fielddayportal-model"
import { today } from "../../../util/dateUtil"
import { dollarCents, formattedCents } from "../../../util/stringUtil"

export function disbursementBatchId(donationDisbursementBatch: DonationDisbursementBatchResponseModelI): string {
  return `${today()} ending ${donationDisbursementBatch.endAt} ${donationDisbursementBatch.batchId}`
}

export function disbursementTotal(donationDisbursementBatch: DonationDisbursementBatchResponseModelI): string {
  return formattedCents(disbursementCents(donationDisbursementBatch))
}

export function disbursementTotalFormatted(donationDisbursementBatch: DonationDisbursementBatchResponseModelI): string {
  return dollarCents(disbursementCents(donationDisbursementBatch))
}

function disbursementCents(donationDisbursementBatch: DonationDisbursementBatchResponseModelI): number {
  const total = donationDisbursementBatch.disbursementNonProfitOrgBatches.reduce((total, npoBatch) => {
    return total + (npoBatch.disbursementAmountCents ?? 0)
  }, 0)
  return total
}
