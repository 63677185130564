import { InputAdornment, SxProps, TextField } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { ReactNode, useEffect, useState } from "react"
import { formattedCents, formattedDollars } from "../../util/stringUtil"

interface props {
  id:     string
  name:   string
  label?:  string
  onChange: (newVal?: number) => void
  disabled?: boolean
  value?: number
  minimum?: number
  error?: string | null
  helpText?: string | ReactNode
  sx?: SxProps<Theme>
}

export function CurrencyTextFieldCents(props: props) {
  const { id, name, disabled, value, minimum, label, onChange, error, helpText, sx } = props

  const [stringValue, setStringValue] = useState<string>(value ? formattedCents(value) : "")

  // Set the string value in this component based on the number value
  // passed in as props from the parent component.
  useEffect(() => {
    const sanValue = minimum ? Math.max(minimum, value ?? 0) : value
    setStringValue(sanValue ? formattedCents(sanValue) : "")
  }, [value])

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const eventVal = event.target.value
    const minStr = minimum ? `${minimum / 100}` : "0"
    const formattedVal = isNaN(parseFloat(eventVal)) ? minStr : eventVal.replaceAll(",", "")

    // Decimal multiplication in javascript is frequently wrong (e.g. 10.20 * 100)
    // so rather than trying to convert a dollar value to cents via math,
    // we do it with string parsing. This will probably break down if we need
    // to start handling currencies other than US dollars.
    const [wholePart, decimalPart] = formattedVal.split(".")
    const dollarValue = Math.abs(wholePart ? parseInt(wholePart) : 0)

    // Get the first two characters of the decimal part of the number.
    const truncatedCents = decimalPart ? decimalPart.substring(0,2) : "0"
    const centsNumber = Math.abs(parseInt(truncatedCents))

    // onChange is passed in by the parent component and should
    // expect an integer value in cents to be passed in as the parameter.
    const centVal = (dollarValue * 100) + centsNumber
    onChange(!minimum || centVal >= minimum ? centVal : minimum)

    // If the value prop does not change even though there is a change
    // (e.g. the value is 1000.00 and the user inputs 1,000) the useEffect
    // will not be triggered, so update the string value directly.
    // The useEffect is still necessary to handle when value changes
    // without being updated from here.
    const sanValue = minimum ? Math.max(minimum, centVal ?? 0) : value
    if (value === sanValue) setStringValue(sanValue ? formattedCents(value) : "")
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStringValue(event.target.value)
  }

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      value={stringValue}
      type="string"
      InputProps={{
        startAdornment: <InputAdornment position="start">{`$`}</InputAdornment>,
      }}
      inputProps={{
        style: { textAlign: "right" },
      }}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder="0.00"
      helperText={error ?? helpText ?? " "}
      error={!!error}
      sx={sx}
      disabled={disabled}
    />
  )
}

export function CurrencyTextFieldDollars(props: props) {
  const { id, name, disabled, value, label, onChange, error, helpText, sx } = props

  const [stringValue, setStringValue] = useState<string>(value ? formattedDollars(value) : "")

  // Set the string value in this component based on the number value
  // passed in as props from the parent component.
  useEffect(() => {
    setStringValue(value ? formattedDollars(value) : "")
  }, [value])

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const eventVal = event.target.value
    const formattedVal = isNaN(parseFloat(eventVal)) ? "0" : eventVal.replaceAll(",", "")

    // This is just dollars, but someone might put in cents. So truncate the cents.
    const [wholePart] = formattedVal.split(".")
    const dollarValue = Math.abs(wholePart ? parseInt(wholePart) : 0)

    // onChange is passed in by the parent component and should
    // expect an integer value in dollars to be passed in as the parameter.
    onChange(dollarValue)

    // If the value prop does not change even though there is a change
    // (e.g. the value is 1000 and the user inputs 1,000) the useEffect
    // will not be triggered, so update the string value directly.
    // The useEffect is still necessary to handle when value changes
    // without being updated from here.
    if (value === dollarValue) setStringValue(value ? formattedDollars(value) : "")
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStringValue(event.target.value)
  }

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      value={stringValue}
      type="string"
      InputProps={{
        startAdornment: <InputAdornment position="start">{`$`}</InputAdornment>,
      }}
      inputProps={{
        style: { textAlign: "right" },
      }}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder="0"
      helperText={error ?? helpText ?? " "}
      error={!!error}
      sx={sx}
      disabled={disabled}
    />
  )
}
