import { MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import { Divider, Grid, List, Typography, useTheme } from "@mui/material"
import { NPO } from "../../models/Npo"
import { UserInfo } from "../../models/UserInfo"
import MatchableDonationListItem from "./MatchableDonationListItem"
import { OpenDonationModal } from "./MatchableDonationModal"

interface props {
  matchableDonations: MatchableDonationModelResponse[] | null
  donationUsers: Map<string, UserInfo>,
  donationNpos: Map<string, NPO>,
  adminView: boolean,
  setCurrentDonation: (value: MatchableDonationModelResponse | null) => void,
  setOpenModal: (openModal: OpenDonationModal) => void
}

export default function MatchableDonationsList(props: props) {
  const {
    matchableDonations,
    donationUsers,
    donationNpos,
    adminView,
    setCurrentDonation,
    setOpenModal
  } = props

  const theme = useTheme()

  const listHeaders = [{
    title: "Donation date",
    columns: 1.75
  },
  {
    title: adminView ? "Details" : "Nonprofit",
    columns: 3.5
  },
  {
    title: "Donation amount",
    columns: 1.5
  },
  {
    title: "Match amount",
    columns: 1.5
  },
  {
    title: "Status",
    columns: 2
  }]

  return (<>
    {(matchableDonations && matchableDonations.length > 0) &&
      <Grid container columnSpacing={2} mt={3} minWidth={"630px"}>
        {listHeaders.map((header, i) => (
          <Grid item key={i} xs={header.columns}>
            <Typography variant="label3" color={theme.palette.text.secondary}>
              {header.title}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={12} mt={1}>
          <Divider />
        </Grid>
      </Grid>
    }
    {(matchableDonations && matchableDonations.length > 0) && <List sx={{ py: 0 }}>
      { matchableDonations.map((donation) => {
        const donationUser = donationUsers.get(donation.userId)
        const donationNpo = donationNpos.get(donation.nonProfitOrgId)
        // not possible for these to be missing
        if (!donationUser || !donationNpo) return null

        return (
          <MatchableDonationListItem
            key={donation.id}
            adminView={adminView}
            donation={donation}
            user={donationUser}
            nonProfitOrg={donationNpo}
            selectDonation={() => {setCurrentDonation(donation)}}
            setOpenModal={setOpenModal}
          />)
      })}
    </List>}
  </>)
}
