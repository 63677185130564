import { MetroRegion, ScheduledActivityRegistrationRestriction } from "@fieldday/fielddayportal-model"
import { ScheduledActivityForView } from "../models/Activity"
import { usaStates } from "./usaStates"

// Turn "AllDay" into "All Day"
export function spaceWords(input: string): string {
  // special case to make it not say "website U R L" - can improve this later, this whole thing is a hack
  return input.replace("URL", "")
    .replace("ID", "")
    .replace(/([A-Z])/g, ' $1').trim()
}

// Split a list of emails from a single string into a list of email strings
// delimited by comma, space, newline, carriage return, tab, or any combination of those.
export function splitEmails(emails: string): string[] {
  return emails.split(/[, \n\r\t]+/).filter(s => s.includes("@"))
}

export function domainFromEmail(email: string): string | undefined {
  const emailDomainRegex: RegExp = /^.+@(?<domain>[\w.]+)$/
  return email.match(emailDomainRegex)?.groups?.domain
}

export function sanitize(input: string): string {
  return input.toLowerCase().trim()
}

// convert a name to an abbreviation
export function abbrev(name?: string): string | undefined {
  return name?.split(" ").map(word => word[0]?.toUpperCase()).join("")
}

// check if it's a valid URL using the built-in URL constructor
export const isValidUrl = (urlString: string): boolean => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const pluralize = (count: number, text: string): string => {
  if (count === 1) return text
  return `${text}s`
}

export const pluralizeWithNum = (count: number, text: string): string =>
  `${count} ${text}${count === 1 ? "" : "s"}`

export const regionToString = (region: string | undefined | null, toUpperCase?: boolean, cityOnly?: boolean, stateOnly?: boolean): string => {
  if (!region) {
    console.warn("regionToString called with undefined or null region")
    return 'Portland, OR'
  }
  if (region === MetroRegion.NotListed) return "My area is not listed"
  // replace underscore with ' ' unless it's the last underscore and followed by 2 chars, then replace with ', '
  const regionStr = region.replace(/_(?=[a-zA-Z]{2}\b)/g, ', ').replace(/_/g, ' ')
  const regionStateStr = regionStr.split(', ')
  if(stateOnly) return regionStateStr[1]
  if (toUpperCase) return cityOnly ? regionStateStr[0].toUpperCase() : regionStr.toUpperCase()
  return cityOnly ? regionStateStr[0] : regionStr
}

export const regionOrOtherToString = (region: string, city?: string, state?: string): string | undefined => {
  if (!region || region === MetroRegion.NotListed) {
    if (city && state) return `${city}, ${state}`
    else return
  }
  return regionToString(region)
}

export const matchState = (region: MetroRegion) => {
  if (region === MetroRegion.NotListed) return undefined
  const stateStr = regionToString(region, false, false, true)
  const match = usaStates.find(s => s.code === stateStr) ?? null
  return match
}

export const locationString = (scheduledActivity: ScheduledActivityForView): string => {
  if (scheduledActivity.location) {
    return `${scheduledActivity.location.city}, ${scheduledActivity.location.state}`
  } else {
    return "Virtual"
  }
}

enum urlTemplateKey {
  userEmail = "USER_EMAIL",
  userId = "USER_ID",
  eventId = "EVENT_ID",
}

function urlTemplateReplace(urlTemplate: string, templateKey: urlTemplateKey, newValue: string): string {
  return urlTemplate.replaceAll(`{${templateKey}}`, newValue)
}

export function renderUrlTemplate(urlTemplate: string, opts: { userEmail?: string, userId?: string, eventId?: string }): string {
  if (opts.userEmail) {
    urlTemplate = urlTemplateReplace(urlTemplate, urlTemplateKey.userEmail, opts.userEmail)
  }
  if (opts.eventId) {
    urlTemplate = urlTemplateReplace(urlTemplate, urlTemplateKey.eventId, opts.eventId)
  }
  if (opts.userId) {
    urlTemplate = urlTemplateReplace(urlTemplate, urlTemplateKey.userId, opts.userId)
  }
  return urlTemplate
}

export function autogenHandle(fromName: string) {
  // autogenerate a compliant url handle
  // >> replaces whitespace with dashes, removes all non-allowed characters (letters, numbers, dashes, underscores), and converts to lowercase
  return fromName.replace(/\s/g, "-").replace(/[^\w-]/g, "").toLocaleLowerCase()
}

export const restrictionsArray = Object.values(ScheduledActivityRegistrationRestriction)
export const restrictionForDisplay = (restriction: ScheduledActivityRegistrationRestriction) => {
  switch(restriction) {
    case ScheduledActivityRegistrationRestriction.TeamShareOnly:
      return "Team share only"
    default: {
      return ScheduledActivityRegistrationRestriction.Unrestricted
    }
  }
}

export function dollarCents(cents?: number | null): string {
  return `$${formattedCents(cents)}`
}

export function formattedCents(cents?: number | null): string {
  if (!cents) return "0.00"
  const dollars = cents / 100
  return dollars.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function dollarDollars(dollars?: number | null): string {
  return `$${formattedDollars(dollars)}`
}

export function formattedDollars(dollars?: number | null): string {
  if (!dollars) return "0"
  return dollars.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
