import { Drawer } from "@mui/material"
import useNavBarStyles from "../../../styles/navBarStyles"
import NavColumnContent from "./NavColumnContent"
import { matchPath, useLocation } from "react-router-dom"

export default function NavColumn() {
  const navBarStyles = useNavBarStyles()

  const { pathname } = useLocation()
  const pathMatch = (path: string | string[], exact?: boolean): boolean => !!matchPath(pathname, { path: path, exact })
  const loginPage = pathMatch('/', true )

  return loginPage ? null : (
    <Drawer variant="permanent" anchor="left" className={`${navBarStyles.navColumn} ${navBarStyles.sectionDesktop}`}>
      <NavColumnContent />
    </Drawer>
  )
}
