import { MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import { Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { DonationMatchPolicy } from "../../models/Donations"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserInfo } from "../../models/UserInfo"
import preventNav from "../../util/preventNav"
import MatchableDonationPolicySummaryList from "../User/MatchableDonationPolicySummaryList"
import MatchableDonationCreateForm from "./MatchableDonationCreateForm"
import { DonationStatusChip } from "./MatchableDonationListItem"
import MatchableDonationManageForm from "./MatchableDonationManageForm"
import MatchableDonationTracker from "./MatchableDonationTracker"
import MatchableDonationUpdateForm from "./MatchableDonationUpdateForm"
import StripeProvidedMatchableDonationCreateForm from "./StripeProvidedMatchableDonationCreateForm"

export enum OpenDonationModal {
  NONE,
  DETAILS,
  CREATE,
  UPDATE,
  MANAGE,
  PLATFORM,
}

interface props {
  openModal: OpenDonationModal,
  setOpenModal: (modal: OpenDonationModal) => void,
  currentDonation: MatchableDonationModelResponse | null,
  listDonations?: (retryAfter?: number) => void,
  donationUser?: UserInfo,
  donationNpo?: NPO,
  donationOrgUnit?: OrgUnit,
  canAdmin: boolean,
  adminView?: boolean,
  orgUnit?: OrgUnit,
  activePolicies?: DonationMatchPolicy[], // pass in the relevant policies, primarily for new donations
  appliedPolicy?: DonationMatchPolicy, // for matching management
  platformDonationOnly?: boolean,
  presetNpo?: NPO,
  presetDonationAmount?: number,
  reload?: () => void,
}

export default function MatchableDonationModal(props: props) {
  const { openModal, setOpenModal, currentDonation, listDonations, donationUser, donationNpo, donationOrgUnit,
    canAdmin, adminView, orgUnit, activePolicies, appliedPolicy, platformDonationOnly, presetNpo, presetDonationAmount, reload } = props
  const [formDirty, setFormDirty] = useState(false)

  const [submitButtonComponent, setSubmitButtonComponent] = useState<JSX.Element | null>(null)

  const closeModal = (forSubmit?: boolean, retryAfter?: number) => {
    const triggerClose = () => {
      setSubmitButtonComponent(null)
      setFormDirty(false)
      setOpenModal(OpenDonationModal.NONE)
      reload && reload()
      if (forSubmit && listDonations) listDonations(retryAfter)
    }

    if (!forSubmit && formDirty) {
      if (window.confirm("Are you sure you want to go? You have not submitted the changes.")) {
        triggerClose()
      }
    } else {
      triggerClose()
    }
  }

  preventNav(formDirty)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (<>
    { (orgUnit && activePolicies) &&
    <Dialog fullScreen={fullScreen} open={openModal === OpenDonationModal.CREATE} onClose={() => {closeModal()}} fullWidth maxWidth="md">
      <DialogTitle>
        Add a donation
        <IconButton
          aria-label="closeDonationModal"
          sx={{ position: 'absolute', right: 16, top: 16 }}
          onClick={() => { closeModal()} }
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <MatchableDonationPolicySummaryList matchableDonationPolicies={activePolicies} orgUnits={[orgUnit]} variant="brief" />
        <MatchableDonationCreateForm
          header="Add donation"
          orgUnitId={orgUnit.id}
          onClose={(forSubmit?: boolean) => { closeModal(forSubmit) }}
          reportDirty={setFormDirty}
        />
      </DialogContent>
    </Dialog>}

    {(orgUnit && activePolicies) &&
      <Dialog fullScreen={fullScreen} open={openModal === OpenDonationModal.PLATFORM}
        onClose={() => { closeModal() }} fullWidth maxWidth={presetNpo ? "sm" : "md"}
      >
        {(openModal === OpenDonationModal.PLATFORM) && <>
          <DialogTitle>
            {presetNpo ? `Donate to ${presetNpo.name}` : "Add a donation"}
            <IconButton
              aria-label="closeDonationModal"
              sx={{ position: 'absolute', right: 16, top: 16 }}
              onClick={() => { closeModal() }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="md" sx={{ pb: 2 }}>
              <MatchableDonationPolicySummaryList matchableDonationPolicies={activePolicies} orgUnits={[ orgUnit ]} variant="brief" />
            </Container>
            <StripeProvidedMatchableDonationCreateForm
              header="Add donation"
              orgUnitId={orgUnit.id}
              onClose={closeModal}
              reportDirty={setFormDirty}
              setSubmitButtonComponent={setSubmitButtonComponent}
              platformDonationOnly={platformDonationOnly}
              presetNpo={presetNpo}
              presetAmount={presetDonationAmount}
            />
          </DialogContent>
          {submitButtonComponent && <DialogActions sx={{ p: 2 }}>
            {submitButtonComponent}
          </DialogActions>}
        </>}
      </Dialog>
    }

    {currentDonation && <>
      <Dialog fullScreen={fullScreen} open={openModal === OpenDonationModal.DETAILS} onClose={() => { closeModal() }} fullWidth maxWidth="md">
        <DialogTitle>
          Donation details
          <IconButton
            aria-label="closeDonationModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal() }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MatchableDonationTracker
            canAdmin={canAdmin}
            adminView={adminView ?? false}
            matchableDonation={currentDonation}
            donationUser={donationUser}
            donationNpo={donationNpo}
            donationOrgUnit={donationOrgUnit}
          />
        </DialogContent>
      </Dialog>

      {donationNpo && <>
        <Dialog fullScreen={fullScreen} open={openModal === OpenDonationModal.UPDATE} onClose={() => { closeModal() }} fullWidth maxWidth="md">
          <DialogTitle>
            Edit donation
            <DonationStatusChip donation={currentDonation} sx={{ marginLeft: "2em" }} />
            <IconButton
              aria-label="closeDonationModal"
              sx={{ position: 'absolute', right: 16, top: 16 }}
              onClick={() => { closeModal() }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <MatchableDonationUpdateForm
              header="Edit donation"
              orgUnitId={currentDonation.orgUnitId}
              onClose={(forSubmit?: boolean) => { closeModal(forSubmit) }}
              matchableDonation={currentDonation}
              donationNpo={donationNpo}
              reportDirty={setFormDirty}
            />
          </DialogContent>
        </Dialog>

        <Dialog fullScreen={fullScreen} open={openModal === OpenDonationModal.MANAGE} onClose={() => { closeModal() }} fullWidth maxWidth="md">
          <DialogTitle>
            Manage donation match
            <DonationStatusChip donation={currentDonation} sx={{ marginLeft: "2em" }} />
            <IconButton
              aria-label="closeDonationModal"
              sx={{ position: 'absolute', right: 16, top: 16 }}
              onClick={() => { closeModal() }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <MatchableDonationManageForm
              onClose={(forSubmit?: boolean) => { closeModal(forSubmit) }}
              matchableDonation={currentDonation}
              donationUser={donationUser}
              donationNpo={donationNpo}
              reportDirty={setFormDirty}
              donationOrgUnit={donationOrgUnit}
              appliedPolicy={appliedPolicy}
            />
          </DialogContent>
        </Dialog>
      </>}
    </>}
  </>)
}
