import { DonationInvoiceBatchStatus } from "@fieldday/fielddayportal-model"
import CloseIcon from "@mui/icons-material/Close"
import CopyIcon from "@mui/icons-material/ContentCopy"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import EmptyPageCallToAction from "../../../components/Global/EmptyPageCallToAction"
import PopperButtonGroup from "../../../components/Global/PopperButtonGroup"
import { AlertSeverity, useLoading } from "../../../hooks/useLoading"
import { NPO } from "../../../models/Npo"
import { OrgUnit } from "../../../models/OrgUnit"
import useFormStyles from "../../../styles/formStyles"
import { dayjsTz, isoDate, shortDate, today } from "../../../util/dateUtil"
import { CTAIconSrc } from "../../../util/icons"
import { dollarCents, formattedCents } from "../../../util/stringUtil"
import { DonationInvoiceBatchModelResponseWithUpdatedAt, useAPI } from "../../../util/useAPI"
import { DonationsSummary, InvoiceStatusChip } from "./DonationInvoicing"

export default function DonationInvoicesInvoiced({ refreshCounter, triggerRefresh }: { refreshCounter: number, triggerRefresh: () => void }) {
  const FieldDayAPI = useAPI()
  const [donationInvoiceBatches, setDonationInvoiceBatches] = useState<DonationInvoiceBatchModelResponseWithUpdatedAt[] | null>(null)
  const [batchOrgUnits, setBatchOrgUnits] = useState<OrgUnit[] | null>(null)
  const [batchNonProfitOrgUnits, setBatchNonProfitOrgUnits] = useState<NPO[] | null>(null)

  const { loadStart, loadEnd } = useLoading()

  useEffect(() => {
    const abortController = new AbortController()
    loadStart(true)
    FieldDayAPI.listDonationInvoiceBatches([DonationInvoiceBatchStatus.Invoiced], abortController).then((res) => {
      setDonationInvoiceBatches(_.orderBy(res.data.donationInvoiceBatches, "updatedAt", "desc"))
      setBatchOrgUnits(res.data.orgUnits)
      setBatchNonProfitOrgUnits(res.data.nonProfitOrgs)
    }).finally(() => {loadEnd()})

    return () => {
      abortController.abort()
    }
  }, [refreshCounter])

  return (<>
    {(donationInvoiceBatches && donationInvoiceBatches.length === 0)
      ? <EmptyPageCallToAction
        header="No invoices awaiting payment"
        description=""
        iconSrc={CTAIconSrc.Organization}
      />
      : <Grid container spacing={2} mt={4} mb={4} pl={2} pr={20}>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Last updated</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"}>Team name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Match amount</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Fees amount</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>Invoice ID</Typography>
        </Grid>
      </Grid>
    }

    {donationInvoiceBatches?.map(batch => {
      const orgUnit = batchOrgUnits?.find(ou => ou.id === batch.orgUnitId)
      if (!orgUnit) return <></>
      return (
        <DonationInvoiceProgressSummary
          key={batch.orgUnitId}
          batch={batch}
          orgUnit={orgUnit}
          nonProfitOrgs={batchNonProfitOrgUnits ?? []}
          triggerRefresh={triggerRefresh}
        />
      )
    })}
  </>)
}

function DonationInvoiceProgressSummary({ batch, orgUnit, nonProfitOrgs, triggerRefresh }: {
  key: string,
  batch: DonationInvoiceBatchModelResponseWithUpdatedAt,
  orgUnit: OrgUnit,
  nonProfitOrgs: NPO[],
  triggerRefresh: () => void,
}) {
  const FieldDayAPI = useAPI()

  const formStyles = useFormStyles()

  const { setAlert, loadStart } = useLoading()

  const [expanded, setExpanded] = useState(false)
  const [openModal, setOpenModal] = useState<"none" | "paid">("none")
  function closeModal() {
    setOpenModal("none")
  }

  const defaultStripePaidDate = today()
  const [stripePaidDate, setStripePaidDate] = useState(defaultStripePaidDate)

  return (<>
    <Dialog open={openModal === "paid"} fullWidth maxWidth="xs" onClose={closeModal}>
      {(openModal === "paid") && <>
        <DialogTitle>
          Set payment date
          <IconButton
            aria-label="closeDonationModal"
            sx={{ position: 'absolute', right: 16, top: 16 }}
            onClick={() => { closeModal()} }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container>
            <FormControl sx={{ marginY: 4 }} className={formStyles.textInputFullWidth}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={"Invoice paid on"}
                  value={stripePaidDate}
                  onChange={(newDate: string | null | Dayjs) => {
                    if (newDate) {
                      const dateISO = isoDate(newDate)
                      setStripePaidDate(dateISO)
                    } else {
                      setStripePaidDate(defaultStripePaidDate)
                    }
                  }}
                  renderInput={params => <TextField
                    {...params}
                    helperText={"The date that the invoice was paid"}
                  />}
                />
              </LocalizationProvider>
            </FormControl>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            if (stripePaidDate === "") {
              setAlert(AlertSeverity.ERROR, "Paid date is required to mark as paid")
            } else {
              closeModal()
              loadStart(true)
              FieldDayAPI.updateDonationInvoiceBatch(batch.id, {
                status: DonationInvoiceBatchStatus.Paid,
                paidAt: stripePaidDate,
              }).then(() => {
                triggerRefresh()
              })
            }
          }}>Mark as paid</Button>
        </DialogActions>
      </>}
    </Dialog>

    <Accordion expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<GridExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography marginTop={"0.5em"}>
                  {shortDate(dayjsTz(batch.updatedAt))}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography marginTop={"0.5em"} display="inline-block">
                  {orgUnit.name}
                </Typography>
                <InvoiceStatusChip sx={{ ml: 1 }} batch={batch} />
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(`${formattedCents(batch.invoiceMatchCents)}`)
                      setAlert(AlertSeverity.SUCCESS, `Copied ${formattedCents(batch.invoiceMatchCents)} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {dollarCents(batch.invoiceMatchCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(`${formattedCents(batch.invoiceFeesCents)}`)
                      setAlert(AlertSeverity.SUCCESS, `Copied ${formattedCents(batch.invoiceFeesCents)} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {dollarCents(batch.invoiceFeesCents)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap maxWidth={"90%"} display="inline-block">
                  <IconButton
                    sx={{ float: "" }}
                    onClick={(event) => {
                      event?.stopPropagation()
                      event?.preventDefault()
                      navigator.clipboard.writeText(batch.invoiceId ?? "")
                      setAlert(AlertSeverity.SUCCESS, `Copied ${batch.invoiceId} to clipboard`, 2000)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  {batch.invoiceId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {expanded && <Grid item xs={12}>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Created on: </Typography>
                <Typography>{shortDate(dayjsTz(batch.createdAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch ends on: </Typography>
                <Typography>{shortDate(dayjs(batch.endAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Invoiced on: </Typography>
                <Typography>{shortDate(dayjs(batch.invoicedAt))}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Batch id:</Typography>
                <Typography>{batch.batchId}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Invoice id:</Typography>
                <Typography>{batch.invoiceId}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="label2" display={"inline"}>Donations:</Typography>
                <Typography>{batch.matchableDonations?.length}</Typography>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        <PopperButtonGroup
          sx={{ mr: "1em", height: "2.5em" }}
          defaultColor="primary"
          setTopMargin="0em"
          primaryButton={
            <Button onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setOpenModal("paid")
            }} variant="outlined">Paid</Button>
          }
        >
          <Button onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            loadStart(true)
            FieldDayAPI.updateDonationInvoiceBatch(batch.id, {
              status: DonationInvoiceBatchStatus.Created,
              invoicedAt: null,
              invoiceId: null,
            }).then(() => {
              triggerRefresh()
            })
          }}>
            Void
          </Button>
        </PopperButtonGroup>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <DonationsSummary
          matchableDonations={batch.matchableDonations ?? []}
          nonProfitOrgs={nonProfitOrgs}
        />
      </AccordionDetails>
    </Accordion>
  </>)
}
