import { DonationMatchPolicyStatus } from "@fieldday/fielddayportal-model"
import { Button, Chip, Link, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { DonationMatchPolicy } from "../../models/Donations"
import { NPO } from "../../models/Npo"
import useGlobalStyles from "../../styles/globalStyles"
import { tsFormatDateRange } from "../../util/dateUtil"
import MatchableDonationPolicyDialog from "./MatchableDonationPolicyDialog"

export default function MatchableDonationPolicyListItem({
  matchPolicy,
  setMatchPolicy,
  toggleForm,
  disabled,
  exception,
  policyNpos,
}: {
  matchPolicy: DonationMatchPolicy,
  setMatchPolicy: (value: React.SetStateAction<DonationMatchPolicy>) => void,
  toggleForm: () => void,
  disabled: boolean,
  exception: boolean,
  policyNpos: NPO[],
}) {
  const globalStyles = useGlobalStyles()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleEditClick = () => {
    setMatchPolicy(matchPolicy)
    toggleForm()
  }

  const handlePolicyOpen = () => {
    setDialogIsOpen(true)
    setMatchPolicy(matchPolicy)
  }

  const handlePolicyClose = () => {
    setDialogIsOpen(false)
  }

  return (
    <>
      <ListItem key={matchPolicy.id} alignItems="flex-start" style={{ paddingLeft: '0em', paddingRight: '0em' }}>
        <ListItemText
          primary={
            <Link component="button" underline="none" textAlign='left' disabled={disabled} onClick={handlePolicyOpen}>
              {matchPolicy.name}
            </Link>
          }
          secondary={
            <Stack alignItems='flex-start' component='span'>
              <Typography variant='label2' style={{ marginTop: '.2rem', marginBottom: '.2rem' }} >
                {matchPolicy.startISO && matchPolicy.endISO && tsFormatDateRange(matchPolicy.startISO, matchPolicy.endISO, true, true)}
              </Typography>
              <PolicyStatusChip size="small" status={matchPolicy.status} />
            </Stack>
          }
          primaryTypographyProps={{
            className: `${globalStyles.matchPolicyLink} ${disabled && !exception ? 'disabled' : ""}`
          }}
          style={{ marginRight: '1rem', width: '250px', alignItems: 'flex-start' }}
        />
        <ListItemIcon>
          <Button variant="outlined" color='warning' onClick={handleEditClick} disabled={disabled}>
            Edit
          </Button>
        </ListItemIcon>
      </ListItem>
      <MatchableDonationPolicyDialog
        matchPolicy={matchPolicy}
        policyNpos={policyNpos}
        open={dialogIsOpen}
        close={handlePolicyClose}
        handleEditClick={handleEditClick}
        adminView
        configView
      />
    </>
  )
}

export function PolicyStatusChip({ status, size, marginOverride }: {
  size: "small" | "medium",
  status?: DonationMatchPolicyStatus,
  marginOverride?: { left?: string, right?: string }
}) {
  if (!status) return null
  return (
    <Chip
    component='span'
    label={status}
    size={size}
    color={status === DonationMatchPolicyStatus.Active ? 'primary' : 'error'}
    style={{ marginLeft: marginOverride?.left, marginRight: marginOverride?.right }}
    />
  )
}
