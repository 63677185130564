import makeStyles from '@mui/styles/makeStyles'

const drawerWidth = 246
export const appBarHeight = "135px"

const useNavBarStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    marginBottom: '8px',
    backgroundColor: 'transparent',
    '&.spacer': {
      marginBottom: '3em',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  profileMenu: {
    padding: theme.spacing(0, 0, 1, 0)
  },
  myMenuLink: {
    color: `${theme.palette.text.primary} !important`,
    padding: "0.5em 1em",
  },
  navColumn: {
    padding: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
    width: drawerWidth,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      padding: "2.75rem 1.5rem 2rem",
    },
  },
  fieldDayLink: {
    color: theme.palette.neutral.main,
    marginTop: theme.spacing(1),
    '& a:any-link': {
      color: theme.palette.neutral.main,
      fontWeight: "normal",
    }
  },
  appBar: {
    padding: "8px 26px 0",
    width: "100%",
    minHeight: appBarHeight
  }
}))

export default useNavBarStyles
