import { Box, Typography, useTheme } from '@mui/material'
import { CTAIconSrc, CallToActionIcon } from '../../util/icons'
import SubheadLink from './SubheadLink'

export interface CallToActionProps {
  header: string
  description: JSX.Element | string
  callToActionText?: string
  callToActionLink?: string
  secondaryCallToActionText?: string,
  secondaryCallToActionLink?: string,
  iconSrc: CTAIconSrc
  marginBottom?: string
  marginTop?: string
  external?: boolean
}

export default function EmptyPageCallToAction({
  header, description, callToActionText, callToActionLink, secondaryCallToActionLink,
  secondaryCallToActionText, iconSrc, marginBottom, marginTop, external
}: CallToActionProps) {
  const theme = useTheme()
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
      sx={{
        mx: "auto",
        mb: marginBottom ?? "auto",
        mt: marginTop ?? "auto",
        padding: '1rem',
        textAlign: 'center',
        bgcolor: theme.palette.neutral.light,
        borderRadius: "4px"
      }}>
      <CallToActionIcon src={iconSrc} />
      <Typography variant="h6" mb={0} mt={-1}>
        {header}
      </Typography>
      <Box style={{ maxWidth: "23rem" }}>
        <Typography variant="body2" color="textSecondary" mb={2} mt={.5}>
          {description}
        </Typography>
      </Box>
      {callToActionText && callToActionLink &&
        <SubheadLink to={callToActionLink} direction={external ? "external" : "forward"} size="small" external={external}>
          {callToActionText}
        </SubheadLink>
      }
      {secondaryCallToActionText && secondaryCallToActionLink &&
        <SubheadLink to={secondaryCallToActionLink} direction={external ? "external" : "forward"} size="small" external={external}>
          {secondaryCallToActionText}
        </SubheadLink>
      }
    </Box>
  )
}
