import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box, Divider,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { ReactElement, cloneElement, useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { HeaderDetails } from '../../../hooks/useLayout';
import useNavBarStyles from '../../../styles/navBarStyles';
import SignInButton from '../../Accounts/SignInButton';
import NavColumnContent from './NavColumnContent';
const ProfileMenu = React.lazy(() => import('./ProfileMenu'))
const IconButtonsForUser = React.lazy(() => import('./IconButtonsForUser'))

// make the top panel scroll along with the page
function ElevationScroll(props: { children: ReactElement }) {
  const { children } = props;

  return cloneElement(children, {
    elevation: 0,
  });
}

export default function PrimaryAppBar({ headerDetails }: { headerDetails: HeaderDetails }) {
  const navBarStyles = useNavBarStyles()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null)

  const { user } = useAuth()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const { pathname } = useLocation()
  const pathMatch = (path: string | string[], exact?: boolean): boolean => !!matchPath(pathname, { path: path, exact })
  const loginPage = pathMatch('/', true )

  const handleProfileMenuOpen = (event: any) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  // close the drawer/profile menu any time the page is navigated
  useEffect(() => {
    setIsDrawerOpen(false)
    setProfileMenuAnchorEl(null)
  }, [pathname])

  return loginPage ? null : (
    <>
      <ElevationScroll>
        <AppBar color="inherit"
          position={mobileView ? "relative" : "sticky"}
          className={navBarStyles.appBar}
        >
          <Toolbar sx={{ alignItems: "flex-start", px: 0 }}>
            <div className={navBarStyles.sectionMobile}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                size="large"
                onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </div>
            {(headerDetails.primaryHeader || headerDetails.subheader) ?
              <Box mt={mobileView ? "10px" : "1.25em"}  ml={mobileView ? "-2em" : "0"} width="calc(100% - 12px)">
                { mobileView &&
                  <Box textAlign="center">
                    <Link to="/" style={{ marginBottom: "1em" }}>
                      <img height="32px" width="59px" src="/images/FieldDay_BlackLogo.webp" alt="Field Day Logo" title="Field Day" />
                    </Link>
                  </Box>
                }
                <Typography variant="h2" component="h1" mb={mobileView ? "6px" : headerDetails.subheader ? .5 : 2}>
                  {headerDetails.primaryHeader ?? ""}
                </Typography>
                <Typography variant="label2" color="textSecondary">
                  {headerDetails.subheader ?? ""}
                </Typography>
              </Box> :
              <Box mt={3} width="50%">
                <Skeleton variant="rectangular" animation="wave" height={100} width='100%' sx={{ borderRadius: "4px", marginBottom: '2em' }} />
              </Box>
            }
            <div className={navBarStyles.grow} />
            {user ? (
              <React.Suspense>
                <IconButtonsForUser handleProfileMenuOpen={handleProfileMenuOpen} />
              </React.Suspense>
            ) : (
              <Stack direction='column' alignItems='end' height='100%' mt={2} width={mobileView ? "41%" : "25%"}>
                <SignInButton sx={{ width: 'fit-content' }}>Sign in</SignInButton>
                <Typography variant={mobileView ? "caption" : 'body2'} className={navBarStyles.fieldDayLink}>
                  <a href="https://heyfieldday.com" target="_blank" rel="noreferrer">
                    What is Field Day?
                  </a>
                </Typography>
              </Stack>
            )}
          </Toolbar>
          {headerDetails.withDivider && <Divider sx={{ mx: "24px", mt: 3 }} />}
        </AppBar>
      </ElevationScroll>
      {user && <React.Suspense>
        <ProfileMenu anchorEl={profileMenuAnchorEl} setAnchorEl={setProfileMenuAnchorEl} />
      </React.Suspense>}
      <Drawer
        variant="temporary" anchor="left"
        className={`${navBarStyles.navColumn} ${navBarStyles.sectionMobile}`}
        open={isDrawerOpen} onClose={toggleDrawer}
      >
        <NavColumnContent />
      </Drawer>
    </>
  );
}

