import { DonationDisbursementBatchResponseModelI, DonationDisbursementNonProfitOrgBatchResponseModelI } from "@fieldday/fielddayportal-model"
import { Button } from "@mui/material"
import _ from "lodash"
import { NPO } from "../../../models/Npo"
import { downloadFile } from "../../../util/download"
import { formattedCents } from "../../../util/stringUtil"

export default function GetDisbursementCsvButton({ batch, nonProfitOrgs, variant, includeHeaders }: {
  batch: DonationDisbursementBatchResponseModelI,
  nonProfitOrgs: Readonly<NPO[]>,
  variant?: "text" | "outlined" | "contained" | "listItem",
  includeHeaders?: boolean,
}) {
  return (
    <Button
      variant={variant ?? "listItem"}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        const disbursement = generateDisbursementCsv(batch, nonProfitOrgs, includeHeaders)
        downloadFile(disbursement, `disbursement ending ${batch.endAt}.csv`, "text/csv")
      }}
    >{includeHeaders ? "Get CSV (headers)" : "Get CSV"}</Button>
  )
}

function generateDisbursementCsv(batch: DonationDisbursementBatchResponseModelI, npos: Readonly<NPO[]>, includeHeaders: boolean | undefined): string {
  const disbursementEntries: string[] = []

  for (const npoBatch of _.orderBy(batch.disbursementNonProfitOrgBatches, "sequenceNumber")) {
    const npo = npos.find(n => n.id === npoBatch.nonProfitOrgId)
    if (npo) {
      disbursementEntries.push(disbursementCsvLine(npoBatch, npo, includeHeaders))
    }
  }

  if (includeHeaders) {
    return [
      ["Recipient Name", "Account number", "Account type", "Routing number", "Sequence number", "Dollar amount", "EIN"].join(","),
      ...disbursementEntries,
    ].join("\n")
  }

  return disbursementEntries.join("\n")
}

function disbursementCsvLine(batch: DonationDisbursementNonProfitOrgBatchResponseModelI, npo: NPO, includeHeaders: boolean | undefined): string {
  const einCellNumber = includeHeaders && batch.sequenceNumber ? batch.sequenceNumber + 1 : batch.sequenceNumber
  const einCellRef = `G${einCellNumber}`
  return [
    npo.name,
    einCellNumber ? `=ACH_ACCOUNTNUM(${einCellRef})` : "",
    einCellNumber ? `=ACH_ACCOUNTTYPE(${einCellRef})` : "",
    einCellNumber ? `=ACH_ROUTINGNUM(${einCellRef})` : "",
    batch.sequenceNumber,
    formattedCents(batch.disbursementAmountCents).split(",").join(""),
    npo.ein,
  ].join(",")
}
