import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from '../../styles/useStyles';
import Header from './Header';

export default function Render404() {
  const styles = useStyles()

  return (
    <main>
      <Header primaryHeader="Not found" />
      <Container maxWidth="sm" className={styles.heroContent}>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom mt={4}>
          Oops, looks like something went wrong.
        </Typography>
        <Typography variant="subtitle1" align="center" color="textPrimary" gutterBottom>
          The page you were looking for was not found.
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" style={ { margin: "50px" } }>
          Click <Link to="/">here</Link> to go home
        </Typography>
      </Container>
    </main>
  );
}
