import { DonationStatus, MatchApprovalStatus, MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import {
  Box,
  Container,
  Divider,
  Grid,
  Step, StepConnector, StepLabel, Stepper,
  Typography,
  stepConnectorClasses,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material"
import dayjs from "dayjs"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserInfo } from "../../models/UserInfo"
import useStyles from "../../styles/useStyles"
import { DonationUiStatus, getDonationUiStatus } from "../../util/matchableDonationUtils"
import { dollarCents } from "../../util/stringUtil"
import MatchableDonationDetails, { DonationDetailGridItem } from "./MatchableDonationDetails"

interface props {
  matchableDonation?: MatchableDonationModelResponse,
  donationUser?: UserInfo,
  donationNpo?: NPO,
  donationOrgUnit?: OrgUnit,
  canAdmin: boolean,
  adminView: boolean
}

export default function MatchableDonationTracker(props: props) {
  const { matchableDonation, donationUser, donationNpo, donationOrgUnit, canAdmin, adminView } = props
  const theme = useTheme()
  const styles = useStyles()
  const platformDonation = matchableDonation?.platformDonations && matchableDonation.platformDonations[0]
  const status = matchableDonation && getDonationUiStatus(matchableDonation)
  const approvalPending = matchableDonation?.approvalStatus === MatchApprovalStatus.Pending
  const paymentFailed = status === DonationUiStatus.PaymentFailed
  const paymentPending = status === DonationUiStatus.PaymentPending
  const lgView = useMediaQuery(theme.breakpoints.up('md'))

  const approvedAmtText = (() => {
    if (!matchableDonation) return
    if (matchableDonation.approvalStatus === MatchApprovalStatus.Approved) {
      return `${dollarCents(matchableDonation.approvedAmount)}`
    }
    if (approvalPending) {
      return "Pending"
    }
    if (matchableDonation.approvalStatus === MatchApprovalStatus.Rejected) {
      return "Match declined"
    }
  })()

  const approvedDateText = (() => {
    if (!matchableDonation) return
    if (matchableDonation.approvedDateISO) {
      return `${dayjs(matchableDonation.approvedDateISO).format("MMMM D, YYYY")}`
    }
    return "Pending"
  })()

  const matchDateText = (() => {
    if (!matchableDonation) return
    if ([DonationStatus.Matched, DonationStatus.Completed].includes(matchableDonation.status) && matchableDonation.matchedDateISO) {
      return `${dayjs(matchableDonation.matchedDateISO).format("MMMM D, YYYY")}`
    }
    return "Pending"
  })()

  const disbursedDateText = (() => {
    if (!matchableDonation) return
    const disbursedDate = matchableDonation.disbursedDateISO ?? platformDonation?.disbursedDateISO
    if (status === DonationUiStatus.Completed && disbursedDate) {
      return `${dayjs(disbursedDate).format("MMMM D, YYYY")}`
    }
    return "Pending"
  })()

  const matchTotalText = (() => {
    if (!matchableDonation) return
    if (matchableDonation.approvalStatus === MatchApprovalStatus.Pending) {
      return "Pending"
    }
    return `${dollarCents((matchableDonation.amount ?? 0) + (matchableDonation.matchedAmount ?? 0))}`
  })()

  const disbursementDelayMessage = ["The donation may take up to one to three business days after the disbursement",
    "date to appear in the nonprofit's account."].join(" ")

  const trackerStep = (() => {
    if (matchableDonation) {
      switch(status) {
        case DonationUiStatus.Made:
          return ({
            index: 0,
            description: `${adminView ? "This" : "Your"} donation has been made and is awaiting match approval.`
          })
        case DonationUiStatus.Reported:
          return ({
            index: 0,
            description: `${adminView ? "This" : "Your"} donation has been reported and is awaiting match approval.`
          })
        case DonationUiStatus.HoursCompleted:
          return ({
            index: 0,
            description: `${adminView ? "This" : "Your"} volunteer time may be eligible for an hourly match.`
          })
        case DonationUiStatus.PaymentFailed:
          return ({
            index: 0,
            description: `Payment failed. ${adminView ? "" : "Please try again."}`
          })
        case DonationUiStatus.PaymentPending:
          return ({
            index: 0,
            description: "Payment pending. Please check back later."
          })
        case DonationUiStatus.Approved:
          return ({
            index: 1,
            description: `${adminView ? "This" : "Your"} ${matchableDonation.minutesVolunteered ?
              'volunteer time has been approved for an hourly match by your Team.': 'donation has been approved to be matched by your company.'}`
          })
        case DonationUiStatus.Declined:
          return ({
            index: 1,
            description: "Your company has declined to match this donation."
              + `${platformDonation && 'Your original donation is awaiting delivery to the nonprofit recipient.'}`
          })
        case DonationUiStatus.Matched:
          return ({
            index: 2,
            description: "Your company has contributed the approved match amount and the donation is awaiting delivery to the nonprofit recipient."
          })
        case DonationUiStatus.Completed: {
          const description = (() => {
            if (platformDonation) {
              if (matchableDonation.approvalStatus === MatchApprovalStatus.Approved) {
                return ["Your company has contributed the approved match amount,",
                  "and the disbursement bank transfer has been initiated to the nonprofit recipient.",
                  disbursementDelayMessage].join(" ")
              } else {
                return ["The disbursement bank transfer for this donation has been initiated to the nonprofit recipient.",
                  disbursementDelayMessage].join(" ")
              }
            } else {
              "Your company declined to match this donation."
            }
          })()

          return ({
            index: 3,
            description: description
          })
        }
      }
    }
    return ({
      index: 0,
      description: ""
    })
  })()

  const stepIcon = (stepIndex: number) => {
    if (paymentPending && stepIndex === 0) {
      return WatchLaterOutlinedIcon
    }
    if ((((stepIndex === 1 || stepIndex === 2) && stepIndex <= trackerStep.index)
      && matchableDonation?.approvalStatus === MatchApprovalStatus.Rejected)
      || (paymentFailed && stepIndex === 0)) {
        return BlockIcon
    }
    if (stepIndex <= trackerStep.index) {
      return CheckCircleIcon
    }
    return RadioButtonUncheckedIcon
  }

  const stepIconClass = (stepIndex: number) => {
    if ((((stepIndex === 1 || stepIndex === 2) && stepIndex <= trackerStep.index)
      && matchableDonation?.approvalStatus === MatchApprovalStatus.Rejected)
      || (paymentFailed && stepIndex === 0)) {
        return styles.donationTrackerDeclined
    }
    if (stepIndex <= trackerStep.index) {
      return styles.donationTrackerComplete
    }
    return styles.donationTrackerIncomplete
  }

  const step0Label = (() => {
    if (platformDonation) {
      if (paymentFailed) return "Payment failed"
      if (paymentPending) return "Payment pending"
      return "Donation made"
    }
    if (matchableDonation?.minutesVolunteered) {
      return "Hours completed"
    }
    return "Donation reported"
  })()

  return (<>
    <Container maxWidth="md">
      <Box className={styles.boxContent} alignItems="center">
        <Stepper activeStep={trackerStep.index} alternativeLabel connector={<TrackerConnector />}>
          <Step>
            <StepLabel className={stepIconClass(0)} StepIconComponent={stepIcon(0)}>
              {step0Label}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={stepIconClass(1)} StepIconComponent={stepIcon(1)}>
              {matchableDonation?.approvalStatus === MatchApprovalStatus.Rejected ? "Match declined" : "Match approved"}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={stepIconClass(2)} StepIconComponent={stepIcon(2)}>
              {matchableDonation?.approvalStatus === MatchApprovalStatus.Rejected ? "Not matched" : "Matched"}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={stepIconClass(3)} StepIconComponent={stepIcon(3)}>Completed</StepLabel>
          </Step>
        </Stepper>
        <Typography variant="body2" mt={3} px={lgView ? 5 : 3}>
          {trackerStep.description}
        </Typography>
      </Box>
      <MatchableDonationDetails
        matchableDonation={matchableDonation}
        platformDonation={platformDonation}
        donationUser={donationUser}
        donationNpo={donationNpo}
        donationOrgUnit={donationOrgUnit}
        canAdmin={canAdmin}
        adminView={adminView}
      />
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} mb={-1.5}>
          <Divider sx={{ mt: 1, mb: 3 }}/>
          <Typography fontFamily={"LoraVariable"} fontWeight={600}>Matching and delivery</Typography>
        </Grid>
        <DonationDetailGridItem
          title="Approved match amount"
          content={approvedAmtText}
          override={{ secondaryColor: approvalPending }}
        />
        {matchableDonation?.approvalStatus !== MatchApprovalStatus.Rejected &&
          <>
            <DonationDetailGridItem
              title="Approval date"
              content={approvedDateText}
              override={{ secondaryColor: approvalPending }}
            />
            <DonationDetailGridItem
              title="Match date"
              content={matchDateText}
              override={{ secondaryColor: !(status && [DonationUiStatus.Matched, DonationUiStatus.Completed].includes(status)) }}
            />
          </>
        }
        <DonationDetailGridItem
          title="Total donation amount"
          content={matchTotalText}
          override={{ secondaryColor: approvalPending }}
        />
        <DonationDetailGridItem
          title="Disbursement date"
          content={disbursedDateText}
          override={{ secondaryColor: !(status && DonationUiStatus.Completed === status) }}
        />
      </Grid>
    </Container>
  </>)
}

const TrackerConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: '-46%',
    right: '54%',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderStyle: "solid"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderStyle: "solid"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.neutral.main,
    borderWidth: "1px",
    borderStyle: "dashed"
  },

}))
