import { DonationStatus, MatchApprovalStatus, MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import { Box, Button, Container, Divider, FormControl, Grid, Stack, Typography, useTheme } from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useDirty } from "../../hooks/useDirty"
import { useLoading } from "../../hooks/useLoading"
import { DonationMatchPolicy } from "../../models/Donations"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserInfo } from "../../models/UserInfo"
import useStyles from "../../styles/useStyles"
import { tsFormatDate, tsFormatDateRange } from "../../util/dateUtil"
import { DonationUiStatus, getDonationUiStatus } from "../../util/matchableDonationUtils"
import { useAPI } from "../../util/useAPI"
import { CurrencyTextFieldCents } from "../Forms/CurrencyTextField"
import PopperButtonGroup from "../Global/PopperButtonGroup"
import MatchableDonationDetails from "./MatchableDonationDetails"

interface props {
  matchableDonation: MatchableDonationModelResponse,
  donationUser?: UserInfo,
  donationNpo?: NPO,
  donationOrgUnit?: OrgUnit,
  onClose: (skipConfirm?: boolean) => void,
  reportDirty: (dirty: boolean) => void,
  appliedPolicy?: DonationMatchPolicy
}

export default function MatchableDonationManageForm(props: props) {
  const { matchableDonation, donationUser, donationNpo, onClose, reportDirty, appliedPolicy } = props
  const FieldDayAPI = useAPI()
  const { loadStart, loadEnd } = useLoading()

  const theme = useTheme()
  const styles = useStyles()


  const [matchedAmount, setMatchedAmount] = useState(matchableDonation?.matchedAmount ?? undefined)
  const handleMatchedAmountChange = (newVal?: number) => {
    const val = !newVal || isNaN(newVal) ? undefined : newVal
    setMatchedAmount(val)
  }

  const [approvedAmount, setApprovedAmount] = useState(matchableDonation.approvedAmount ?? undefined)
  const handleApprovedAmountChange = (newVal?: number)=> {
    const val = !newVal || isNaN(newVal) ? undefined : newVal
    setApprovedAmount(val)
  }

  const status = getDonationUiStatus(matchableDonation)
  const matchingDisabled = status === DonationUiStatus.PaymentFailed || status === DonationUiStatus.PaymentPending

  const [allowPropmt, setAllowPrompt] = useState(true)
  const [dirtyFields] = useDirty({
    objForUpdate: matchedAmount,
    forDirtyCompare: (matchedAmount) => {return String(matchedAmount)}
  })
  // This is kind of messy, but the parent component is responsible
  // for modal switching and the window.confirm call so it needs
  // to know if this component is dirty. So if dirtyFields changes,
  // report it up to the parent via the reportDirty callback.
  useEffect(() => {
    reportDirty(dirtyFields && allowPropmt)
  }, [allowPropmt, dirtyFields])

  const platformDonation = matchableDonation?.platformDonations && matchableDonation.platformDonations[0]
  const approved = matchableDonation.approvalStatus === MatchApprovalStatus.Approved
  const completed = matchableDonation.status === DonationStatus.Completed

  return (<>
    <Container maxWidth="md">
      <MatchableDonationDetails
        matchableDonation={matchableDonation}
        platformDonation={platformDonation}
        donationUser={donationUser}
        donationNpo={donationNpo}
        canAdmin={true}
        adminView={true}
      />
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2.5, mb: 1.5 }}/>
        </Grid>
        <Grid item xs={12}>
          <Typography fontFamily={"LoraVariable"} fontWeight={600}>{"Donation matching"}</Typography>
        </Grid>
        {matchingDisabled &&
          <Grid item xs={12} mt={-1} mb={-2}>
            <Box className={styles.boxContent}>
              <Typography>
                {`Donation payment ${status === DonationUiStatus.PaymentPending ? "pending" : "failed"}. Donation matching unavailable.`}
              </Typography>
            </Box>
          </Grid>
        }
        {!matchingDisabled && <>
          {appliedPolicy &&
            <Grid item xs={12} mt={-1} mb={-2}>
              <Box className={`${styles.boxContent} noTextMargin`}>
                <Stack direction="row"  alignItems="baseline">
                  <Typography variant="subhead3B" mr={1}>
                    {appliedPolicy.name}
                  </Typography>
                  <Typography variant="caption">
                    {(appliedPolicy.startISO && appliedPolicy.endISO) && tsFormatDateRange(appliedPolicy.startISO, appliedPolicy.endISO, true, true)}
                    &nbsp;|&nbsp;Report deadline: {tsFormatDate(appliedPolicy.acceptEndISO, 'monthDateYr')}
                  </Typography>
                </Stack>
                <Stack direction="row" mt={2} mb={0}>
                  <Stack mr={3}>
                    <Typography variant='label3' color={theme.palette.text.secondary}>Match percent</Typography>
                    <Typography variant="body2">{appliedPolicy.matchPercent}%</Typography>
                  </Stack>
                  <Stack mr={3}>
                    <Typography variant='label3' color={theme.palette.text.secondary}>Individual match limit</Typography>
                    <Typography variant="body2" mb={0}>${appliedPolicy.matchLimitIndividualDollars?.toLocaleString()}</Typography>
                  </Stack>
                  <Stack mr={3}>
                    <Typography variant='label3' color={theme.palette.text.secondary}>Total match limit</Typography>
                    <Typography variant="body2" mb={0}>${appliedPolicy.matchLimitTotalDollars?.toLocaleString()}</Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          }
          <Grid item container xs={12}>
            {matchableDonation?.approvalStatus !== MatchApprovalStatus.Rejected &&
              <Grid item xs={2.5} mt={-1.75} mb={2} mr={2}>
                <Typography variant="caption" color={theme.palette.text.secondary}>{"Approval date"}</Typography>
                <Typography mt={.5} color={matchableDonation?.approvalStatus === MatchApprovalStatus.Pending ? theme.palette.text.secondary : "auto"}>
                  {matchableDonation?.approvedDateISO ? `${dayjs(matchableDonation.approvedDateISO).format("MMMM D, YYYY")}` : "Pending"}
                </Typography>
              </Grid>
            }
            <FormControl className={styles.manageMatchFormControl}>
              <CurrencyTextFieldCents
                id="approvedAmount"
                name="approvedAmount"
                label="Approved match amount"
                value={approvedAmount}
                onChange={handleApprovedAmountChange}
                helpText={"Amount approved to be matched"}
              />
            </FormControl>
            {(matchableDonation) &&
              <FormControl sx={{ mt: "0.3em", ml: 2 }}>
                <PopperButtonGroup
                  defaultColor="primary"
                  setTopMargin="0"
                  primaryButton={
                    <Button size="large" variant="outlined" onClick={() => {
                      setAllowPrompt(false)
                      loadStart(true)
                      FieldDayAPI.approveDonationForMatch(matchableDonation.orgUnitId, matchableDonation.id, approvedAmount).then(() => {
                        onClose(true)
                        loadEnd()
                      })
                    }}>
                      {approved ? "Update" : "Approve"}
                    </Button>
                  }
                >
                  <Button variant="listItem" color="error" onClick={() => {
                    setAllowPrompt(false)
                    loadStart(true)
                    FieldDayAPI.unapproveDonationForMatch(matchableDonation.orgUnitId, matchableDonation.id).then(() => {
                      onClose(true)
                      loadEnd()
                    })
                  }}>
                    {"Decline"}
                  </Button>
                </PopperButtonGroup>
              </FormControl>
            }
          </Grid>
          {(approved || completed) &&
            <Grid item container xs={12} mt={2}>
              {matchableDonation?.approvalStatus !== MatchApprovalStatus.Rejected &&
                <Grid item xs={2.5} mt={-1.75} mb={2} mr={2}>
                  <Typography variant="caption" color={theme.palette.text.secondary}>{"Match date"}</Typography>
                  <Typography mt={.5} color={completed ? "auto" : theme.palette.text.secondary}>
                    {matchableDonation?.matchedDateISO ? `${dayjs(matchableDonation.matchedDateISO).format("MMMM D, YYYY")}` : "Pending"}
                  </Typography>
                </Grid>
              }
              <FormControl className={styles.manageMatchFormControl}>
                <CurrencyTextFieldCents
                  id="matchedAmount"
                  name="matchedAmount"
                  label="Matched amount"
                  value={matchedAmount}
                  onChange={handleMatchedAmountChange}
                  helpText={"Matched amount paid to the nonprofit"}
                />
              </FormControl>
              {(matchableDonation) &&
                <FormControl sx={{ mt: "0.3em", ml: 2 }}>
                  <PopperButtonGroup
                    defaultColor="primary"
                    setTopMargin="0"
                    primaryButton={
                      <Button size="large" variant="outlined" onClick={() => {
                        setAllowPrompt(false)
                        loadStart(true)
                        FieldDayAPI.confirmDonationMatched(matchableDonation.orgUnitId, matchableDonation.id, matchedAmount).then(() => {
                          onClose(true)
                          loadEnd()
                        })
                      }}>
                        {completed ? "Update match" : "Confirm match"}
                      </Button>
                    }
                  >
                    {(completed) &&
                      <Button color="error" variant="listItem" onClick={() => {
                        setAllowPrompt(false)
                        loadStart(true)
                        FieldDayAPI.unconfirmDonationMatched(matchableDonation.orgUnitId, matchableDonation.id).then(() => {
                          onClose(true)
                          loadEnd()
                        })
                      }}>
                        {"Undo match"}
                      </Button>
                    }
                  </PopperButtonGroup>
                </FormControl>
              }
            </Grid>
          }
        </>}
      </Grid>
    </Container>
  </>)
}
