import { ErrorObject } from "ajv"
import _ from "lodash"

export function getErrorMessages(errors: ErrorObject[]) {
  errors.forEach(e => console.warn(e))
  // filter out the "if"s because they are redundant
  const messages = errors.filter((error) => error.keyword !== "if").map((error) => {
    const field = error.instancePath ?
      error.instancePath.replace("/", "") :   // chop off the leading slash
      error.params.missingProperty
    return [field,
      // if it's required, then don't show the whole error with the field name; "required" is sufficient
      ["required", "dependentRequired"].includes(error.keyword) ? "Required." : error.message]
  })
  // note: using uniqBy means that the first error for each field will be returned
  // (without this, the last error for each field would show up in the object)
  return Object.fromEntries(_.uniqBy(messages, (v) => v[0]))
}

export function getNestedErrorMessages(errors: ErrorObject[]) {
  errors.forEach(e => console.warn(e))
  // filter out the "if"s because they are redundant
  const messages = errors.filter((error) => error.keyword !== "if").map((error) => {
    const field = error.params.missingProperty ?
      `${error.instancePath}/${error.params.missingProperty}` :
      error.instancePath
    return [field,
      // if it's required, then don't show the whole error with the field name; "required" is sufficient
      ["required", "dependentRequired"].includes(error.keyword) ? "Required." : error.message]
  })
  // note: using uniqBy means that the first error for each field will be returned
  // (without this, the last error for each field would show up in the object)
  return Object.fromEntries(_.uniqBy(messages, (v) => v[0]))
}
