import { Box } from "@mui/material"
import _ from "lodash"
import * as React from "react"
import { useEffect } from "react"
import { Helmet, HelmetTags } from "react-helmet"
import { Route as RealRoute, Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom"

import Unauthorized from "./components/Global/401"
import Render404 from "./components/Global/404"
import CallToActionBanner from "./components/Global/CallToActionBanner"
import PrivateRoute from "./components/Global/PrivateRoute"
import Route from "./components/Global/SuspenseRoute"
import { DETECTED_ROOT_URL } from "./config/aws-auth"
import { useAuth } from "./hooks/useAuth"
import { LayoutProvider } from "./hooks/useLayout"
import { UserHelper } from "./models/UserHelper"
import IndividualSignUp from "./views/Orgs/BelieveInPortland/IndividualSignUp"
import MatchableUserDonations from "./views/User/MatchableUserDonations"
import { SSODevTest, SSODynatrace } from "./views/User/SSO"
import DonationDashboard from "./views/Wizard/DonationDashboard"
import DonationDisbursement from "./views/Wizard/DonationDisbursement/DonationDisbursement"
import NonProfitDisbursementConfig from "./views/Wizard/DonationDisbursement/NonProfitDisbursementConfig"
import DonationInvoicing from "./views/Wizard/DonationInvoicing/DonationInvoicing"

const NotificationBar = React.lazy(() => import("./components/Global/NotificationBar"))
const SignUp = React.lazy(() => import("./views/User/SignUp"))
const PageNotFound = React.lazy(() => import("./components/Global/PageNotFound"))
const ListActivityTemplates = React.lazy(() => import("./views/FieldTrips/ListActivityTemplates"))
const CreateNpo = React.lazy(() => import("./views/NonProfits/CreateNpo"))
const ListNonProfits = React.lazy(() => import("./views/NonProfits/ListNonProfits"))
const NonprofitProfileRoutes = React.lazy(() => import("./views/NonProfits/NonprofitProfileRoutes"))
const NpoById = React.lazy(() => import("./views/NonProfits/NpoById"))
const CreateBelieveTeam = React.lazy(() => import("./views/Orgs/BelieveInPortland/CreateBelieveTeam"))
const CreateTeam = React.lazy(() => import("./views/Orgs/CreateTeam"))
const Guest = React.lazy(() => import("./views/Orgs/Guest"))
const OrgUnitById = React.lazy(() => import("./views/Orgs/OrgUnitById"))
const TeamProfileRoutes = React.lazy(() => import("./views/Orgs/TeamProfileRoutes"))
const Welcome = React.lazy(() => import("./views/Orgs/Welcome"))
const ViewEvent = React.lazy(() => import("./views/ScheduledActivities/ViewEvent"))
const EditProfile = React.lazy(() => import("./views/User/EditUserProfile"))
const Home = React.lazy(() => import("./views/User/Home"))
const Notifications = React.lazy(() => import("./views/User/Notifications"))
const SignOut = React.lazy(() => import("./views/User/SignOut"))
const UserPastEvents = React.lazy(() => import("./views/User/UserPastEvents"))
const CommunityCalendar = React.lazy(() => import("./views/ScheduledActivities/CommunityCalendar"))
const ConfirmRegistrations = React.lazy(() => import("./views/Wizard/ConfirmRegistrations"))
const Dashboard = React.lazy(() => import("./views/Wizard/Dashboard"))
const FullCalendar = React.lazy(() => import("./views/Wizard/FullCalendar"))
const ListFieldTripPlans = React.lazy(() => import("./views/Wizard/ListFieldTripPlans"))
const WizardListNonprofits = React.lazy(() => import("./views/Wizard/ListNonprofits"))
const ListSurveys = React.lazy(() => import("./views/Wizard/ListSurveys"))
const ListSystemEvents = React.lazy(() => import("./views/Wizard/ListSystemEvents"))
const ListTeams = React.lazy(() => import("./views/Wizard/ListTeams"))
const ListUsers = React.lazy(() => import("./views/Wizard/ListUsers"))
const ThemePlayground = React.lazy(() => import("./views/Wizard/ThemePlayground"))
const ViewCommunityCampaign = React.lazy(() => import("./views/Orgs/ViewCommunityCampaign"))
const WelcomeCampaign = React.lazy(() => import("./views/Orgs/WelcomeCampaign"))

// Headway is the release notes app
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Headway: any
  }
}

export default function App() {
  // disable the mouse wheel for number-based input
  document.addEventListener("wheel", function (_event) {
    const numberInput = document.activeElement as HTMLInputElement

    if (numberInput.type === "number") {
      numberInput.blur()
    }
  })

  const { pathname } = useLocation()
  const { user, isWizard } = useAuth()

  useEffect(() => {
    if (!user) return
    // always load release notes regardless of env
    const releaseNotesScript = document.createElement('script')
    releaseNotesScript.src = "https://cdn.headwayapp.co/widget.js"
    releaseNotesScript.defer = true
    releaseNotesScript.onload = () => {
      window.Headway.init({
        selector: ".release-notes",
        account: "xGVwzy"
      })
    }
    document.head.appendChild(releaseNotesScript)
  }, [user?.id])

  useEffect(() => {
    if (import.meta.env.REACT_APP_ANALYTICS_ID) {
      window.gtag('set', 'user_id', user?.id || "")
      if (user) {
        const userHelper = new UserHelper(user)
        window.gtag('set', 'user_properties', {
          is_complete: userHelper.isComplete(),
          is_npo_coord: userHelper.coordinatingNonprofitMemberships().length > 0,
          is_team_coord: userHelper.coordinatingOrgUnitMemberships().length > 0,
          is_wizard: isWizard(),
        })
      }
    }
  }, [user?.id])

  if (pathname.endsWith("/") && pathname !== "/") return (
    <Redirect to={_.trimEnd(pathname, "/")} />
  )

  // no footer, header, nav, etc
  if (pathname === "/" || pathname.match("^/sso/.+")) {
    return (
      <Box component="main" flexGrow={1} alignItems="center" display="flex">
        <LayoutProvider hideMargins>
          <PrimaryRouter />
        </LayoutProvider>
      </Box>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onHelmetUpdate = (newState: any, _addedTags: HelmetTags, _removedTags: HelmetTags) => {
    // don't actually care what the tags are, just using this to trigger an update to analytics
    // the reason we do this here instead of on route change is that we want the report to
    // contain the correct page title
    if (import.meta.env.REACT_APP_ANALYTICS_ID) {
      const title = newState.title.replace(/\s\|.+$/, "")
      window.gtag("event", "screen_view", {
        "app_name": "FieldDay",
        "screen_name": title
      })
    }
  }

  return (
    <>
      <Helmet
        titleTemplate={`%s | Volunteer as a Team at Field Day`} title={"Connect and Volunteer! " + window.location.pathname}
        onChangeClientState={onHelmetUpdate}
      >
        <link rel="canonical" href={`${DETECTED_ROOT_URL}${_.trimStart(pathname, "/")}`} />
      </Helmet>
      <Box sx={{ display: 'flex' }}>
        {user && <React.Suspense>
          <NotificationBar />
        </React.Suspense>}
        <CallToActionBanner />
        <LayoutProvider>
          <PrimaryRouter />
        </LayoutProvider>
      </Box>
    </>
  )
}

function PrimaryRouter() {
  return (
    <Switch>
      {/* Home page / landing page here */}
      <Route exact path="/home">
        <Home />
      </Route>
      {/* Login */}
      <Route exact path="/">
        <SignUp />
      </Route>
      <Route path="/login">
        <Redirect to={`/${location.search}`} />
      </Route>
      <Route path="/sso/devtest">
        <SSODevTest />
      </Route>
      <Route path="/sso/dynatrace">
        <SSODynatrace />
      </Route>
      <Route path="/signout">
        <SignOut />
      </Route>
      {/* Account / Past event pages */}
      <PrivateRoute path="/pastevents">
        <UserPastEvents />
      </PrivateRoute>
      {/* Account / Profile pages */}
      <PrivateRoute path="/profile/edit">
        <EditProfile />
      </PrivateRoute>
      <PrivateRoute path="/profile/donations">
        <MatchableUserDonations />
      </PrivateRoute>
      {/* Notification Center */}
      <PrivateRoute path="/notifications">
        <Notifications />
      </PrivateRoute>
      {/* NPO Pages */}
      <Route path="/nonprofits">
        <NonProfitRoutes />
      </Route>
      {/* Team pages */}
      <Route path="/teams">
        <TeamRoutes />
      </Route>
      {/* Events (scheduled activities) */}
      <Route path={"/events"}>
        <ActivityRoutes />
      </Route>
      {/* Activities (what used to be called templates) */}
      <PrivateRoute path={"/activities"} customNoUserMessage="Create an account or sign in to browse activities on Field Day." membershipRequired>
        <ListActivityTemplates />
      </PrivateRoute>
      <Route path={"/welcome/:inviteToken"}>
        <Welcome />
      </Route>
      <Route path={"/guest/:guestToken"}>
        <Guest />
      </Route>
      <Route path={`/campaigns/community/:campaignid`}>
        <ViewCommunityCampaign />
      </Route>
      <Route path={"/campaign/:inviteToken"}>
        <WelcomeCampaign />
      </Route>

      {/* special route for Believe in Portland one-time event team signup*/}
      <Route path={"/believe-in-portland/individual"}>
        <IndividualSignUp />
      </Route>
      <Route path={"/believe-in-portland"}>
        <CreateBelieveTeam />
      </Route>
      <Route path={"/we-believe-in-portland/individual"}>
        <IndividualSignUp />
      </Route>
      <Route path={"/we-believe-in-portland"}>
        <CreateBelieveTeam />
      </Route>

      <PrivateRoute path={"/wizard"} wizardRequired={true} customNoUserMessage="The Chamber of Secrets is closed. 🧙‍♀️ 🐍">
        <WizardRoutes />
      </PrivateRoute>
      {/* 401 page */}
      <RealRoute path="/401" component={Unauthorized} />
      {/* 404 page */}
      <RealRoute path="/404" component={Render404} />
      {/* Redirect all unfound routes to 404 page */}
      <Route path="/">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

function WizardRoutes() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path} >
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={`${path}/ftps`}>
        <ListFieldTripPlans />
      </PrivateRoute>
      <PrivateRoute path={`${path}/teams`}>
        <ListTeams />
      </PrivateRoute>
      <PrivateRoute path={`${path}/users`}>
        <ListUsers />
      </PrivateRoute>
      <PrivateRoute path={`${path}/nonprofits`}>
        <WizardListNonprofits />
      </PrivateRoute>
      <PrivateRoute path={`${path}/calendar`}>
        <FullCalendar />
      </PrivateRoute>
      <PrivateRoute path={`${path}/themes`}>
        <ThemePlayground />
      </PrivateRoute>
      <PrivateRoute path={`${path}/systemEvents`}>
        <ListSystemEvents />
      </PrivateRoute>
      <PrivateRoute path={`${path}/surveys`}>
        <ListSurveys />
      </PrivateRoute>
      <PrivateRoute path={`${path}/confirmRegistrations`}>
        <ConfirmRegistrations />
      </PrivateRoute>
      <PrivateRoute path={`${path}/donation/invoicing`}>
        <DonationInvoicing />
      </PrivateRoute>
      <PrivateRoute path={`${path}/donation/disbursement`}>
        <DonationDisbursement />
      </PrivateRoute>
      <PrivateRoute path={`${path}/donation/nonprofit`}>
        <NonProfitDisbursementConfig />
      </PrivateRoute>
      <PrivateRoute path={`${path}/donation`}>
        <DonationDashboard />
      </PrivateRoute>
    </Switch>
  )
}

function NonProfitRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} >
        <ListNonProfits />
      </Route>
      <PrivateRoute path={`${path}/create`} completeProfileRequired>
        <CreateNpo />
      </PrivateRoute>
      <Route path={`${path}/byId/:npoid`}>
        <NpoById />
      </Route>
      <Route path={`${path}/:npohandle`} >
        <NonprofitProfileRoutes />
      </Route>
      <Route path="/">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

function TeamRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute path={`${path}/create`} completeProfileRequired>
        <CreateTeam />
      </PrivateRoute>
      <Route path={`${path}/byId/:ouid`}>
        <OrgUnitById />
      </Route>
      <Route path={`${path}/:teamhandle`} >
        <TeamProfileRoutes />
      </Route>
      <Route path="/">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

function ActivityRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <CommunityCalendar />
      </Route>
      <Route exact path={`${path}/:scheduledactivityid`}>
        <ViewEvent />
      </Route>
      <Route path="/">
        <PageNotFound />
      </Route>
    </Switch>
  )
}

declare global {
  interface Window {
    gtag: (...args: unknown[]) => void
  }
}
