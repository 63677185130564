import qs from "qs"
import { useState } from "react"

const defaultPage = "1"
const defaultPerPage = "10"
const defaultMaxPage = 1

/**
 * Hook to make paginating a little easier.
 *
 * @example const [page, setPage, maxPage, updateMaxPage] = usePagination()
 * @example <Pagination count={maxPage} page={page}>
 *
 * @returns [
 *  page - the current page
 *  setPage - used to update the page from a new page selection or from query params
 *  maxPage - the max page
 *  updateMaxPage - pass the new total from a list API response to this function to update the max page
 * ]
 */
interface PaginationData {
  page: number,
  setPage: (page?: number | string) => void,
  maxPage: number,
  updateMaxPage: (newTotal: number) => void,
  perPage: number,
}

export function usePagination(): PaginationData {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as { page?: string, perPage?: string }

  const perPage = parseInt(`${query.perPage ?? defaultPerPage}`)

  const [page, _setPage] = useState(stanitizePage(query.page))
  const setPage = (page?: number | string) => {
    const newPage = stanitizePage(page)
    _setPage(newPage)
  }

  const [maxPage, setMaxPage] = useState(defaultMaxPage)
  const updateMaxPage = (newTotal: number) => {
    setMaxPage(Math.ceil(newTotal / perPage))
  }

  return { page, setPage, maxPage, updateMaxPage, perPage }
}

function stanitizePage(page?: string | number): number {
  return parseInt(`${page ?? defaultPage}`)
}
