import { OrgStatus } from "@fieldday/fielddayportal-model"
import { Autocomplete, FormControl, FormLabel, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { NPO, NPOWithActivities } from "../../models/Npo"
import useStyles from "../../styles/useStyles"
import { useAPI } from "../../util/useAPI"

export function OrgStatusPicker({ statuses, onFilterChange }: {
  statuses: OrgStatus[],
  onFilterChange: (statuses: OrgStatus[]) => void
}) {
  const allStatuses = Object.values(OrgStatus)
  return (
    <FormControl>
      <FormLabel component="legend">Filter by status</FormLabel>
      <Autocomplete
        onChange={(_, values) => onFilterChange(values)}
        multiple
        options={allStatuses}
        value={statuses ?? []}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} placeholder="Org statuses" />
        )}
        size="small"
        style={{ minWidth: "200px" }}
      />
    </FormControl>
  )
}

export function NonprofitPicker({ npoIds, onFilterChange, orgStatuses = [
  OrgStatus.New,
  OrgStatus.Active,
  OrgStatus.Private,
  OrgStatus.Paused,
]}: {
  npoIds: string[],
  onFilterChange: (npos: NPO[]) => void,
  orgStatuses?: OrgStatus[],
}) {
  const FieldDayAPI = useAPI()
  const styles = useStyles()
  const [foundNPOs, setFoundNPOs] = useState<NPOWithActivities[]>([])
  const [nonprofitOrgFilter, setNPOFilter] = useState<string | undefined>(undefined)
  const [selectedNPOs, setSelectedNPOs] = useState<NPO[]>([])
  useEffect(() => {
    Promise.all(
      npoIds.map(npoId => FieldDayAPI.getNpoById(npoId))
    ).then(resps => {
      setSelectedNPOs(resps.map(resp => resp.nonProfitOrg))
    })
  }, [npoIds])

  useEffect(() => {
    FieldDayAPI.listNposWithAuth(nonprofitOrgFilter, orgStatuses).then(resp => {
      setFoundNPOs(resp.nonProfitOrgs)
    })
  }, [nonprofitOrgFilter])

  return (
    <FormControl className={styles.formControl}>
      <FormLabel component="legend">
        <Typography>Filter by nonprofits</Typography>
        <Typography variant="caption">show plans of one or more NPOs</Typography>
      </FormLabel>
      <Autocomplete
        onChange={(_, values) => onFilterChange(values)}
        onInputChange={(_event, newInput) => {
          setNPOFilter(newInput)
        }}
        multiple
        options={foundNPOs}
        getOptionLabel={(npo: NPO) => npo?.name ?? "---"}
        value={selectedNPOs ?? []}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} placeholder="Nonprofits" />
        )}
        isOptionEqualToValue={(option: NPO, value: NPO) => option.id === value.id}
      />
    </FormControl>
  )
}
