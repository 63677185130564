import { useEffect, useState } from "react"
import preventNav from "../util/preventNav"
import { usePreviousValue } from "./usePreviousValue"

interface props<T> {
  objForUpdate: T,
  // forDirtyCompare takes an object with type of objForUpdate
  // and returns a string for use in a useEffect dependency array.
  // This may be a JSON stringified object.
  //
  // Dan Abramov suggests that this is ok if you have an object that is
  // not deeply nested and you only care about comparing properties.
  // We should be selective about the properties picked in
  // forDirtyCompare so this remains reasonably performant.
  //
  // https://twitter.com/dan_abramov/status/1104414272753487872
  // "Deep equality checks are generally a bad idea. If the inputs
  // are shallow enough that you think deep equality would still be fast,
  // consider just using [JSON.stringify(obj)] instead. Note it won’t compare functions."
  forDirtyCompare: (obj: T) => string,
}

// Use this for forms which keep track of their own dirty status
// and can opt to reset the dirty status as needed (e.g. after
// loading an object from the API for update via the form).
//
// returns: tuple [
//   dirty:        dirty status
//   resetDirty:   function to reset the dirty status
//   disableDirty: function to disable dirty status before navigating away
//   setDirtyFlase: function to explictly set dirty status to false
// ]
export function useDirty<T>(props: props<T>): [boolean, () => void, () => void, () => void] {
  const { objForUpdate, forDirtyCompare } = props

  const [dirty, setDirty] = useState<boolean>(false)
  const [dirtyEnabled, setDirtyEnabled] = useState(true)

  const [updatedFromInit, setUpdatedFromInit] = useState(false)
  const previousUpdatedFromInit = usePreviousValue(updatedFromInit)
  const shouldResetDirty = !previousUpdatedFromInit && updatedFromInit

  const resetDirty = () => {
    setUpdatedFromInit(true)
  }

  const disableDirty = () => {
    setDirtyEnabled(false)
  }

  const setDirtyFalse = () => {
    setDirty(false)
  }

  useEffect(() => {
    preventNav(dirty && dirtyEnabled)
  }, [dirty, dirtyEnabled])

  useDirtyCallback({
    objForUpdate: objForUpdate,
    forDirtyCompare: forDirtyCompare,
    setDirty: setDirty,
    shouldResetDirty: shouldResetDirty,
  })

  return [dirty && dirtyEnabled, resetDirty, disableDirty, setDirtyFalse]
}

interface callbackProps<T> extends props<T> {
  setDirty: (isDirty: boolean) => void,
  shouldResetDirty: boolean,
}

// Use this for nested forms where a child component might need to
// propagate its dirty status up to a parent component.
export function useDirtyCallback<T>(props: callbackProps<T>) {
  const { objForUpdate, forDirtyCompare, setDirty, shouldResetDirty } = props
  const [originalObj, setOriginalObj] = useState(forDirtyCompare(objForUpdate))

  useEffect(() => {
    // On each render of the hook, allow resetting the dirty field
    // and the original object to compare against for future dirty checks.
    // This is useful when e.g. replacing an initial value
    // dirty-checkable object with a new one loaded from the API.
    if (shouldResetDirty) {
      setOriginalObj(forDirtyCompare(objForUpdate))
      setDirty(false)
    } else {
      setDirty(originalObj !== forDirtyCompare(objForUpdate))
    }
  }, [originalObj, forDirtyCompare(objForUpdate)])
}
