import { ActivityType } from "@fieldday/fielddayportal-model";
import { UserOrgType } from "@fieldday/fielddayportal-model/dist/src/model/FieldTripPlan";
import dayjs from "dayjs";
import _ from "lodash";
import { ScheduledActivity, ScheduledActivityForView } from "../models/Activity";
import { Campaign } from "../models/Campaign";
import { OrgUnit } from "../models/OrgUnit";
import { saIsPast } from "./dateUtil";
import { pluralizeWithNum } from "./stringUtil";

export const WEBIP_CURRENT_ID = "7aaac022-87cc-4093-ae92-f73d395ed650" // 09-13-24

// defaults to most recent first (most APIs return it the other way)
export function sortByTimestamp(sas: ScheduledActivity[], newestFirst: boolean = true): ScheduledActivity[] {
  // Shallow-copy the array to avoid mutating it when sorting.
  const copiedSas = sas.slice()

  return copiedSas.sort((a, b) => timestampSortVal(a, b, newestFirst) )
}

export function sortByFieldTripAndTimestamp(sas: ScheduledActivity[], newestFirst: boolean = true): ScheduledActivity[] {
  // Shallow-copy the array to avoid mutating it when sorting.
  const copiedSas = sas.slice()

  return copiedSas.sort((a, b) => {
    if (a.orgUnitId && !b.orgUnitId) return -1
    else if (b.orgUnitId && !a.orgUnitId) return 1
    else return timestampSortVal(a, b, newestFirst)
  })
}

function timestampSortVal(a: ScheduledActivity, b: ScheduledActivity, newestFirst: boolean): number {
  return newestFirst ?
    dayjs(b.timestampUTC).unix() - dayjs(a.timestampUTC).unix() :
    dayjs(a.timestampUTC).unix() - dayjs(b.timestampUTC).unix()
}

export function filterSaOrgSharesForUser(
  schedActs: ScheduledActivityForView[],
  userTeamMembershipIds: string[] | undefined
): ScheduledActivityForView[] {
  if (!userTeamMembershipIds) return []

  const usersOrgSharedActs: ScheduledActivityForView[] = []
  schedActs.forEach(sa => {
    const sharedOrgIds = sa.orgUnitShares?.map(org => org.orgUnitId)
    sharedOrgIds?.forEach(id => {
      if (userTeamMembershipIds.includes(id)) {
        return usersOrgSharedActs.push(sa)
      }
    })
  })
  return usersOrgSharedActs
}

export function calculateSlotDifference(originalSlots: number, updatedSlots: number): number | undefined {
  const slotDiff = updatedSlots - originalSlots
  return slotDiff
}

export const volunteerCountAsString = (sa: ScheduledActivityForView, forOrgType: UserOrgType = UserOrgType.OrgUnit): string => {
  // from the NPO perspective, include all volunteers
  if (forOrgType === UserOrgType.Nonprofit) {
    const allVols = (sa.registrationCount ?? 0) + (sa.guestRegistrationCount ?? 0)
    return pluralizeWithNum(allVols, "volunteer")
  }
  // for a Team, break it up to include guests on FTs and only team mebers on the shared count
  const volCount = (sa.orgUnitId ? sa.registrationCount : sa.sharedRegistrationCount) ?? 0
  const guestVolunteerText = sa.guestRegistrationCount && sa.guestRegistrationCount > 0 ?
    `and ${pluralizeWithNum(sa.guestRegistrationCount, "guest")} ` : ""
  return `${pluralizeWithNum(volCount, "Team member")} ${guestVolunteerText}`
}

export const tokenFromSa = (sa: ScheduledActivityForView, sharingOrgUnit?: OrgUnit): string | undefined => {
  return sa.inviteToken ??
    sa.inviteTokensOnShares?.find(tos => tos.orgUnitId === sharingOrgUnit?.id)?.token
}

export const isDrive = (sa: ScheduledActivityForView) => sa.durationInMinutes === 0
export const isNonVolunteerEvent = (sa: ScheduledActivityForView) => sa.activity?.type === ActivityType.Other
export const isVolunteerEvent = (sa: ScheduledActivityForView) => sa.activity?.type === ActivityType.Volunteer && !isDrive(sa)

export const isEvent = (eventOrCampaign: ScheduledActivityForView | Campaign) => 'activityId' in eventOrCampaign

export const regNoun = (sa: ScheduledActivityForView, capitalize: boolean = false): string => {
  if (saIsPast(sa)) return capitalize ? "Survey" : "survey"
  // XXX: use activity type here; for now, just guess if it's a drive vs volunteering
  const noun = isDrive(sa) ? "donation" : "registration"
  return capitalize ? _.capitalize(noun) : noun
}

export const regVerb = (sa: ScheduledActivityForView, capitalize: boolean = false): string => {
  // XXX: use activity type here; for now, just guess if it's a drive vs volunteering
  const noun = isDrive(sa) ? "track" : "complete"
  return capitalize ? _.capitalize(noun) : noun
}

export const signUpVerb = (sa: ScheduledActivityForView, capitalize: boolean = false): string => {
  const verb = isVolunteerEvent(sa) ? "volunteer" : "sign up"
  return capitalize ? _.capitalize(verb) : verb
}

export const regPeople = (sa: ScheduledActivityForView, capitalize: boolean = false, brief: boolean = false): string => {
  const volunteers = brief ? "volunteers" : "registered volunteers"
  const noun = isVolunteerEvent(sa) ? volunteers : "participants"
  return capitalize ? _.capitalize(noun) : noun
}

export const regPeopleSec = (sa: ScheduledActivityForView, capitalize: boolean = false): string => {
  // XXX: use activity type here; for now, just guess if it's a drive vs volunteering
  const noun = isDrive(sa) ? "donor" : "attendee"
  return capitalize ? _.capitalize(noun) : noun
}

export const regRemain = (sa: ScheduledActivityForView): string => {
  return isDrive(sa) ? `${pluralizeWithNum(sa.registrationCount ?? 0, 'donation')} made` :
                       `${pluralizeWithNum(sa.numberOfSlotsLeft ?? 0, 'spot')} remaining`
}

export const detailsParticipants = (sa: ScheduledActivityForView, capitalize: boolean = false): string => {
  const noun = isVolunteerEvent(sa) ? "volunteers" : "participants"
  return capitalize ? _.capitalize(noun) : noun
}
