import { FileModelResponse, FileState, MatchableDonationModelResponse } from "@fieldday/fielddayportal-model"
import ClearIcon from "@mui/icons-material/Clear"
import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import * as Sentry from "@sentry/react"
import { Fragment, useEffect, useRef, useState } from "react"
import { FieldDayAPIProvider } from "../../util/useAPI"

interface props {
  buttonText: string,
  setLocalFilesArray: (localFilesArray: LocalReceiptFile[]) => void,
  error?: boolean,
  clearErrors?: () => void,
}

class LocalReceiptFileMap extends Map<string, LocalReceiptFile> {}

export interface LocalReceiptFile {
  localUrl: string
  file: File
  name: string
  state?: FileState
}

export interface UserFileUpload {
  id:         string
  uploadUrl?: string
  fileUrl:    string
  fileId:     string
  localUrl:   string
  fileName?:  string
  state:      FileState
  file?:      File
}

export default function MatchableDonationReceiptUploadForm(props: props) {
  const { buttonText, setLocalFilesArray, error, clearErrors } = props

  const receiptFileRef = useRef<HTMLInputElement>(null)
  const [localFiles, setLocalFiles] = useState(new LocalReceiptFileMap())

  // Newly created file URLs will be saved here for revoking on component unmount.
  const localFileUrls = useRef<string[]>([])
  const localFilesArray = Array.from(localFiles.values())

  const handleFileChange = () => {
    const files = receiptFileRef.current?.files
    if (!files) return
    if (clearErrors) clearErrors()
    // Generate a new local URL for each file to upload.
    // The local URL is used as the file identifier to map
    // the image upload (and image object in the database)
    // to the local file object so we can track its state.
    //
    // This allows us to do things like hide the image preview once it
    // has uploaded or potentially other UI fanciness based on the state of each upload.
    const newFiles = new LocalReceiptFileMap()
    for (const file of Array.from(files)) {
      const localUrl = URL.createObjectURL(file)
      // Save in a ref for cleanup on component unmount.
      localFileUrls.current.push(localUrl)
      const localFile: LocalReceiptFile = {
        localUrl: localUrl,
        file: file,
        name: file.name,
      }
      newFiles.set(localUrl, localFile)
    }
    setLocalFiles(newFiles)
    setLocalFilesArray(Array.from(newFiles.values()))
  }

  useEffect(() => {
    return () => {
      for (const localFileUrl of localFileUrls.current) {
        URL.revokeObjectURL(localFileUrl)
      }
    }
  }, [])

  return (<>
    <Grid item xs={12}>
      <Box component={"span"}>
        <label>
          <input
            type="file"
            disabled={false}
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={receiptFileRef}
          />
          <Button
            variant="outlined"
            component="span"
            disabled={false}
            color={error ? "error" : "primary"}
          >
            {buttonText}
          </Button>
        </label>
      </Box>
    </Grid>
    {error && <Grid item>
      <Typography color="error" style={{ padding: "0.5em" }}>
        Please provide a receipt of the donation.
      </Typography>
    </Grid>}
    <Grid item>
      {localFilesArray.map((localFile: LocalReceiptFile) => {
        return (
          <Fragment key={localFile.localUrl}>
            <Box sx={{ display: "flex", flexWrap: "nowrap" }} pt={2}>
              <Typography noWrap sx={{ paddingTop: "0.5em" }} key={localFile.localUrl}>{localFile.name}</Typography>
              <IconButton
                size="small"
                sx={{ paddingTop: "0.5em" }}
                disableRipple
                onClick={() => {
                  setLocalFilesArray([])
                  setLocalFiles(new LocalReceiptFileMap())
                  receiptFileRef.current && (receiptFileRef.current.value = "")
                }}
                aria-label="clearReceiptFile"
              ><ClearIcon /></IconButton>
            </Box>
          </Fragment>
        )
      })}
    </Grid>
  </>)
}

const uploadS3File = async (fileUpload: UserFileUpload) => {
  const file = fileUpload.file
  const uploadUrl = fileUpload.uploadUrl
  if (uploadUrl && file) {
    // Allow for extended filename support with a fallback to
    // non-extended support where necessary per RFC-6266.
    // https://datatracker.ietf.org/doc/html/rfc6266#section-5
    const contentDisposition = [
      "attachment",
      `filename="${fileUpload.fileName}"`,
      `filename*=UTF-8''${fileUpload.fileName}`,
    ]

    // Headers MUST match the headers used to create
    // the pre-signed S3 URL for upload.
    const headers: Record<string, string> = fileUpload.fileName ?
      { "content-disposition": contentDisposition.join(";") } :
      {}
    const uploadResult = await fetch(uploadUrl, { method: "PUT", body: file, headers: headers })
    if (uploadResult.status >= 400) {
      const uploadResultBody = await uploadResult.text()
      const error = new Error(`Throwing exception on image upload for response code ${uploadResult.status}: ${uploadResultBody}`)
      Sentry.captureException(error)
      throw error
    }
  }
}

export const uploadDonationReceiptFiles = async (
  donation: MatchableDonationModelResponse,
  localFiles: Readonly<LocalReceiptFile[]>,
  fieldDayAPI: FieldDayAPIProvider,
) => {
  const fileNames = localFiles.map(file => file.name)
  const fileUploadBatch = await fieldDayAPI.createDonationReceiptUploadBatch(donation.orgUnitId, donation.id, fileNames)
  const receiptFiles = fileUploadBatch.data.receiptFiles as FileModelResponse[]

  for (const localFile of localFiles) {
    // The response from the createUploadBatch API has URL encoded file names.
    // So URL encode the local file name to match when finding this local file's receipt.
    const receiptFile = receiptFiles.find(receipt => receipt.fileName === encodeURIComponent(localFile.name))
    if (receiptFile) {
      const newFileUpload: UserFileUpload = {
        id:         receiptFile?.id,
        uploadUrl:  receiptFile.uploadUrl,
        fileUrl:    receiptFile.url,
        fileId:     receiptFile.id,
        localUrl:   localFile.localUrl,
        fileName:   receiptFile.fileName,
        state:      receiptFile.state,
        file:       localFile.file
      }
      await uploadS3File(newFileUpload)
      await fieldDayAPI.updateMatchableDonation(donation.orgUnitId, donation.id, {
        receiptId: receiptFile.id,
      })
    }
  }
}
