import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { appBarHeight } from './navBarStyles';

const useStyles = makeStyles((theme) => ({
  markdown: {
    '& p:first-of-type': {
      marginTop: "0px",
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 2, 3),
    '& p': {
      marginBottom: "1em",
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 0, 2, 2),
      '& p': {
        marginBottom: "1em",
      },
    }
  },
  boxContent: {
    backgroundColor: theme.palette.neutral.light,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 2, 2, 2)
    },
    '& p:not(:last-child, :noTextMargin)': {
      marginBottom: "1em",
    },
    '& h3': {
      marginTop: 0
    },
  },
  boxContentTight: {
    backgroundColor: theme.palette.neutral.light,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 2, 2, 2)
    },
    '& p:not(:last-child)': {
      marginBottom: "1em",
    },
    '& h3': {
      marginTop: 0
    },
  },
  mainHeader: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem'
    }
  },
  npLogo: {
    [theme.breakpoints.down('lg')]: {
      order: -1,
    }
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  filterMenu: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    }
  },
  filterMenuContainer: {
    position: "sticky",
    [theme.breakpoints.down('md')]: {
      position: 'static',
    },
    top: appBarHeight,
    zIndex: 1000,
    borderBottom: `1px solid ${theme.palette.divider}6A`,
    marginBottom: '1rem',
    paddingTop: "2rem",
    backgroundColor: "white",
  },
  filterButton: {
    padding: 0,
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  filterClearButton: {
    fontSize: "14px",
    color: theme.palette.neutral.dark,
    textDecoration: "underline",
    fontWeight: "normal",
  },
  cardGrid: {
    padding: theme.spacing(2, 2),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible', // so we can have the ribbon effect on .tag
    backgroundColor: '#fff',
    borderRadius: "4px",
    boxShadow: "0 2px 7px rgba(0,0,0,.1)",
    '&.autoHeight': {
      height: 'auto'
    }
  },
  cardNoHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  cardActionArea: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  cardMedia: { // use for standard sized photos
    paddingTop: '60%', // 15:9
    borderRadius: "4px 4px 0 0",
    '&.dimmed': {
      opacity: .75
    },
  },
  cardMediaRoundedRect: {
    paddingTop: '56.25%', // 16:9
    borderRadius: "8px",
  },
  cardMediaImage: { // use for logos, etc
    paddingTop: '0%',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.neutral.light,
    color: 'black',
    padding: '8px',
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  cardCentered: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  cardContentContrast: {
    backgroundColor: theme.palette.neutral.light,
  },
  cardContentDesc: {
  },
  more: {
    color: "rgba(60,60,60,0.8)",
    fontStyle: "italic",
    paddingLeft: "4px",
  },
  cardContentDescFadeOut: {
    // show a solid color in older browsers (e.g., IE11)
    color: "black",
    height: "3em",
    overflow: "hidden",

    // show the text gradient in modern browsers
    "@supports (--css: variables)": {
      background: "linear-gradient(to bottom, rgba(45,45,45,.9), rgba(45,45,45,.1))",
      color: "transparent",
      "-webkit-background-clip": "text",
      backgroundClip: "text",
    }
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cardPastEvent: {
    color: theme.palette.neutral.main
  },
  rsvpButton: {
    marginRight: 'auto',
    '& svg': {
      height: "0.8em"
    }
  },
  spotsleft: {
    color: theme.palette.error.main,
  },
  spotsfull: {
    paddingRight: '10px',
    color: '#666',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  causeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  tag: {
    display: 'inline-block',
    borderTopLeftRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    float: 'right',
    padding: '2px 1rem',
    color: '#fff',
    textAlign: 'right',
    position: 'relative',
    right: '-10px',
    bottom: '10px',
    fontSize: '1rem',
  },
  soontag: {
    backgroundColor: theme.palette.error.main,
  },
  pasttag: {
    backgroundColor: theme.palette.neutral.dark,
    fontStyle: "italic",
    color: "#DDD",
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(6)
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: 142,
      marginLeft: 0
    }
  },
  manageMatchFormControl: {
    minWidth: 244
  },
  eventPageFormControl: {
    minWidth: 200,
    '&.marginLg': {
      marginBottom: theme.spacing(4)
    },
    '&.marginMd': {
      marginBottom: theme.spacing(3.5)
    },
    '&.marginSm': {
      marginBottom: theme.spacing(3)
    },
  },
  formLabel: {
    color: 'rgba(0, 0, 0, 0.6) !important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  messageItem: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    '&:focus': {
      outline: "none",
    },
    '&:focus $messageBody': {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: `calc(1.0rem - 1px)`
    },
  },
  messageBody: {
    backgroundColor: theme.palette.background.paper,
    padding: '1.0rem',
  },
  table: {
    body: {
      fontSize: '1.2rem'
    }
  },
  leaderboardCell: {
    fontSize: '1.2rem'
  },
  imageListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450
  },
  infoIcon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  listWithIcons: {
    '& li': {
      paddingLeft: 0,
      '& .MuiListItemIcon-root': { minWidth: "36px" }
    }
  },
  leftArrowButton: {
    background: "rgba(100,150,100,0.3)",
    boxShadow: "-6px 6px 6px rgba(125, 125, 125, 0.5)",
    left: "16px",
    height: "240px",
    "&:hover": {
      background: "rgba(100,175,100,0.7)",
      boxShadow: "none"
    },
    "&.Mui-disabled": {
      background: "rgba(225, 225, 225, 0.2)",
      boxShadow: "none",
    }
  },
  rightArrowButton: {
    background: "rgba(100,150,100,0.3)",
    boxShadow: "6px 6px 6px rgba(125, 125, 125, 0.5)",
    left: "-16px",
    height: "240px",
    "&:hover": {
      background: alpha(theme.palette.primary.main, 0.5),
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      background: "rgba(225, 225, 225, 0.2)",
      boxShadow: "none",
    }
  },
  homePage: {
    padding: theme.spacing(3, 1, 3, 3),
  },
  homeTopMenu: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0, 2, 3),
  },
  homeTopButton: {
    fontSize: "1.2rem",
    textTransform: "none",
    borderRadius: "2em",
    padding: theme.spacing(1, 4),
    fontWeight: 700,
  },
  homeSideBox: {
    background: theme.palette.neutral.light,
    padding: theme.spacing(3, 2),
    borderRadius: "4px",
  },
  homeInfoText: {
    fontStyle: "italic",
    margin: theme.spacing(4, 2),
  },
  statsHeader: {
    padding: theme.spacing(3),
    lineHeight: "1.5em",
    textAlign: "center",
  },
  statsHighlight: {
    padding: "8px",
    background: theme.palette.primary.light,
    color: theme.name === 'existing' ? 'white' : theme.palette.primary.dark,
    borderRadius: "8px",
    marginLeft: "12px",
  },
  markdownHelpTitle: {
    textAlign: "center",
    paddingTop: ".5em",
    paddingBottom: ".5em",
    fontSize: "1.75em",
    fontWeight: 700
  },
  markdownHelpBody: {
    fontSize: "1.25em"
  },
  markdownHelperText: {
    fontSize: theme.spacing(1.75),
    textAlign: 'center',
    color: "#727272",
    marginBottom: "1em"
  },
  markdownHelpBtn: {
    textTransform: 'none',
    fontSize: theme.spacing(1.6)
  },
  pastEventLink: {
    fontSize: theme.spacing(2.7),
  },
  rolesHelpTextBox: {
    padding:'1.5rem',
    backgroundColor: theme.palette.neutral.light,
    borderRadius: "4px",
    marginBottom: '1em'
  },
  rolesHelpTextTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 550,
  },
  rolesHelpTextBody: {
    fontSize: theme.spacing(1.75),
  },
  unselectedFilterChip: {
    fontWeight: 400
  },
  selectedFilterChip: {
    backgroundColor: theme.palette.primary.dark,
    color: "#FFFFFF",
    '&:hover': {
      color: theme.palette.neutral.dark,
    },
    fontWeight: 400,
    '& .MuiSvgIcon-root': {
      backgroundColor: theme.palette.primary.dark,
      color: "#FFFFFF",
      '&:hover': {
        color: "#FFFFFF",
      }
    },
  },
  regionPicker: {
    minWidth: '200px',
  },
  regionFilter: {
    width: '100%',
    color: theme.palette.neutral.main,
    backgroundColor: "#FFFFFF",
    alignItems: 'start',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.875rem',
      marginBottom: 0,
      paddingBottom: 0,
    }
  },
  campaignBannerBox: {
    background: theme.palette.primary.light,
    padding: theme.spacing(3),
    borderRadius: "4px",
  },
  campaignParticipantsBox: {
    background: theme.palette.neutral.light,
    padding: theme.spacing(2, 3, 3),
    borderRadius: "4px"
  },
  buttonBox: {
    background: theme.palette.neutral.light,
    padding: theme.spacing(3),
    borderRadius: "4px"
  },
  statsBox: {
    backgroundColor: theme.palette.neutral.light,
    borderRadius: "4px",
    height: "100%",
    padding: ".75rem"
  },
  donationTrackerIncomplete: {
    '& .MuiStepLabel-label': {
      fontSize: ".75rem",
      marginTop: ".5rem",
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.neutral.main,
      height: "13px",
      marginTop: "4px",
    }
  },
  donationTrackerComplete: {
    '& .MuiStepLabel-label': {
      fontSize: ".75rem",
      marginTop: ".5rem",
      '&.Mui-completed': {
        fontWeight: 400
      },
      '&.Mui-active': {
        fontWeight: 600
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
      height: "19px",
    }
  },
  donationTrackerDeclined: {
    '& .MuiStepLabel-label': {
      fontSize: ".75rem",
      marginTop: ".5rem",
      '&.Mui-completed': {
        fontWeight: 400
      },
      '&.Mui-active': {
        fontWeight: 600
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.neutral.dark,
      height: "19px",
    },
  },
  donationMatchingGraphBox: {
    backgroundColor: theme.palette.neutral.light,
    padding: theme.spacing(1, 2, 1),
    marginBottom: theme.spacing(2),
    borderRadius: "4px",
  }
}));

export default useStyles;
