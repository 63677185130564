import makeStyles from '@mui/styles/makeStyles';
import { appBarHeight } from './navBarStyles';

// shared styles between NPO and OU pages
const useOrgStyles = makeStyles((theme) => ({
  horizontalNav: {
    position: "sticky",
    [theme.breakpoints.down('md')]: {
      position: 'static'
    },
    top: appBarHeight,
    paddingTop: "2rem",
    backgroundColor: theme.palette.background.default,
    zIndex: 1000
  },
  bannerImage: {

  },
  logoImage: {
    maxWidth: "100%",
    width: "fit-content",
    height: "fit-content",
    maxHeight: "200px",
    objectFit: "contain",
    '&.mini': {
      maxHeight: "50px",
    }
  },
  dashboardTabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
    '& .MuiTabs-scroller': { overflow: 'visible !important' },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.dark,
      height: "3px",
    },
    '& a': {
      marginTop: ".5rem",
      minWidth: 0,
      textTransform: "none",
      marginRight: theme.spacing(5),
      color: "inherit",
      fontSize: "1rem",
      padding: 0,
      fontWeight: 400,
      '&.Mui-selected': {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      '&:hover': {
      },
      '& svg': {
        display: "none",
      },
    },
    '&.timeline': {
      borderBottom: 'none',
    },
  },
  // for big list of nonprofits
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: "20px",
    padding: theme.spacing(3),

    "&:hover": {
      /* offset-x | offset-y | blur-radius | color */
      boxShadow: "0 5px 35px 0px rgba(0,0,0,.1)"
    }
  },

  cardActions: {
    paddingTop: "4px",
  },

  cardActionButton: {
    borderRadius: "40px"
  },

  missingLogoBox: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.error.main,
    borderRadius: "10px",
    padding: "8px",
    fontStyle: "italic",
    color: "#666",
    backgroundColor: "#FDD"
  },

  causesList: {
    background: theme.palette.neutral.light,
    borderRadius: "1em",
    marginBottom: "1em",
  },

  timeOfDayBox: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    border: `1px solid ${theme.palette.neutral.light}`,
    borderRadius: '2px'
  },

  timeOfDayBoxHighlight: {
    background: theme.palette.primary.light,
    color: theme.name === 'existing' ? 'white' : theme.palette.primary.dark,
    textAlign: 'center',
    fontWeight: 600,
    borderRadius: "2px"
  },

  miniTodBox: {
    display: "none",
  },

  miniTodBoxHighlight: {
    background: theme.palette.primary.light,
    color: theme.name === 'existing' ? 'white' : theme.palette.primary.dark,
    textAlign: 'center',
    fontSize: '0.8em',
    fontWeight: 700,
    padding: "2px 6px !important",
    margin: "8px 0",
  },

  calendarBox: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: '1em',
    border: `1px solid ${theme.palette.neutral.light}`,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  calendarBoxHighlight: {
    background: theme.palette.primary.light,
    color: theme.name === 'existing' ? 'white' : theme.palette.primary.dark,
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "2px"
  },

  miniCalBox: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: '0.8em',
    border: "1px solid #888",
    marginLeft: "-1px",
    marginBottom: "4px",
  },

  miniCalBoxHighlight: {
    background: theme.palette.primary.light,
    color: theme.name === 'existing' ? 'white' : theme.palette.primary.dark,
    textAlign: 'center',
    fontSize: '0.8em',
    fontWeight: 700,
    border: "1px solid #888",
    marginLeft: "-1px",
    marginBottom: "4px",
  },

  dialogTitle: {
    fontWeight: 700,
  },
  npoCardHeader: {
    fontSize: '1.25em',
  },
  activityPreviewContainer: {
    marginBottom: theme.spacing(2),
    '&.withCard': {
      borderBottomWidth: '1.25px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.neutral.main
    },
  },
}));

export default useOrgStyles
